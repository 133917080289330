import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Link, Tooltip, TextField, Box } from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { dangerouslySetInnerHTML } from 'react-dom';
import { ContentComponent, MembershipStatus, MembershipStatusDanke } from '../../components/ContentForFullMemberShip';
import ViewFormOutputMembership from '../../components/ViewFormOuputMembership';
import DialogOutput from '../../../../components/DialogOutput';
import ViewFormOutput from '../../components/ViewFormOuput';
import * as DatabaseService from  '../../../../services/DatabaseService';
import { checkDeviationPercentStatus } from '../../calculations/ebmDeviationConditions';
import EBM_Result_Notes from '../../components/EBM_Result_Notes/EBM_Result_Notes'

function insertAbrechnungskompassCSVData_func(data, input, scheine, state_id, abrechnung_billing_no) {
  return DatabaseService.insertAbrechnungskompassCSVData(data, input, scheine, state_id, abrechnung_billing_no)
}

export default function TableSection(props) {

    const { rowsResult, scheine, gopNumbersInput, kv, viewOnlyMode, userStatus, abrechnungBillingOption, debug, maxHeight=500, onDataChange, abrechnungskompassCSV_ID, userRoleID } = props;

    let processOutputCSV = "";
    let processOutputJSON = {
      tables: []
    };

    useEffect(() => {

      if (!viewOnlyMode && rowsResult && rowsResult.length > 0) {
        insertAbrechnungskompassCSVData_func(processOutputCSV, gopNumbersInput, scheine, kv, abrechnungBillingOption)
      }

      if (onDataChange) {
        onDataChange(processOutputJSON);
      }

    }, [rowsResult, scheine, gopNumbersInput, kv])

    const getTableHeaders = (tableType, debug) => {
      // Define base columns that appear in all tables
      const baseHeaders = [
          "EBM-Ziffer",
          "Leistung", 
          "Anzahl", 
          "Soll", /*Ideal number of treatments*/
          "Abweichung", 
          "Abweichung %"
      ];

      const additionalHeaders = [
        "Gesamtpotenzial in €",
        "Ihr Potenzial in €"
      ];

      // Add type-specific columns
      if (tableType === "1" || debug) {
          return {
              columns: [...baseHeaders, ...additionalHeaders],
              csvHeader: baseHeaders.join(",") + "," + additionalHeaders.join(",")
          };
      } else if (tableType === "2") {
          return {
              columns: [...baseHeaders, ""],
              csvHeader: baseHeaders.join(",") + ""
          };
      } else {
          return {
              columns: baseHeaders,
              csvHeader: baseHeaders.join(",")
          };
      }
    };

    const TableFilter = (props) => {

        const { rowsResult, only_list_include_chart = false, titleHeader="", tableType = "", viewOnlyMode } = props;
    
        /*Dialog Box*/
        const [open, setOpen] = React.useState(false);
        const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "", fullScreen: false });
        /*End of Dialog Box*/ 
        const [readOnlyNotes, setReadOnlyNotes] = useState(false);

        let rows = [];

        /*console.log("only_list_include_chart")
        console.log(only_list_include_chart)*/
    
        if (only_list_include_chart) {
          rows = rowsResult.filter(row => row.include_chart !== null) // Exclude rows where row.include_chart is null
                           .sort((a, b) => a.gop - b.gop);

        } else {
          //rows = rows;  // No filter applied
          rows = rowsResult.filter(row => {
            // Add your filter condition here
            // For example, to include all rows, you can return true
            return true;
          }).sort((a, b) => a.gop - b.gop);
        }
    
        let currentHeader = null;
        let backgroundColorRow = '#00000000';
        let countRowForBgColor = 0; //this will help start with a white row
        let colorTextRow = '';
    
        const { columns, csvHeader } = getTableHeaders(tableType, debug);

        processOutputCSV += `"${titleHeader}"\n`;
        processOutputCSV += `${csvHeader}\n`;

        const tableData = {
            title: titleHeader,
            columns: columns,
            sections: []
        };

        let currentSection = null;

        const handleViewForm = (gop, description) => {
          setDialogInformation({ title: gop, information: description, status: "view", action: "view_gop_info" });
          setOpen(true);
        }
    
        const handleViewFormDialogFeatures = () => {
            setDialogInformation({ status: "view", action: "view_membership_features" });
            setOpen(true);
        }

        const handleNotes = () => {
            setDialogInformation({ title: "Hinweise", status: "view", action: "view_notes" });
            setOpen(true);
        }
    
        const handleClose = () => {
            setOpen(false);
        };
        
        useEffect(() => {
          processOutputJSON.tables.push(tableData);
        }, [rows]);
    
        useEffect(() => {
            // Set readOnly to true by default
            // Only users with role 4 can edit
            setReadOnlyNotes(parseInt(userRoleID) !== 4);
        }, [userRoleID]);

        const getDialogConfig = () => {
          if (dialogData.status !== "view") return { content: null, maxWidth: 'sm' };
          
          // Define dialog configurations for different action types
          const dialogConfigs = {
              view_gop_info: {
                  content: <ViewFormOutput description={dialogData.information} />,
                  maxWidth: 'sm'
              },
              view_membership_features: {
                  content: <ViewFormOutputMembership />,
                  maxWidth: 'lg'
              },
              view_notes: {
                  content: <EBM_Result_Notes abrechnungskompassCSV_ID={abrechnungskompassCSV_ID} readOnlyNotes={readOnlyNotes} />,
                  maxWidth: 'md'
              }
          };
          
          // Return the config for the current action, or a default if not found
          return dialogConfigs[dialogData.action] || { content: null, maxWidth: 'sm' };
        };

        return (<>
        
          {
            (userStatus !== 3) ?  <Typography variant="h6" gutterBottom>
                                    {titleHeader} 
                                  </Typography> 
                                : ""
          }

            <TableContainer component={Paper} sx={{borderRadius:0, maxHeight: maxHeight}}>
                    <Table size="small" aria-label="simple table" sx={{tableLayout: 'auto'}}>
                      <TableHead>
                        {
                          (userStatus !== 3) ?
                            <TableRow sx={{
                                            backgroundColor: '#006400', 
                                            '& .MuiTableCell-root': { color: 'white', textAlign: 'right', whiteSpace: 'nowrap'},
                                            position: 'sticky',
                                            top: 0, // Ensures the header sticks to the top
                                            zIndex: 1, // Keeps it above other content when scrolling
                                          }}>
                              <TableCell sx={{textAlign: 'left!important'}}>{columns[0]}</TableCell>   
                              <TableCell sx={{textAlign: 'left!important'}}>{columns[1]}</TableCell>
                              <TableCell>{columns[2]}</TableCell>
                              <TableCell>{columns[3]}</TableCell>
                              <TableCell>{columns[4]}</TableCell>
                              <TableCell>{columns[5]}</TableCell>
                              {debug && <TableCell>EBM-Koeffizient</TableCell>}
                              {(tableType === "1" || debug) && columns.length > 6 && (
                                  <>
                                      <TableCell>{columns[6]}</TableCell>
                                      <TableCell>{columns[7]}</TableCell>
                                  </>
                              )}
                              {tableType === "2" && columns.length > 6 && <TableCell>{/* Caution */}</TableCell>}
                            </TableRow>
                          : ""
                        }
                      </TableHead>
                      <TableBody sx={{color: '#134020',  position: 'relative', '& .MuiTableCell-root': { border:0 }}}>
      
                        <MembershipStatus 
                          userStatus={userStatus}
                          onClick={()=>{ handleViewFormDialogFeatures() }}
                        />
      
                        {rows.map((row, index) => {
                            
                            const tableRows = [];
      
                            let outputGop = row.gop;
                            let outputTitle = row.title;
                            let ouputAnzahl = row.anzahl;
                            let outputIdealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);
                            let outputDeviation = ((!isNaN(parseFloat(row.deviation))) ? parseFloat(row.deviation).toFixed(0).toString().replace('.', ',') : 0);
                            let ouputDeviationInPercent = isNaN(parseFloat(row.deviation_in_percent)) ? '0' : parseFloat(row.deviation_in_percent).toFixed(2).toString().replace('.', ',') + '%';
                            
                            /*let outputGezamtpotenzial = (row.exclude_scheine === 1) ? (
                                                                                        (typeof row.euro_potencial_mutterschaftsvorsorge_ziffern === 'undefined') ? 
                                                                                        parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',') : parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern).toFixed(2).toString().replace('.', ',')
                                                                                      ) : (
                                                                                        parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',')
                                                                                      );*/

                              /*let outputGezamtpotenzial;
                              if (row.exclude_scheine === 1) {
                                if (typeof row.euro_potencial_mutterschaftsvorsorge_ziffern === 0) {
                                  outputGezamtpotenzial = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                                } else {
                                  outputGezamtpotenzial = parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern).toFixed(2).toString().replace('.', ',');
                                }
                              } else {
                                outputGezamtpotenzial = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                              }*/

                                let outputGezamtpotenzial;
                                
                                if (row.exclude_scheine === 1) {
                                  if (row.euro_potencial_mutterschaftsvorsorge_ziffern === 0) {
                                    //outputGezamtpotenzial = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                                    outputGezamtpotenzial = parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',');
                                  } else {
                                    outputGezamtpotenzial = parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern).toFixed(2).toString().replace('.', ',');
                                  }
                                } else {
                                  outputGezamtpotenzial = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                                }

                            /*let outputIhrPotenzialInEuro =  (typeof row.euro_potencial_show === 'undefined') ? 
                                                                  parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',') : 
                                                                    (row.euro_potencial_show) ? 
                                                                        (typeof row.adjusted_euro_potencial === 'undefined') ? 
                                                                            parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',') : parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',') 
                                                                    : '-';*/

                            let outputIhrPotenzialInEuro;
                            
                            //outputIhrPotenzialInEuro = parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',');

                            if(row.euro_potencial_show){
                                  outputIhrPotenzialInEuro = parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',');
                            }else{
                                  outputIhrPotenzialInEuro = '-';
                            }
                            

                                /*let outputIhrPotenzialInEuro;

                                if (typeof row.euro_potencial_show === 'undefined') {
                                    outputIhrPotenzialInEuro = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                                } else {
                                    if (row.euro_potencial_show) {
                                        if (typeof row.adjusted_euro_potencial === 'undefined') {
                                            outputIhrPotenzialInEuro = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                                        } else {
                                            outputIhrPotenzialInEuro = parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',');
                                        }
                                    } else {
                                        outputIhrPotenzialInEuro = '-';
                                    }
                                }*/
                            
                            let outputDeviationInPercentStatus = checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).info; 
      
                              if(debug){
                                  //console.log("row.length")
                                  //console.log(row.length)
                              }
                              
                              if (row.header_description !== currentHeader) {
                                
                                currentHeader = row.header_description;
                                
                                countRowForBgColor = 0;
      
                                processOutputCSV += '"' + currentHeader + '"\n';
                                
                                // Add new section to JSON
                                currentSection = {
                                    header: currentHeader,
                                    rows: []
                                };
                                tableData.sections.push(currentSection);
                                
                                  tableRows.push(
                                    <TableRow key={index} sx={{backgroundColor: '#CAE3B6', '& .MuiTableCell-root': { color: '#134020'}}}>
                                      <TableCell colSpan={10}>
                                        <ContentComponent 
                                          userStatus={userStatus} 
                                          content={
                                            <div dangerouslySetInnerHTML={{ __html: currentHeader }} />
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ) 
                              }
      
                              if((checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).backgroundColor !== '#00000000')){
                                backgroundColorRow = checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).backgroundColor;
                              }else{
                                backgroundColorRow = (countRowForBgColor % 2 === 0 ? '#FFFFFF' : '#F1F2F4')
                              }

                              colorTextRow = (checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color);
      
                                //if(userStatus){
                                  tableRows.push(
                                    <TableRow
                                        key={row.id}
                                        sx={{ 
                                              '&:last-child td, &:last-child th': { border: 0 }, 
                                              backgroundColor: backgroundColorRow,
                                              '& .MuiTableCell-root': {color: colorTextRow, textAlign: 'right'},
                                              '& .MuiTableCell-root a': {color: checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color_href, textDecorationColor: checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color_href }
                                          }} 
                                      >
                                        <TableCell sx={{textAlign: 'left!important'}}>
                                          
      
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={
                                              
                                              (row.description !== null) 
                                                ? <>
                                                    <Link 
                                                        sx={{cursor:'pointer'}} 
                                                        onClick={()=>{ handleViewForm(row.gop, row.description) }}
                                                    >{outputGop}
                                                    </Link>
                                                  </> : <>{outputGop}</>
      
                                            }
                                          />
      
                                        </TableCell> {/*EBM-Ziffer*/}
                                        <TableCell sx={{textAlign: 'left!important'}}>
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={
                                              
                                                <Tooltip title={outputTitle} placement="top">
                                                <TextField 
                                                  defaultValue={outputTitle} 
                                                  size="small" 
                                                  fullWidth  
                                                  InputProps={{
                                                    readOnly: true,
                                                    sx: {
                                                          fontSize: '14px',
                                                          '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent', // Removes the border color
                                                          },
                                                          '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent', // Removes border color on hover
                                                          },
                                                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent', // Removes border color on focus
                                                          },
                                                        }
                                                  }}
                                                  inputProps={{
                                                    style: {
                                                      padding: '3px',   // Inner padding of the input element
                                                      overflow: 'hidden',         // Hide overflowing content
                                                      whiteSpace: 'nowrap',       // Prevent text from wrapping
                                                      textOverflow: 'ellipsis',   // Show ellipsis (...) when text overflows
                                                      color: (checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color)
                                                    },
                                                  }}
                                                />
                                                </Tooltip>
      
                                            }
                                          />
                                        </TableCell> {/*Leistung*/}
                                        <TableCell> 
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={ouputAnzahl} 
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={ outputIdealNumberOfTreatments } 
                                          />
                                        </TableCell> {/*Ideale Anzahl an Behandlungen (Soll)*/}
                                        {/*<TableCell>{ parseFloat(row.deviation).toFixed(2).toString().replace('.', ',') }</TableCell>*/} {/*Abweichung*/}  
                                        <TableCell>
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={ outputDeviation } 
                                          />
                                          </TableCell> {/*Abweichung*/}
                                        <TableCell> 
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={ ouputDeviationInPercent }
                                          />
                                        </TableCell>{/*Abweichung %*/}    
                                        { (debug) ? <TableCell>{ row.ebm_koeffizient.toString().replace('.', ',') }</TableCell>: <></> } {/*EBM-Koeffizient*/}                                            
                                        
                                        { 
                                          (tableType === "1" || debug) ? 
                                          <>
                                            <TableCell>
                                              <ContentComponent 
                                                userStatus={userStatus} 
                                                content={ outputGezamtpotenzial } 
                                              />
                                            </TableCell> {/*Gesamtpotenzial*/}
                                            <TableCell> 
                                              <ContentComponent 
                                                userStatus={userStatus} 
                                                content={ outputIhrPotenzialInEuro } 
                                              />
                                            </TableCell> {/*Ihr Potenzial in €*/}
                                          </>
                                          : 
                                          <></>
                                        }
      
                                        {
                                          (tableType === "2") ? 
                                            <TableCell sx={{textAlign: 'left!important'}}>
                                              <ContentComponent 
                                                userStatus={userStatus} 
                                                content={
                                                  <Box sx={{display: 'flex', alignItems: 'center', fontWeight: 'bold'}}>
                                                    <Box>{checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).icon}</Box>
                                                    <Box sx={{ml: 1}}>{checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).info}</Box>
                                                  </Box>
                                                } 
                                              />
                                            </TableCell> : ""
                                        } 
                                      </TableRow>
      
                                  )
                                //}
      
                                processOutputCSV +=  '"' + outputGop + '",' +
                                                      '"' + outputTitle + '",' +
                                                      '"' + ouputAnzahl  + '",' +
                                                      '"' + outputIdealNumberOfTreatments + '",' +
                                                      '"' + outputDeviation + '",' +
                                                      '"' + ouputDeviationInPercent + '"' +
                                                      ((tableType === '1' || debug) ? ',"' + outputGezamtpotenzial + '","' + outputIhrPotenzialInEuro + '"' : '') +
                                                      ((tableType === '2') ? ',"' + outputDeviationInPercentStatus + '"' : '') + '\n';
    
                                countRowForBgColor++;
                                
                                const rowData = {
                                    gop: outputGop,
                                    title: outputTitle,
                                    anzahl: ouputAnzahl,
                                    ideal_number_of_treatments: outputIdealNumberOfTreatments,
                                    deviation: outputDeviation,
                                    deviation_in_percent: ouputDeviationInPercent,
                                    backgroundColorRow: backgroundColorRow,
                                    colorTextRow: colorTextRow
                                };
                                
                                if (tableType === "1" || debug) {
                                    rowData.gesamtpotenzial = outputGezamtpotenzial;
                                    rowData.ihr_potenzial = outputIhrPotenzialInEuro;
                                }
                                
                                if (tableType === "2") {
                                    rowData.outputDeviationInPercentStatus = outputDeviationInPercentStatus;
                                }
                                
                                // Add row to current section
                                currentSection.rows.push(rowData);

                                return (userStatus !== 3) ? tableRows : "";
                          }
                        )}
                        
                      </TableBody>
                    </Table>
              </TableContainer>
              
              {
                (tableType === "2" && viewOnlyMode && userStatus === 1) ? 
                <>                
                  <br />
                  <Button variant="contained" onClick={handleNotes}><TextSnippetIcon /></Button>
                </> : ""
              }
              
              {
                  dialogData.status === "view" ? (
                      <DialogOutput
                          fullScreen={false}
                          open={open}
                          dialogData={dialogData}
                          handleClose={handleClose}
                          maxWidth={getDialogConfig().maxWidth}
                          output={getDialogConfig().content}
                      />
                  ) : null
              }
        </>);
    }
    
    
    return (
        <>
            <MembershipStatusDanke userStatus={userStatus} />

            <TableFilter 
              rowsResult={rowsResult} 
              only_list_include_chart={true} 
              titleHeader="EBM-Ziffern der freien Leistungen" 
              tableType="1" 
              debug={false} 
            />

            <br />
            
            <TableFilter 
              rowsResult={rowsResult} 
              titleHeader="Gesamttabelle" 
              tableType="2" 
              debug={false}
              viewOnlyMode={viewOnlyMode}
            />

        </>
    );
}
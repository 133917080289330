import React, { useEffect, useState, createRef, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import * as DatabaseService from '../../../../services/DatabaseService';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import DialogOutput from '../../../../components/DialogOutput';
import ViewFormOutput from '../../../../components/ViewFormOuput';
import ViewFormOutputStyle from '../../../GOÄ_Abrechnungskompass_Calculation/styles/ViewFormOutputStyle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import germanFormatCurrency from '../../../../utils/germanFormatCurrency';
import { filterAndSortRows } from '../../utils/sortFilteredRows';
import { IconButton, Autocomplete, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import TableSeparateStyle from '../../styles/TableSeparateStyle';
import calculateFieldWithFactor from '../../calculations/calculateFieldWithFactor';
import GOAE_Factor_Table_Result from './results/GOAE_Factor_Table_Result';
import Charts_Section from '../../results/components/Charts_Section';
import { getFactorFromMapping } from '../../utils/getFactorFromMapping';
import { getAnzahlFromMapping } from './utils/getAnzahlFromMapping';
import { colorName } from '../../../../components/colors/Colors';
import { useSingleUserData } from '../../../../services/userService';
import { LoadingSkeletonLayout } from '../../components/Loading/LoadingSkeletonLayout';

const Krebsfrueherkennung_Calculation = (props) => {
    const { factorMapping, kasuistikenTitle, enableRightTable_H_and_H1_Calculation = false } = props;

    const { userStatus, userRoleID, userKV, useUpdateInformation, sessionUserInfo, isLoadingUserService } = useSingleUserData();

    const location = useLocation();
    const [debug, setDebug] = useState(false);
    const [listDataFromDB, setRowDataFromDB] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalRowsCount, setTotalRowsCount] = useState(0);

    const defaultFaktor = '2,3';
    const defaultAnzahl = 1;

    const [fields, setFields] = useState(() => {
        // Create fields based on factorMapping structure
        return factorMapping.map(mapping => {
            // Create fields directly from mapping.list
            return mapping.list.map(item => ({
                ziffer: '',
                anzahl: item.anzahl || defaultAnzahl,
                faktor: defaultFaktor,
                hasTyped: false,
                mappingTitle: mapping.title,
            }));
        }).flat();
    });

    // Modify the fields state initialization

    const [showFactorAndAmount, setShowFactorAndAmount] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "", dialog_type: "" });
    const [openDialogOnce, setOpenDialogOnce] = React.useState(false);

    const [showZifferInformation, setShowZifferInformation] = useState(false);

    const [sumTable1, setSumTable1] = useState(0);
    const [sumTable2, setSumTable2] = useState(0);

    const [sumOfZifferWithH1, setSumOfZifferWithH1] = useState(0);

    const [isContentReady, setIsContentReady] = useState(false);

    const getGOÄ_ZifferAbrechnungskompassData = async () => {

        try {
            const JSONData = await DatabaseService.getGOÄ_Abrechnungskompass();
            if (JSONData && JSONData.rows) {
                const filteredRows = filterAndSortRows(JSONData.rows);
                setRowDataFromDB(filteredRows);
            }

            if (JSONData && JSONData.totalCount) {
                setTotalRowsCount(JSONData.totalCount); // Set the user data array to listAllUsers
                //setRowsPerPage(JSONData.totalCount)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }

        //setLoading(false); // Set loading to false after data is fetched
    }

    useEffect(() => {
        
        getGOÄ_ZifferAbrechnungskompassData()
            .then(() => {})
            .catch(error => {
                console.log('Error fetching data:', error);
            });
        
    }, []);

    useEffect(() => {
        const updatedRows = listDataFromDB.map(item => ({
            id: item.id,
            ziffer: item.ziffer,
            coefficient: item.coefficient,
            factor: item.factor,
            pre_assigned_factor: item.pre_assigned_factor,
            title: item.title,
            description: item.description,
            text_legende: item.text_legende,
            punktzahl: item.punktzahl,
            main_value: item.main_value
        }));
        setRows(updatedRows);
    }, [listDataFromDB]);

    useEffect(() => {

        if(new URLSearchParams(location.search).get('debug') !== null) {
        setDebug(true);  
        }

    }, [])

    // Add calculation function
    const calculateTotalH1 = useCallback((currentFields) => {
        return (currentFields || [])
            .filter(field => field?.ziffer?.includes('H1') ?? false)
            .reduce((acc, field) => acc + calculateFieldWithFactor(rows, field), 0);
    }, [rows]);

    const exists3541H = fields.some(field => field.ziffer === '3541H');

     const row3541H = {
            ziffer: '3541H',
            anzahl: getAnzahlFromMapping('3541H', factorMapping, defaultAnzahl),
            faktor: getFactorFromMapping('3541H', rows, defaultFaktor, factorMapping),
            hasTyped: false
    };

    const calculate3541HValue = useCallback(() => {
            return row3541H ? calculateFieldWithFactor(rows, row3541H) : 0;
    }, [fields, rows]);

    const betragOf3541H = calculate3541HValue();

    const handleAutocompleteInputChange = (index, event, mappingIndex) => {
        const newFields = [...fields];
        const newZiffer = event?.target?.value || '';
        
        // Get the current mapping
        const currentMapping = factorMapping[mappingIndex];
        
        // Find the actual index by counting fields with the same mapping title
        const fieldsInCurrentMapping = fields.filter(f => f.mappingTitle === currentMapping.title);
        const actualIndex = fields.findIndex(f => 
            f.mappingTitle === currentMapping.title && 
            fieldsInCurrentMapping.indexOf(f) === index
        );
    
        if (actualIndex === -1) return; // Safety check
    
        // Check if ziffer exists in database rows
        const zifferExists = rows.some(row => row.ziffer === newZiffer);
        if (!zifferExists && newZiffer !== '') return; // Don't allow non-existent ziffers
    
        // Always allow change since we're not restricted by mapping list
        newFields[actualIndex] = { 
            ...newFields[actualIndex], 
            ziffer: newZiffer,
            anzahl: getAnzahlFromMapping(newZiffer, factorMapping, defaultAnzahl, currentMapping.title),
            faktor: getFactorFromMapping(newZiffer, rows, defaultFaktor, factorMapping),
            mappingTitle: currentMapping.title
        };
        
        setShowZifferInformation(newFields.some(field => field.ziffer !== '' && field.ziffer !== null));
        setFields(newFields);
    };

    const handleAutocompleteInputKeyDown = (event, index, inputValue, mappingIndex) => {
        if (event.key === 'Enter' && inputValue) {
            // Get the current mapping using mappingIndex
            const currentMapping = factorMapping[mappingIndex];
            
            // Check if input exactly matches any option in currentMapping.list
            const exactMatch = currentMapping.list
                .map(item => item.ziffer)
                .find(ziffer => ziffer.toLowerCase() === inputValue.toLowerCase());
            
            if (exactMatch) {
                handleAutocompleteInputChange(index, { target: { value: exactMatch } }, mappingIndex);
                return;
            }
    
            // If no exact match in mapping.list, check rows for partial matches
            const filteredOptions = currentMapping.list
                .map(item => item.ziffer)
                .filter(ziffer => 
                    ziffer &&
                    (!fields
                        .filter(f => f.mappingTitle === currentMapping.title)
                        .some(f => f.ziffer === ziffer && f !== fields[index])) &&
                    ziffer.toLowerCase().includes(inputValue.toLowerCase())
                );
            
            if (filteredOptions.length === 1) {
                handleAutocompleteInputChange(index, { target: { value: filteredOptions[0] } }, mappingIndex);
            }
        }
    };

    /*console.log("fields");
    console.log(fields);*/

    const handleSelectChange = (index, event, mappingIndex) => {
        const newFields = [...fields];
        const currentMapping = factorMapping[mappingIndex];
        
        // Find the actual index by counting fields with the same mapping title
        const fieldsInCurrentMapping = fields.filter(f => f.mappingTitle === currentMapping.title);
        const actualIndex = fields.findIndex(f => 
            f.mappingTitle === currentMapping.title && 
            fieldsInCurrentMapping.indexOf(f) === index
        );
    
        if (actualIndex === -1) return; // Safety check
        
        newFields[actualIndex] = { 
            ...newFields[actualIndex], 
            anzahl: event.target.value 
        };
        setFields(newFields);
    };

    const handleInputFactorChange = (index, event) => {
        let value = event.target.value;
        const regex = /^[0-9,.]*$/;
    
        if (regex.test(value) || value === '') {
            value = value.replace(/,/g, '.').replace(/\./g, ',');
            const newFields = [...fields];
            newFields[index] = { 
                ...newFields[index], 
                faktor: value,
                hasTyped: true 
            };
            setFields(newFields);
        }
    };

    const addInputField = (mappingTitle) => {
        const newField = {
            ziffer: '',
            anzahl: defaultAnzahl,
            faktor: defaultFaktor,
            hasTyped: false,
            mappingTitle: mappingTitle,
        };
        setFields([...fields, newField]);
    };
    
    const removeInputField = (index, mappingTitle) => {
        setFields(prevFields => {
            const fieldsInMapping = prevFields.filter(f => f.mappingTitle === mappingTitle);
            const fieldToRemove = fieldsInMapping[index];
            
            if (!fieldToRemove) return prevFields;
            
            return prevFields.filter(field => field !== fieldToRemove);
        });
    };

    /*console.log("fields");
    console.log(fields);
    console.log(JSON.stringify(fields));*/

    const calculateAmount = () => {
        setShowFactorAndAmount(true);

        if(!showFactorAndAmount) {
            window.scrollTo(0, 0);
        }
    };

    //console.log(fields);

    const options = [1, 2, 3]; // Define your options array here

    const handleViewForm = (ziffer, description) => {
        setDialogInformation({ title: "", information: description, status: "view", dialog_type: "goae_ziffer_information" });
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const title1 = "GOÄ-Ziffer";
    const title2 = "Anzahl";

    useEffect(() => {
        const newTotalBetrag = fields.reduce((acc, field) => 
            acc + calculateTotalAmount(rows, field), 0
        );
        
        setSumTable1(newTotalBetrag);
    }, [fields, rows]);

    // Update useEffect to recalculate when fields change
    useEffect(() => {
        const newTotalH1 = calculateTotalH1(fields);
        setSumOfZifferWithH1(newTotalH1);
    
        if (newTotalH1 > betragOf3541H && !exists3541H) {
            // Find the mapping that contains 3541H
            const mapping3541H = factorMapping.find(mapping => 
                mapping.list.some(item => item.ziffer === '3541H')
            );
    
            // Only add 3541H if we have a valid mapping
            if (mapping3541H) {
                // Create new field for 3541H
                const new3541HField = {
                    ziffer: '3541H',
                    anzahl: getAnzahlFromMapping('3541H', factorMapping, defaultAnzahl),
                    faktor: getFactorFromMapping('3541H', rows, defaultFaktor, factorMapping),
                    hasTyped: false,
                    mappingTitle: mapping3541H.title
                };
    
                // Update fields while preserving other mappings
                setFields(prevFields => {
                    // Get all fields except the ones with mapping3541H.title
                    const otherFields = prevFields.filter(f => f.mappingTitle !== mapping3541H.title);
                    // Get all fields from the current mapping
                    const currentMappingFields = prevFields.filter(f => f.mappingTitle === mapping3541H.title);
                    
                    return [
                        ...otherFields, // Keep fields from other mappings
                        ...currentMappingFields, // Keep existing fields in current mapping
                        new3541HField // Add new 3541H field
                    ];
                });
    
                if (!openDialogOnce) {
                    handleViewForm("3541H", rows.find(row => row.ziffer === "3541H").description);
                    setOpenDialogOnce(true);
                }
            }
        }
    }, [fields, calculateTotalH1, rows, betragOf3541H, exists3541H, factorMapping]);

    useEffect(() => {
        if (rows.length > 0) {
            setIsContentReady(true);
        }
    }, [rows]);

    const calculateTotalAmount = (rows, field) => {
            
        const h1Total = calculateTotalH1(fields);
        
        /*console.log('Debug values:', {
            ziffer: field.ziffer,
            betragOf3541H,
            h1Total,
            sumOfZifferWithH1
        });*/

        if (!field?.ziffer) {
            return 0;
        }         

        if(field?.ziffer?.includes('H1') ?? false) {
            const calculatedAmount = calculateFieldWithFactor(rows, field);
            const shouldBeZero = sumOfZifferWithH1 > betragOf3541H;
            /*console.log('H1 calculation:', { calculatedAmount, shouldBeZero });
            console.log('H1 shouldBeZero: >', { sumOfZifferWithH1, betragOf3541H, shouldBeZero });*/
            return shouldBeZero ? 0 : calculatedAmount;
        } else if(field.ziffer === '3541H') {
            const calculatedAmount1 = calculateFieldWithFactor(rows, field);
            const shouldBeZero1 = sumOfZifferWithH1 < betragOf3541H;
            /*console.log('H calculation:', { shouldBeZero1 });
            console.log('H shouldBeZero: <', { sumOfZifferWithH1, betragOf3541H, shouldBeZero1 });*/
            return shouldBeZero1 ? 0 : calculatedAmount1;
            //return calculatedAmount1;
        }else {
            return calculateFieldWithFactor(rows, field);
        }
    };

    return (
        <>
            <Typography variant="h5" component="h1" gutterBottom sx={{color: '#014017'}}>
                { kasuistikenTitle }
            </Typography>
            <br />
            
            {showFactorAndAmount && (<>
                <Charts_Section sumTable1={sumTable1} sumTable2={sumTable2} />
                <br />
                </>
            )}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} sx={{position: 'relative'}}>
                    {!isContentReady ? ( <> <LoadingSkeletonLayout /> </> ) : ( <>
                        <TableSeparateStyle>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="simple table">
                                    <TableHead>
                                        {(title1 !== "" && title2 !== "") && (
                                            <>
                                                <TableRow>
                                                    <TableCell className="TableCell" colSpan={showZifferInformation ? 2 : 0}>
                                                        <Box sx={{padding:'9px 0'}}>{title1}</Box>
                                                    </TableCell>
                                                    <TableCell className="TableCell">{title2}</TableCell>
                                                    <TableCell className="TableCell">Faktor</TableCell>
                                                    <TableCell className="TableCell">Betrag</TableCell>
                                                    <TableCell className="TableCell">Aktion</TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                    </TableHead>
                                    <TableBody>
                                        {factorMapping.map((mapping, mappingIndex) => (
                                            <React.Fragment key={`mapping-${mappingIndex}`}>
                                                <TableRow>
                                                    <TableCell 
                                                        className="TableCell" 
                                                        colSpan={showZifferInformation ? 7 : 6}
                                                        sx={{ 
                                                            backgroundColor: colorName.gray,
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {mapping.title || ''}
                                                    </TableCell>
                                                </TableRow>
                                                {fields
                                                    .filter(field => 
                                                        // Only show fields that belong to current mapping title
                                                        field.mappingTitle === mapping.title
                                                    )
                                                    .map((field, index) => {
                                                        const tableRows = [];
                                                        
                                                        const rowColor = field.ziffer && !mapping.list.some(item => 
                                                            item.ziffer === field.ziffer
                                                        );

                                                        tableRows.push(
                                                            <TableRow key={`input-${mappingIndex}-${index}`} sx={{ 
                                                                backgroundColor: (showFactorAndAmount && rowColor) ? colorName.lightorange : 'inherit',
                                                                boxShadow: field.ziffer === '3541H' ? `inset 0 0 0 5px ${colorName.red}` : 'none'
                                                            }} >
                                                                {
                                                                    showZifferInformation ? (
                                                                        <TableCell width={"10%"} className="TableCell">
                                                                        {
                                                                            field.ziffer && rows.find(row => row.ziffer === field.ziffer) ? (
                                                                                <IconButton variant="contained" onClick={() => handleViewForm(field.ziffer, rows.find(row => row.ziffer === field.ziffer).description)}><InfoIcon /></IconButton>
                                                                            ) : null
                                                                        }
                                                                        </TableCell>
                                                                    ) : null
                                                                }
                                                                <TableCell width={"30%"} className="TableCell">
                                                                    <Autocomplete
                                                                        options={(() => {
                                                                            // Get all ziffers from database rows without filtering by mapping
                                                                            let options = rows
                                                                                .map(row => row.ziffer)
                                                                                // Remove duplicates, but only check against fields in the current mapping
                                                                                .filter((ziffer, idx, self) => {
                                                                                    // Special handling for 3541H
                                                                                    if (field.ziffer === '3541H' && ziffer === '3541H') {
                                                                                        return true;
                                                                                    }
                                                                                    // Allow same ziffer in different mappings (Tags)
                                                                                    const usedInCurrentMapping = fields
                                                                                        .filter(f => 
                                                                                            f.mappingTitle === mapping.title && 
                                                                                            f !== field
                                                                                        )
                                                                                        .some(f => f.ziffer === ziffer);

                                                                                    return self.indexOf(ziffer) === idx && !usedInCurrentMapping;
                                                                                });
                                                                                                                        
                                                                            // Sort options numerically and alphabetically
                                                                            return options.sort((a, b) => {
                                                                                // Extract numeric and non-numeric parts
                                                                                const [, numA = '0', suffixA = ''] = a.match(/(\d+)([A-Za-z]*)/) || [];
                                                                                const [, numB = '0', suffixB = ''] = b.match(/(\d+)([A-Za-z]*)/) || [];
                                                                                
                                                                                // Compare numeric parts first
                                                                                const numCompare = parseInt(numA) - parseInt(numB);
                                                                                
                                                                                // If numeric parts are equal, compare suffixes
                                                                                if (numCompare === 0) {
                                                                                    return suffixA.localeCompare(suffixB);
                                                                                }
                                                                                return numCompare;
                                                                            });
                                                                        })()}
                                                                        getOptionLabel={(option) => option}
                                                                        value={field.ziffer || null}
                                                                        onChange={(event, newValue) => handleAutocompleteInputChange(index, { target: { value: newValue } }, mappingIndex)}
                                                                        onKeyDown={(event) => handleAutocompleteInputKeyDown(event, index, event.target.value, mappingIndex)}
                                                                        renderInput={(params) => (
                                                                            <TextField 
                                                                                {...params} 
                                                                                variant="outlined" 
                                                                                size="small" 
                                                                                fullWidth   
                                                                            />
                                                                        )}
                                                                    />
                                                                </TableCell>
                                                                <TableCell width={"30%"} className="TableCell">
                                                                    <Select
                                                                        value={field.anzahl}
                                                                        onChange={(event) => handleSelectChange(index, event, mappingIndex)}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        fullWidth
                                                                        sx={{ 
                                                                            textAlign: 'center'
                                                                        }}>
                                                                        {options.map((option) => (
                                                                            <MenuItem key={option} value={option}>
                                                                                {option}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </TableCell>
                                                                <TableCell width={"30%"} className="TableCell">
                                                                    <TextField
                                                                        value={field.faktor}
                                                                        onChange={(event) => handleInputFactorChange(index, event)}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        fullWidth
                                                                        sx={{ 
                                                                            '& .MuiInputBase-input': {
                                                                                textAlign: 'center'
                                                                            }
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell width={"30%"} className="TableCell" sx={{textWrap: 'nowrap'}}>{ germanFormatCurrency(calculateTotalAmount(rows, field)) }</TableCell>
                                                                {/*<TableCell width={"10%"} className="TableCell">
                                                                    <IconButton variant="contained" onClick={() => removeInputField(index)}><DeleteIcon /></IconButton>
                                                                </TableCell>*/}
                                                                <TableCell width={"10%"} className="TableCell">
                                                                    <IconButton 
                                                                        variant="contained" 
                                                                        onClick={() => removeInputField(index, mapping.title)}
                                                                        size="small"
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                        return tableRows;
                                                })}
                                                <TableRow>
                                                    <TableCell 
                                                        colSpan={showZifferInformation ? 7 : 6}
                                                        className="TableCell"
                                                        sx={{ borderBottom: 0 }}
                                                    >
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            onClick={() => addInputField(mapping.title)}
                                                        >
                                                            + Eingabefeld hinzufügen
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                {mappingIndex === factorMapping.length - 1 && 
                                                    fields.filter(f => f.mappingTitle === mapping.title).length > 0 && (
                                                        <React.Fragment key={`totals-${mappingIndex}`}>
                                                            <TableRow key={`total-sum-${mappingIndex}`}>
                                                                <TableCell className="TableCell" colSpan={showZifferInformation ? 2 : 0} sx={{borderRight:"0!important"}}><Box sx={{p:'17px'}}></Box></TableCell>
                                                                <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}></TableCell>
                                                                <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}></TableCell>
                                                                <TableCell className="TableCell total" sx={{textWrap: 'nowrap'}}>{germanFormatCurrency(sumTable1)}</TableCell>
                                                                <TableCell className="TableCell"></TableCell>
                                                            </TableRow>
                                                            {debug && (
                                                                <TableRow key={`total-debug-${mappingIndex}`}>
                                                                    <TableCell className="TableCell" colSpan={showZifferInformation ? 2 : 0} sx={{borderRight:"0!important"}}><Box sx={{p:'17px'}}></Box></TableCell>
                                                                    <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}></TableCell>
                                                                    <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}>SUM of H1 Treatments</TableCell>
                                                                    <TableCell className="TableCell total" sx={{textWrap: 'nowrap'}}>{germanFormatCurrency(sumOfZifferWithH1)}</TableCell>
                                                                    <TableCell className="TableCell"></TableCell>
                                                                </TableRow>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableSeparateStyle>
                        <br />
                        <Button variant="contained" onClick={()=>{ calculateAmount() }}>Mit optimaler Abrechnung vergleichen</Button>
                    </>)} 
                </Grid>
                {showFactorAndAmount && (
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{position: 'relative', zIndex: 0}}>
                        <GOAE_Factor_Table_Result 
                            rows={rows} 
                            fields={fields} 
                            title1={title1} 
                            title2={title2} 
                            defaultFaktor={defaultFaktor} 
                            defaultAnzahl={defaultAnzahl} 
                            setSumTable2={setSumTable2} 
                            handleViewForm={handleViewForm}
                            factorMapping={factorMapping}
                            enableRightTable_H_and_H1_Calculation={enableRightTable_H_and_H1_Calculation}
                            userStatus={userStatus.outputGoaeKasuistikenAccess}
                            isLoadingUserService={isLoadingUserService}
                            debug={debug}
                        />
                    </Grid>
                )}
            </Grid>
            {
                dialogData.dialog_type === "goae_ziffer_information" ? (
                    <DialogOutput
                        fullScreen={dialogData.dialog_type === "results"}
                        open={open}
                        dialogData={dialogData}
                        handleClose={handleClose}
                        backgroundColor={dialogData.dialog_type === "results" ? '#F1F2F4' : undefined}
                        output={
                            dialogData.dialog_type === "goae_ziffer_information" ? (
                                <ViewFormOutputStyle><ViewFormOutput description={dialogData.information} /></ViewFormOutputStyle>
                            ) : null
                        }
                        maxWidth={(dialogData.dialog_type === "goae_ziffer_information") ? 'md': 'sm'}
                    />
                ) : null
            }
        </>
    );
};

export default Krebsfrueherkennung_Calculation;
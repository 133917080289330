import { userSalutationList, userTitleList } from '../../../../constants/userOptionsList';
import readableDateFormat from '../../../../utils/readableDateFormat';

const displayInfo = (userInfo, created_at = "") => {
    const showColon = created_at ? ':' : '';
    const userSalutation = userSalutationList.find(salutation => salutation.id === userInfo.salutation && salutation.id !== '')?.title || '';
    const userTitle = userTitleList.find(title => title.id === userInfo.user_title && title.id !== '')?.title || '';
    const formattedDate = created_at ? 'Erstellungsdatum: ' + readableDateFormat(created_at) : '';

    return `GOÄ Abrechnungsergebnis für ${userSalutation} ${userTitle} ${userInfo.fname} ${userInfo.lname}${showColon} ${formattedDate}`;
};

export default displayInfo;
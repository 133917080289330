import React, { useState } from 'react';
import * as DatabaseService from '../../../../../services/DatabaseService';
import { TextField, Button, Snackbar, Alert, Switch, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';

const InsertEBMCoefficientForm = (props) => {

    const { kv, onInsertSuccess } = props;

    const [gop, setGop] = useState('');
    const [median, setMedian] = useState('');
    const [includeChart, setIncludeChart] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [errors, setErrors] = useState({
        gop: false
    });

    const handleGopChange = (e) => {
        const value = e.target.value;
        const regex = /^[a-zA-Z0-9]*$/; // Only allow letters and numbers
        if (regex.test(value)) {
            setGop(value);
        }
    };

    const handleMedianChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9]*[,.]?[0-9]*$/; // Only allow numbers, comma, and dot

        if (regex.test(value)) {
            // Store the German format (with comma) in the UI
            const germanFormat = value.replace(/\./g, ',');
            setMedian(germanFormat);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate inputs
        const newErrors = {
            gop: !gop
        };
        setErrors(newErrors);

        if (newErrors.gop || newErrors.median) {
            setSnackbar({
                open: true,
                message: 'Bitte alle erforderlichen Felder ausfüllen',
                severity: 'error'
            });
            return;
        }

        const inserts = [{
            gop: gop,
            median: median,
            state_no: kv,
            include_chart: includeChart ? 1 : ''
        }];

        try {
            const result = await DatabaseService.insertEBMCoefficient(inserts);
            setSnackbar({
                open: true,
                message: result.success ? 'Insert erfolgreich' : result.message,
                severity: result.success ? 'success' : 'error'
            });
            if (result.success) {
                setGop('');
                setMedian('');
                setIncludeChart(false);
                if (onInsertSuccess) {
                    onInsertSuccess(); // Call the callback to refresh parent data
                }
            }
        } catch (error) {
            console.error('Error inserting coefficient:', error);
            setSnackbar({
                open: true,
                message: 'Fehler beim Einfügen des Koeffizienten',
                severity: 'error'
            });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex' }}>
            <TextField
                label="Ziffer"
                value={gop}
                onChange={handleGopChange}
                error={errors.gop}
                margin="normal"
                size="small"
            />
            <Box sx={{ width: '10px' }} />
            <TextField
                label="EBM Koeffizient"
                value={median}
                onChange={handleMedianChange}
                margin="normal"
                size="small"
            />
            </Box>
            <FormControlLabel
                control={
                    <Switch
                        checked={includeChart}
                        onChange={(e) => setIncludeChart(e.target.checked)}
                    />
                }
                label="Freie Leistung"
            />
            <Box sx={{ mt: '8px' }} />
            <Button type="submit" variant="contained" color="primary">
                Einfügen
            </Button>
            <Box sx={{ mt: 2 }}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbar.severity}
                    sx={{ width: '100%', display: snackbar.message ? 'flex' : 'none' }}
                >
                    {snackbar.message}
                </Alert>
            </Box>
        </form>
    );
};

export default InsertEBMCoefficientForm;
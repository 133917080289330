import React, { useState, useEffect, useMemo } from 'react';
import * as DatabaseService from '../../../services/DatabaseService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import percentToDecimal from '../../../utils/percentToDecimal';
import countIhrPotencialIncluded from '../calculations/countIhrPotencialIncluded';
import checkDeviationPercentColor from '../calculations/checkDeviationPercentColor';
import countGesamtpotenzialIncluded from '../calculations/countGesamtpotenzialIncluded';
import TableSection from './components/Table_Section';
import Charts_Section from './components/Charts_Section';
import { LoadingSkeletonLayout } from '../components/Loading/LoadingSkeletonLayout';
import DisplayInfoStyle from '../styles/DisplayInfoStyle';
import { captureElementAsImage, generateGOAEPdf } from './utils/pdfGeneratorUtils';
import displayInfo from './utils/displayInfo';
import { useEnvData } from '../../../services/envService';
import { colorName } from '../../../components/colors/Colors';

const GOÄ_Abrechnungskompass_Calculation_Results = (props) => {

    const { patienten=0, goaeZifferNumbersInput={}, viewOnlyMode, userStatus, userInfo={}, created_at } = props;

    const { isDevMode } = useEnvData();
    const [rowsFromDB, setRowsFromDB] = useState([]);
    const [rowsResult, setRowsResult] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [loadingCapture, setLoadingCapture] = useState(false); // Add loading state
    const [tableMaxHeight, setTableMaxHeight] = useState(500);
    const [tableData, setTableData] = useState(null);

    const handleTableData = (data) => {
        setTableData(data);
    };

     const getGOÄ_ZifferAbrechnungskompassInputRowsData = async () => {
        try {
          const JSONData = await DatabaseService.getGOÄ_Abrechnungskompass();
          if (JSONData && JSONData.rows) {
            setRowsFromDB(JSONData.rows);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } 
    };

    useEffect(() => {
        getGOÄ_ZifferAbrechnungskompassInputRowsData(); // Fetch initial data
    }, []);

    let displayInfoOutput = displayInfo(userInfo, created_at);

    const handleExportToPDF = async () => {
        try {
            setLoadingCapture(true)

            // First capture the chart as an image
            const chartImgData = await captureElementAsImage('capture');

            const result = await generateGOAEPdf({
                tableData, 
                chartImgData, 
                displayInfoOutput, 
                colorName,
                setLoadingCapture
            });

            // Save the PDF
            result.download();

            // Optional: Preview the PDF in a new tab
            //result.preview();
        } catch (error) {
            console.error('Error generating PDF:', error);
            // Create a basic PDF with error message as fallback
            try {
                const pdf = new jsPDF();
                pdf.text('Error generating PDF: ' + error.message, 20, 20);
                pdf.save('GOÄ_Abrechnungskompass_error.pdf');
            } catch (e) {
                console.error('Critical error creating fallback PDF:', e);
            }
        }
    }

    const captureScreenshot = () => {
        setLoadingCapture(true); // Set loading to true when capturing screenshot
        setTableMaxHeight('auto'); // Set table height to auto for full height capture
        requestAnimationFrame(() => { // Wait for the DOM to update
            const element = document.getElementById('capture'); // Adjust the ID to the element you want to capture
            if (element) {
                captureElementToPDF(element);
            } else {
                console.error('Element with ID "capture" not found.');
                setLoadingCapture(false); // Reset loading state if element is not found
            }
        }, 1000); // Adjust the timeout duration as needed
    };

    const captureElementToPDF = (element) => {
        html2canvas(element, {
            backgroundColor: null, // Remove background color to avoid gray bars or shadows
            scale: 2, // Increase the scale for better quality
            useCORS: true // Enable cross-origin resource sharing if needed
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth() - 20; // Add margin
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            const pageHeight = pdf.internal.pageSize.getHeight() - 20; // Add margin
            let heightLeft = pdfHeight;
            let position = 10; // Start position with margin

            pdf.addImage(imgData, 'PNG', 10, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight;

            while (heightLeft > 0) {
                position = heightLeft - pdfHeight + 10; // Adjust position for margin
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 10, position, pdfWidth, pdfHeight);
                heightLeft -= pageHeight;
            }

            const today = new Date();
            const date = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
            const filename = `GOÄ_Fachgruppenvergleich_${date}.pdf`;

            pdf.save(filename);
            setTableMaxHeight(500); // Reset table height back to 500px after capture
            setLoadingCapture(false); 
        });
    };

    useEffect(() => {
        if (rowsResult.length > 0) {
            setLoading(false); // Set loading to false when data is fetched
        }
    }, [rowsResult]);

    const memoizedRowsResult = useMemo(() => {
        if (rowsFromDB.length > 0) {
            return rowsFromDB.map(row => {

                let input_anzahl = goaeZifferNumbersInput[`anzahl_${row.ziffer}`] || 0;
                row.anzahl = input_anzahl;
                let patientenValue = patienten || 0;
                let ideal_number_of_treatments = patientenValue * percentToDecimal(parseFloat(row.coefficient));
                let abweichung_zur_fachgruppe = -(1-input_anzahl/ideal_number_of_treatments);
                abweichung_zur_fachgruppe = abweichung_zur_fachgruppe * 100;
                row.abweichung_zur_fachgruppe = abweichung_zur_fachgruppe;
                let deviation = (input_anzahl - ideal_number_of_treatments);
                row.deviation = deviation;
                let deviation_in_percent = abweichung_zur_fachgruppe;
                row.deviation_in_percent = deviation_in_percent;
                row.deviation_in_percent_status = checkDeviationPercentColor(deviation_in_percent, input_anzahl);
                row.ideal_number_of_treatments = ideal_number_of_treatments;
                let factor = (row.factor === null) ? 0 : parseFloat(row.factor);
                row.gesamtpotenzial = ideal_number_of_treatments * factor;
                row.ihr_potenzial = ideal_number_of_treatments * factor - parseFloat(input_anzahl) * factor;
                let ihr_potenzial_show = 1;
                if(parseInt(input_anzahl) <= parseInt(ideal_number_of_treatments)){
                    ihr_potenzial_show = 1;
                }else{
                    ihr_potenzial_show = 0;
                }
                row.ihr_potenzial_show = ihr_potenzial_show;

                return { ...row };
            });
        }
        return [];
    }, [rowsFromDB, patienten, goaeZifferNumbersInput]);

     useEffect(() => {
        if (rowsFromDB.length > 0) {
            setRowsResult(memoizedRowsResult);
        }
    }, [memoizedRowsResult]);

    const ihrPotencialIncluded = countIhrPotencialIncluded(rowsResult, goaeZifferNumbersInput);

    const gesamtpotenzial_included = countGesamtpotenzialIncluded(rowsResult, goaeZifferNumbersInput);

    return (
        <>
            {loading ? <LoadingSkeletonLayout /> : <>

                {
                        (parseInt(userStatus) === 1) && <>

                            <Box sx={{textAlign:'right'}}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={handleExportToPDF} 
                                    disabled={loadingCapture}
                                    startIcon={loadingCapture && <CircularProgress size={20} />}
                                >
                                    {loadingCapture ? 'Einfangend...' : 'PDF herunterladen'}
                                </Button>
                            </Box>
                            <br />
                        </>
                }
                
                <div style={{ marginTop: parseInt(userStatus) === 1 ? '-55px' : '0' }}>
                    <DisplayInfoStyle> { displayInfoOutput }</DisplayInfoStyle>
                </div>
                
                <br />

                <div id="capture">
                    <Charts_Section 
                        rowsResult={rowsResult} 
                        total_ihr_potenzial_included={ihrPotencialIncluded} 
                        gesamtpotenzial_included={gesamtpotenzial_included} 
                    />
                </div>

                <br /> 

                <TableSection 
                    rowsResult={rowsResult} 
                    patienten={patienten} 
                    goaeZifferNumbersInput={goaeZifferNumbersInput} 
                    viewOnlyMode={viewOnlyMode} 
                    userStatus={userStatus} 
                    debug={isDevMode} 
                    maxHeight={tableMaxHeight} 
                    onDataChange={handleTableData}
                />

                <br /><br />

            </>}
        </>
    );
}

export default GOÄ_Abrechnungskompass_Calculation_Results;
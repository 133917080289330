import React, { useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';

import { styled } from '@mui/system';

import MuiDrawer from '../../../../components/MuiDrawer';

import GynSuccess_Home_Header from '../../../../components/assets/images/template/GynSuccess_Home_Header.webp';

import { GOAE_PAGE_TYPES } from '../../../../constants/goaePageTypeList'

import { colorName } from '../../../../components/colors/Colors';

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    textTransform: 'none',
    fontSize: '20px',
    padding: '0.3em 1em'
  }));

const LightTypographyText = styled(Typography)(({ theme }) => ({
    fontFamily: 'BR Firma Light', 
    lineHeight: '1.8em'
}));

const GynSuccessComponent1 = () => {
    return (
      
        <Box >
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{color: '#014017'}}>
                          Schwangerenmanagement
                        </Typography>
                        <LightTypographyText variant="p" component="div" sx={{fontWeight: '300'}}>
                        Analyse und Optimierung Ihrer GOÄ Abrechnungskasuistiken im Bereich des Schwangerenmanagements.
                        </LightTypographyText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ borderRadius: '8px', overflow: 'hidden', display:'flex' }}>
                        <div
                            style={{
                                width: '100%',
                                height: '0',
                                paddingBottom: '70.96%', // Maintain aspect ratio (16:9)
                                backgroundImage: `url(${GynSuccess_Home_Header})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        />
                        </Paper>
                    </Grid>
                </Grid>
        </Box>
    );
  };

  const GynSuccessComponent4 = () => {

    const StyledBox = styled(Box)(({ theme }) => ({
        color: 'white',
        borderRadius: '16px',
        padding: theme.spacing(3),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }));

      return (
          <Grid container spacing={3}>
            {Object.values(GOAE_PAGE_TYPES)
              .filter(page => page.visibility)
              .map((page, index) => (
                <Grid item xs={12} sm={6} key={page.id}>
                  <StyledBox sx={{ backgroundColor: colorName.lightgreen }}>
                    <div>
                      <Typography variant="h5" gutterBottom align="center">
                        {page.name}
                      </Typography>
                    </div>
                    <StyledButton
                      variant="contained"
                      fullWidth
                      sx={{ marginTop: '1em' }}
                      component={NavLink}
                      to={page.link_name}
                    >
                      Zur Abrechnungskasuistik
                    </StyledButton>
                  </StyledBox>
                </Grid>
              ))}
          </Grid>
      );
  }

export default function GOÄ_Abrechnungskasuistiken_Schwangerenmanagement_Content() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
            <MuiDrawer>
              <Box sx={{ marginLeft: '-24px', marginRight: '-24px' }}>
                <Box sx={{ width: '80%', margin: 'auto'}}>
                    <GynSuccessComponent1 />

                    <Box sx={{ height: '24px' }}></Box>
                    <GynSuccessComponent4 />
                    <Box sx={{ height: '24px' }}></Box>
                </Box>
              </Box>
            </MuiDrawer>
          );
}
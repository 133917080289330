import MuiDrawer from '../../../../components/MuiDrawer';
import EBM_Editor from './EBM_Editor';

export default function EBM_Editor_Main() {

  return (
            <MuiDrawer>
                <EBM_Editor />
            </MuiDrawer>
          );
}
export const GOAE_KREBSFRUEHERKENNUNG_PARENT = {
    name: "Krebsfrüherkennung",
    link_name: "/gebuehrenordnung-fuer-aerzte-abrechnungskompass/abrechnungskasuistiken/krebsfrueherkennung",
};

export const GOAE_KREBSFRUEHERKENNUNG_PAGE_TYPES = {
    MAMMASONOGRAPHIE_SELBEN_TAG: {
        id: 1,
        name: "Mammasonographie am selben Tag",
        link_name:  GOAE_KREBSFRUEHERKENNUNG_PARENT.link_name + "/" + "mammasonographie-am-selben-tag",
        visibility: true
    },
    MAMMASONOGRAPHIE_ANDEREN_TAG: {
        id: 2,
        name: "Mammasonographie an einem anderen Tag",
        link_name: GOAE_KREBSFRUEHERKENNUNG_PARENT.link_name + "/" + "mammasonographie-an-einem-anderen-tag",
        visibility: true
    }
};
import React, { useEffect } from 'react';
import MuiDrawer from '../../../../components/MuiDrawer';
import PageNotFoundPage from '../../../PageNotFound/PageNotFound';
import Krebsfrueherkennung_Calculation from '../Krebsfrueherkennung/Krebsfrueherkennung_Calculation';
import Box from '@mui/material/Box';
import { colorName } from '../../../../components/colors/Colors';
import { MAMMASONOGRAPHIE_SELBEN_TAG_FACTOR_MAPPING } from './constants/Factor_Map/MAMMASONOGRAPHIE_SELBEN_TAG_FACTOR_MAPPING';
import { MAMMASONOGRAPHIE_ANDEREN_TAG_FACTOR_MAPPING } from './constants/Factor_Map/MAMMASONOGRAPHIE_ANDEREN_TAG_FACTOR_MAPPING';
import { GOAE_KREBSFRUEHERKENNUNG_PAGE_TYPES } from '../../../../constants/goaeKrebsfrueherkennungPageTypeList';

export default function Krebsfrueherkennung_Main(props) {

  const { GOAE_KREBSFRUEHERKENNUNG_PAGE_TYPE_ID } = props;

  useEffect(() => {
          window.scrollTo(0, 0)
  }, [])

  const preprocessFactorMapping = (mapping) => {
    const processSection = (section) => {
      // Create a map to track ziffer occurrences within this section
      const zifferMap = new Map();
  
      // Process list to combine duplicates and update anzahl
      const processedList = section.list.reduce((acc, item) => {
        if (zifferMap.has(item.ziffer)) {
          // Update anzahl of existing entry
          const existingItem = acc[zifferMap.get(item.ziffer)];
          existingItem.anzahl = (parseInt(existingItem.anzahl) + parseInt(item.anzahl)).toString();
          return acc;
        } else {
          // Add new entry
          zifferMap.set(item.ziffer, acc.length);
          acc.push({...item});
          return acc;
        }
      }, []);
  
      return {
        ...section,
        list: processedList
      };
    };
  
    // Process each section independently
    return mapping.map(section => {
      if (section.title && section.title.startsWith('Tag ')) {
        return processSection(section);
      }
      return section;
    });
  };

  const renderPage = () => {
    switch(GOAE_KREBSFRUEHERKENNUNG_PAGE_TYPE_ID) {
      case GOAE_KREBSFRUEHERKENNUNG_PAGE_TYPES.MAMMASONOGRAPHIE_ANDEREN_TAG.id :
        return <Krebsfrueherkennung_Calculation 
                  factorMapping={preprocessFactorMapping(MAMMASONOGRAPHIE_ANDEREN_TAG_FACTOR_MAPPING)} 
                  kasuistikenTitle="Mammasonographie an einem anderen Tag - Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor ein:"
                  enableRightTable_H_and_H1_Calculation={true}
                />;
      case GOAE_KREBSFRUEHERKENNUNG_PAGE_TYPES.MAMMASONOGRAPHIE_SELBEN_TAG.id :
        return <Krebsfrueherkennung_Calculation 
                  factorMapping={preprocessFactorMapping(MAMMASONOGRAPHIE_SELBEN_TAG_FACTOR_MAPPING)} 
                  kasuistikenTitle="Mammasonographie am selben Tag - Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor ein:"
                  enableRightTable_H_and_H1_Calculation={true}
                />;
      default:
        return <PageNotFoundPage />;
    }
  };

  return (
    <Box sx={{backgroundColor: colorName.gray, minHeight: '100vh' }}>
            <MuiDrawer>
              { renderPage() }
            </MuiDrawer>
    </Box>
  );
}
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import TableSeparateStyle from '../../../styles/TableSeparateStyle';
import { IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import germanFormatCurrency from '../../../../../utils/germanFormatCurrency';
import { colorName } from '../../../../../components/colors/Colors';
import calculateFieldWithFactor from '../../../calculations/calculateFieldWithFactor';
import { getFactorFromMapping } from '../../../utils/getFactorFromMapping';
import { getAnzahlFromMapping } from '../utils/getAnzahlFromMapping';

import { ContentComponent, MembershipStatus, MembershipStatusDanke } from '../../../components/ContentForFullMemberShip'
import ViewFormOutputMembership from '../../../components/ViewFormOutputMembership';
import DialogOutput from '../../../../../components/DialogOutput';
import { LoadingSkeletonLayout } from '../../../components/Loading/LoadingSkeletonLayout';

const GOAE_Factor_Table_Result = (props) => {

    const { rows, fields, title1, title2, defaultFaktor, defaultAnzahl, setSumTable2, handleViewForm, factorMapping, enableRightTable_H_and_H1_Calculation, userStatus, isLoadingUserService, debug } = props;

    const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });
    const [openDialogFeatures, setOpenDialogFeatures] = React.useState(false);

    const [rowsResult, setRowsResult] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [sumOfZifferWithH1, setSumOfZifferWithH1] = useState(0);

    const [totalBetrag, setTotalBetrag] = useState(0);

    const [isContentReady, setIsContentReady] = useState(false);

    //For H and H1 Calculation
    const calculateTotalH1 = useCallback((currentFields) => {
        return (currentFields || [])
            .filter(field => {
                // Check if field exists in any mapping list
                const existsInMapping = factorMapping.some(mapping =>
                    mapping.list.some(item => 
                        item.ziffer === field.ziffer && field.ziffer?.includes('H1')
                    )
                );
                return existsInMapping;
            })
            .reduce((acc, field) => acc + calculateFieldWithFactor(rows, field), 0);
    }, [rows, factorMapping]);

    const calculate3541HValue = useCallback(() => {
        const row3541H = rowsResult?.find(row => 
            row.ziffer === '3541H' && 
            factorMapping.some(mapping => 
                mapping.list.some(item => item.ziffer === '3541H')
            )
        ) ?? null;
        return row3541H ? calculateFieldWithFactor(rows, row3541H) : 0;
    }, [fields, rows, rowsResult, factorMapping]);

    const betragOf3541H = calculate3541HValue();
    //End of H and H1 Calculation

    const memoizedRowsResult = useMemo(() => {
        if (rows.length > 0) {
            // Flatten all ziffers from the factorMapping structure
            const mappedZiffers = factorMapping.reduce((acc, mapping) => {
                return acc.concat(mapping.list.map(item => ({
                    ziffer: item.ziffer,
                    anzahl: item.anzahl,
                    pre_assigned_factor: item.pre_assigned_factor,
                    mappingTitle: mapping.title
                })));
            }, []);
    
            // Create day-specific rows for each ziffer
            const daySpecificRows = rows.reduce((acc, row) => {
                // Find all day mappings for this ziffer
                const zifferMappings = mappedZiffers.filter(item => 
                    item.ziffer === row.ziffer
                );
    
                // If ziffer exists in mappings, create a row for each day it appears
                if (zifferMappings.length > 0) {
                    const dayRows = zifferMappings.map(mapping => ({
                        ...row,
                        id: `${row.id}_${mapping.mappingTitle}`,
                        mappingTitle: mapping.mappingTitle,
                        daySpecificAnzahl: mapping.anzahl
                    }));
                    return [...acc, ...dayRows];
                }
                return acc;
            }, []);
    
            return daySpecificRows.map((row) => {
                const field = fields.find(field => 
                    field.ziffer === row.ziffer && 
                    field.mappingTitle === row.mappingTitle
                );
                
                const faktor = getFactorFromMapping(row.ziffer, rows, defaultFaktor, factorMapping);
                const anzahl = getAnzahlFromMapping(
                    row.ziffer, 
                    factorMapping, 
                    defaultAnzahl, 
                    row.mappingTitle
                );
    
                return {
                    ...row,
                    faktor,
                    anzahl,
                    has_ziffer: Boolean(field)
                };
            });
        }
        return [];
    }, [rows, fields, factorMapping]);

    useEffect(() => {
        if (rows.length > 0) {
            setRowsResult(memoizedRowsResult);
        }
    }, [memoizedRowsResult]);

    useEffect(() => {
        // Initialize selectedOptions only once when the component mounts
        if (memoizedRowsResult.length > 0) {
            setSelectedOptions(memoizedRowsResult.map(row => row.id));
        }
    }, []);

    /*console.log("rowsResult");
    console.log(rowsResult)*/

    const getFilteredRows = useCallback(() => {
        return rowsResult.filter(row => selectedOptions.includes(row.id));
    }, [rowsResult, selectedOptions]);
    
    //For H and H1 Calculation
    useEffect(() => {
        const filteredRows = getFilteredRows();
        const newTotalH1 = calculateTotalH1(filteredRows);
        setSumOfZifferWithH1(newTotalH1);
    }, [selectedOptions, rowsResult, calculateTotalH1, getFilteredRows]);
    //End of H and H1 Calculation

    const handleOptionChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedOptions([...selectedOptions, value]);
        } else {
            setSelectedOptions(selectedOptions.filter(option => option !== value));
        }
    };

    /*console.log("selectedOptions");
    console.log(selectedOptions);*/

    const calculateTotalAmount = (row, rowsResult) => {
    
        /*console.log("selectedOptions");
        console.log(selectedOptions);

        console.log("row");
        console.log(row);

        console.log("rowsResult");
        console.log(rowsResult);*/

        if(enableRightTable_H_and_H1_Calculation){
            if (row?.ziffer?.includes('H1') ?? false) {
                
                //For H and H1 Calculation
                const calculatedAmount = calculateFieldWithFactor(rowsResult, row);
                const shouldBeZero = sumOfZifferWithH1 > betragOf3541H;
                /*console.log('H1 calculation:', { calculatedAmount, shouldBeZero });
                console.log('H1 shouldBeZero: >', { sumOfZifferWithH1, betragOf3541H, shouldBeZero });*/
                return shouldBeZero ? 0 : calculatedAmount;
                //End of H and H1 Calculation

            } else if (row.ziffer === '3541H') {
                
                //For H and H1 Calculation
                const calculatedAmount1 = calculateFieldWithFactor(rowsResult, row);
                const shouldBeZero1 = sumOfZifferWithH1 < betragOf3541H;
                /*console.log('3541H calculation:', { shouldBeZero1 });
                console.log('3541H shouldBeZero: <', { sumOfZifferWithH1, betragOf3541H, shouldBeZero1 });*/
                return shouldBeZero1 ? 0 : calculatedAmount1;
                //End of H and H1 Calculation

            } else{
                return calculateFieldWithFactor(rowsResult, row);
            }
        }else{
                return calculateFieldWithFactor(rowsResult, row);
        }
    };

    useEffect(() => {
            const calculatedTotal = rowsResult
                .filter(row => selectedOptions.includes(row.id))
                .reduce((acc, row) => acc + calculateTotalAmount(row, rowsResult), 0);
            
            setTotalBetrag(calculatedTotal);
            setSumTable2(calculatedTotal);
        }, [rowsResult, selectedOptions, calculateTotalAmount]);

    useEffect(() => {
        if (rowsResult.length > 0 && !isLoadingUserService) {
            setIsContentReady(true);
        }
    }, [rowsResult, isLoadingUserService]);

    const handleViewFormDialogFeatures = () => {
        setDialogInformation({ title: "", information: "", status: "view" });
        setOpenDialogFeatures(true);
    }

    const handleClose = () => {
        setOpenDialogFeatures(false);
    };

    return (
        <>
            {isLoadingUserService || !isContentReady ? (
                <>
                    <LoadingSkeletonLayout firstTable={false} />
                </>
            ) : (
                <>
                    <Box sx={{position: 'relative'}}>
                        <MembershipStatusDanke userStatus={userStatus} />
                    </Box>

                    <TableSeparateStyle>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="simple table">
                                <TableHead>
                                    {
                                        (userStatus !== 3) ?
                                            <TableRow>
                                                <TableCell className="TableCell" colSpan={2} ><Box sx={{padding:'9px 0'}}>{title1}</Box></TableCell>
                                                <TableCell className="TableCell">Text/Legende</TableCell>
                                                <TableCell className="TableCell">{title2}</TableCell>
                                                <TableCell className="TableCell">Faktor</TableCell>
                                                <TableCell className="TableCell">Betrag</TableCell>
                                                <TableCell className="TableCell">Aktion</TableCell>
                                            </TableRow>
                                        : ""
                                    }
                                </TableHead>
                                <TableBody>
                                    <MembershipStatus 
                                        userStatus={userStatus}
                                        onClick={()=>{ handleViewFormDialogFeatures() }}
                                    />
                                    {factorMapping.map((mapping, mappingIndex) => (
                                        <React.Fragment key={`mapping-${mappingIndex}`}>
                                             {
                                                (userStatus !== 3) ?
                                                    <TableRow>
                                                        <TableCell 
                                                            className="TableCell" 
                                                            colSpan={7}
                                                            sx={{ 
                                                                backgroundColor: colorName.gray,
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            <ContentComponent 
                                                                userStatus={userStatus} 
                                                                content={mapping.title || ''}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                : ""
                                             }
                                            {rowsResult
                                                .filter(row => 
                                                    mapping.list.some(item => 
                                                        item.ziffer === row.ziffer && 
                                                        mapping.title === row.mappingTitle
                                                    )
                                                )
                                                .map((row, index) => {
                                                    const tableRows = [];
                                                    tableRows.push(
                                                        <TableRow key={`output-${row.id}`}  sx={{ backgroundColor: (row.has_ziffer) ? colorName.transparent : colorName.lightgreen2,
                                                                                    '& td .MuiCheckbox-root' : {color: colorName.gray2}
                                                        }}>
                                                            <TableCell width={"10%"} className="TableCell">
                                                                <ContentComponent 
                                                                    userStatus={userStatus} 
                                                                    content={<IconButton variant="contained" onClick={() => handleViewForm(row.ziffer, row.description)}><InfoIcon /></IconButton>}
                                                                />
                                                            </TableCell>
                                                            <TableCell width={"30%"} className="TableCell">                                    
                                                                <ContentComponent 
                                                                    userStatus={userStatus} 
                                                                    content={row.ziffer}
                                                                />
                                                            </TableCell>
                                                            <TableCell width={"30%"} className="TableCell">
                                                                <ContentComponent 
                                                                    userStatus={userStatus} 
                                                                    content={row.text_legende}
                                                                />
                                                            </TableCell>
                                                            <TableCell width={"30%"} className="TableCell">
                                                                <ContentComponent 
                                                                    userStatus={userStatus} 
                                                                    content={row.daySpecificAnzahl || row.anzahl}
                                                                />
                                                            </TableCell>
                                                            <TableCell width={"20%"} className="TableCell">
                                                                <ContentComponent 
                                                                    userStatus={userStatus} 
                                                                    content={ row.faktor }
                                                                />
                                                            </TableCell>
                                                            <TableCell width={"20%"} className="TableCell" sx={{textWrap: 'nowrap'}}>
                                                                { /*germanFormatCurrency(calculateFieldWithFactor(rowsResult, row))*/ }
                                                                { /*germanFormatCurrency(calculateTotalAmount(row, rowsResult))*/ } {/*Temporary*/} 
                                                                <ContentComponent 
                                                                    userStatus={userStatus} 
                                                                    content={ germanFormatCurrency(calculateTotalAmount(row, rowsResult)) }
                                                                /> {/*Temporary*/}
                                                            </TableCell>
                                                            <TableCell width={"10%"} className="TableCell">
                                                                <ContentComponent 
                                                                    userStatus={userStatus} 
                                                                    content={ <FormControlLabel
                                                                                    key={row.id}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={selectedOptions.includes(row.id)}
                                                                                            onChange={handleOptionChange}
                                                                                            value={row.id}
                                                                                            sx={{ padding: '8px'}}
                                                                                        />
                                                                                    }
                                                                                    label=""
                                                                                    sx={{ ml: 0, mr: 0 }}
                                                                                /> 
                                                                    }
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                    // Add totals only after the last mapping's last row
                                                    if (mappingIndex === factorMapping.length - 1 && 
                                                        index === rowsResult.filter(r => 
                                                            mapping.list.some(item => 
                                                                item.ziffer === r.ziffer && 
                                                                mapping.title === r.mappingTitle
                                                            )
                                                        ).length - 1
                                                    ) {
                                                            tableRows.push(
                                                                <React.Fragment key={`totals-${row.id}`}>
                                                                    <TableRow key={`total-sum-${row.id}`}>
                                                                        <TableCell className="TableCell" colSpan={4} sx={{borderRight:"0!important"}}><Box sx={{p:'17px'}}></Box></TableCell>
                                                                        <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}></TableCell>
                                                                        <TableCell className="TableCell total" sx={{textWrap: 'nowrap'}}>
                                                                            <ContentComponent 
                                                                                userStatus={userStatus} 
                                                                                content={germanFormatCurrency(totalBetrag)}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className="TableCell" sx={{borderLeft:"0!important"}}></TableCell>
                                                                    </TableRow>
                                                                    {debug && (
                                                                                <TableRow key={`total-debug-${row.id}`}>
                                                                                    <TableCell className="TableCell" colSpan={4} sx={{borderRight:"0!important"}}><Box sx={{p:'17px'}}></Box></TableCell>
                                                                                    <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}>SUM of H1 Treatments</TableCell>
                                                                                    <TableCell className="TableCell total" sx={{textWrap: 'nowrap'}}>
                                                                                        <ContentComponent 
                                                                                            userStatus={userStatus} 
                                                                                            content={ germanFormatCurrency(sumOfZifferWithH1) }
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell className="TableCell" sx={{borderLeft:"0!important"}}></TableCell>
                                                                                </TableRow>
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        }
                                                        return (userStatus !== 3) ? tableRows : "";          
                                                })
                                            }
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableSeparateStyle>
                </>
            )}
        <DialogOutput
            fullScreen={false} // Set the value according to your requirement
            open={openDialogFeatures}
            dialogData={dialogData}
            handleClose={handleClose}
            maxWidth='lg'
            output={<ViewFormOutputMembership />}
        />

        <br /><br />
        </>
    );
};

export default GOAE_Factor_Table_Result;
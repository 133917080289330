import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

const GopSearch = ({ rows, searchGop, setSearchGop }) => {
    return (
        <Autocomplete
            multiple
            id="tags-outlined"
            options={rows.map(row => row.gop)}
            value={searchGop}
            onChange={(event, newValue) => {
                setSearchGop(newValue);
            }}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Suche Ziffer"
                    sx={{ width: '100%', minWidth: '200px' }}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const { key, ...chipProps } = getTagProps({ index });
                    return (
                        <Chip
                            key={key}
                            variant="outlined"
                            label={option}
                            {...chipProps}
                        />
                    );
                })
            }
        />
    );
};

export default GopSearch;
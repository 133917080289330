import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Controls from '../../../components/controls/Controls';
import ContactInfoForm, { validateContactInfoForm } from '../../../components/ContactInfoForm';
import * as DatabaseService from '../../../services/DatabaseService';
import setNestedField from '../../../utils/setNestedField';

const UpdateUserForm = ({ 
    user, 
    listAllUserRoles, 
    updateUserData_func, 
    onSuccess,
    setOpen 
}) => {

    const [userData, setUserData] = useState({
        user_id: user.id || '',
        username: user.username || '',
        currentusername: user.username || '',
        email: user.email || '',
        currentemail: user.email || '',
        fname: user.fname || '',
        lname: user.lname || '',
        roleId: user.roleId || '',
        password: '',
        profile: {
            salutation: user.profile?.salutation || '',
            title: user.profile?.title || '',
            phone: user.profile?.phone || '',
            currentStatus: user.profile?.currentStatus || '',
            establishmentDate: user.profile?.establishmentDate || null,
            practiceType: user.profile?.practiceType || '',
            insuranceSeats: user.profile?.insuranceSeats || '',
            otherStatus: user.profile?.otherStatus || '',
            practiceManagementSystem: user.profile?.practiceManagementSystem || '',
            address: user.profile?.address || '',
            postalCode: user.profile?.postalCode || '',
            city: user.profile?.city || '',
            website: user.profile?.website || '',
            comments: user.profile?.comments || '',
            isFullMembership: user.profile?.isFullMembership || false,
            bsnr: user.profile?.bsnr || '',
            lanr: user.profile?.lanr || '',
            iban: user.profile?.iban || '',
            accountHolder: user.profile?.accountHolder || '',
            billingDetails: user.profile?.billingDetails || '',
            sendInvoiceEmail: user.profile?.sendInvoiceEmail || !!user.profile?.billingEmail || false,
            billingEmail: user.profile?.billingEmail || '',
            upgradeFeatures: user.profile?.upgradeFeatures || false,
            upgradeFeaturesGoae: user.profile?.upgradeFeaturesGoae || false,
            upgradeFeaturesGoaeKasuistiken: user.profile?.upgradeFeaturesGoaeKasuistiken || false
        }
    });
    
    const [errorUpdate, setErrorUpdate] = useState({});
    const [enableValidationUpdate, setEnableValidationUpdate] = useState(false);
    const [alertUpdate, setAlertUpdate] = useState({ text: '', appear: false, severity: '' });
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
    const [emailExistOnUpdate, setEmailExistOnUpdate] = useState(0);
    const [usernameExistOnUpdate, setUsernameExistOnUpdate] = useState(0);

    const [showPasswordUpdate, setShowPasswordUpdate] = React.useState(false);
    const handleClickShowPasswordUpdate = () => setShowPasswordUpdate((show) => !show);

    const [contactAccordionExpanded, setContactAccordionExpanded] = useState(false);
    
    const checkEmailOnUpdate = async (data) => {
        return await DatabaseService.checkEmail(data);
    }

    const checkUsernameOnUpdate = async (data) => {
        return await DatabaseService.checkUsername(data);
    }

    const changeUpdateData = (e) => {
        const { name, value } = e.target;

        if (name === 'currentusername' || name === 'currentemail') {
            // Do not change currentusername and currentemail
            return;
        }

        setUserData(setNestedField(userData, name, value));
    };
    
    async function onUpdateUser() {
        
        setEnableValidationUpdate(true);

        const hasValidationErrors = validationUpdate(userData, true);

        if (!hasValidationErrors) {
            setUpdateButtonDisabled(true);

            updateUserData_func(userData.user_id, userData)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Failed to update user');
                    }
                })
                .then(result => {
                    
                    setAlertUpdate({
                        text: result.message,
                        appear: true,
                        severity: 'success'
                    });

                    setTimeout(() => {
                        setOpen(false);

                        // Update table data after successful update
                        if (typeof onSuccess === 'function') {
                            onSuccess();
                        }
                    }, 2000);
                })
                .catch(error => {
                    console.error('Error updating user:', error);
                    setOpen(false);
                    // Handle error here, show message or take appropriate action
                });
        }else{

            setContactAccordionExpanded(true);
            
            setAlertUpdate({
                text: "Bitte korrigieren Sie die Fehler im Formular",
                appear: true,
                severity: 'error'
            });
        }
    }
    
    function onUpdateValidation() {
        checkEmailOnUpdate(userData.email).then(r => r.json()).then(result => {
            setEmailExistOnUpdate(result.rows);
        });

        checkUsernameOnUpdate(userData.username).then(r => r.json()).then(result => {
            setUsernameExistOnUpdate(result.rows);
        });

        validationUpdate(userData, enableValidationUpdate);
    }

    function onUpdateValidationFocusOut() {
        validationUpdate(userData, enableValidationUpdate);
    }

    function validationUpdate(userData, validate) {
        let errorUpdate = {};
        
        if (validate) {
            if (!(/$^|.+@.+..+/).test(userData.email)) {
                errorUpdate.email = "Email is not valid.";
            } else {
                if (userData.currentemail === userData.email) {
                    errorUpdate.email = "";
                    setEmailExistOnUpdate(0);
                } else {
                    // Email is provided and valid
                    if (emailExistOnUpdate) {
                        errorUpdate.email = "Email already exists";
                    } else {
                        errorUpdate.email = "";
                    }
                }
            }
            
            if (userData.username === "") {
                errorUpdate.username = "Username is required";
            } else {
                if (userData.currentusername === userData.username) {
                    errorUpdate.username = "";
                    setUsernameExistOnUpdate(0);
                } else {
                    if (usernameExistOnUpdate) {
                        errorUpdate.username = "Username already exists";
                    } else {
                        errorUpdate.username = "";
                    }
                }
            }

            errorUpdate.password = (userData.password !== "")
                ? (userData.password.length < 8) ? "Password length must be 8 characters" : ""
                : "";

            errorUpdate.roleId = (userData.roleId === "") ? "Role is required" : "";
        }

        errorUpdate = {...errorUpdate, ...validateContactInfoForm(userData)};
        setErrorUpdate(errorUpdate);

        return Object.keys(errorUpdate).some(k => !!errorUpdate[k]);
    }

    const handleAccordionChange = (event, isExpanded) => {
        setContactAccordionExpanded(isExpanded);
    };

    return (
        <>
                <TextField
                    hidden
                    disabled
                    margin="dense"
                    id="id"
                    label="ID"
                    name="id"
                    fullWidth
                    type="text"
                    variant="standard"
                    value={userData.user_id}
                    style={{ display: 'none' }} // Apply inline style to hide the TextField
                />

                <TextField
                    hidden
                    disabled
                    margin="dense"
                    id="currentusername"
                    label="currentusername"
                    name="currentusername"
                    fullWidth
                    type="text"
                    variant="standard"
                    value={userData.currentusername}
                    style={{ display: 'none' }}
                />

                <TextField
                    hidden
                    disabled
                    margin="dense"
                    id="currentemail"
                    label="currentemail"
                    name="currentemail"
                    fullWidth
                    type="text"
                    variant="standard"
                    value={userData.currentemail}
                    style={{ display: 'none' }}
                />

                <TextField
                    required
                    margin="dense"
                    id="username"
                    label="Benutzername"
                    name="username"
                    fullWidth
                    type="text"
                    variant="standard"
                    value={userData.username}
                    error={(enableValidationUpdate) ? (errorUpdate.username !== "") ? true : false : false}
                    helperText={errorUpdate.username}
                    onChange={changeUpdateData}
                    onKeyUp={onUpdateValidation}
                    onBlur={onUpdateValidationFocusOut}
                />

                <TextField
                    margin="dense"
                    id="password"
                    label="Password (leave blank to keep current)"
                    name="password"
                    fullWidth
                    autoComplete="off"
                    variant="standard"
                    error={(enableValidationUpdate) ? (errorUpdate.password !== "") ? true : false : false}
                    helperText={errorUpdate.password}
                    onChange={changeUpdateData}
                    onKeyUp={onUpdateValidation}
                    onBlur={onUpdateValidationFocusOut}
                    type={showPasswordUpdate ? 'text' : 'password'}
                    InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPasswordUpdate}
                            >
                                {showPasswordUpdate ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    sx={{display:'none'}}
                />
                <TextField
                    id="roleId"
                    select
                    name="roleId"
                    margin="dense"
                    label="Role"
                    defaultValue=""
                    variant="standard"
                    fullWidth
                    value={userData.roleId}
                    error={(enableValidationUpdate) ? (errorUpdate.roleId !== "") ? true : false : false}
                    helperText={errorUpdate.role}
                    onChange={changeUpdateData}
                    onKeyUp={onUpdateValidation}
                    onBlur={onUpdateValidationFocusOut}
                >
                    {listAllUserRoles.map(
                        (option) => (
                            <MenuItem key={option.id} value={option.id} >
                                {option.role_name}
                            </MenuItem>
                        )
                    )}
                </TextField>

                <Accordion 
                    expanded={contactAccordionExpanded}
                    onChange={handleAccordionChange}
                    sx={{ marginTop: '10px', boxShadow: 'none', '::before': { height: 0 } }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="contact-info-content"
                        id="contact-info-header"
                        sx={{ padding: 0 }}
                    >
                        Kontaktinformationen anzeigen
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        <ContactInfoForm 
                            signup={false} 
                            formError={errorUpdate} 
                            formData={userData || {}} 
                            handleChange={changeUpdateData} 
                        />
                    </AccordionDetails>
                </Accordion>

                <Controls.Checkbox
                    name="profile.upgradeFeatures"
                    label="Vollständiger Funktionsumfang EBM"
                    value={userData?.profile?.upgradeFeatures}
                    onChange={changeUpdateData}
                />

                <Controls.Checkbox
                    name="profile.upgradeFeaturesGoae"
                    label="Vollständiger Funktionsumfang GOÄ"
                    value={userData?.profile?.upgradeFeaturesGoae}
                    onChange={changeUpdateData}
                />

                <Controls.Checkbox
                    name="profile.upgradeFeaturesGoaeKasuistiken"
                    label="Vollständiger Funktionsumfang GOÄ Kasuistiken"
                    value={userData?.profile?.upgradeFeaturesGoaeKasuistiken}
                    onChange={changeUpdateData}
                />

                <Box sx={{ mt: 2, mb: 2 }}>
                    <Button 
                        autoFocus 
                        variant="contained" 
                        color="primary" 
                        onClick={onUpdateUser} 
                        disabled={updateButtonDisabled}
                    >
                        Update
                    </Button>
                </Box>

                <Controls.Alert 
                    severity={alertUpdate.severity} 
                    appear={alertUpdate.appear} 
                    text={alertUpdate.text} 
                    sx={{ marginTop: "10px" }} 
                />
        </>
    );
};

export default UpdateUserForm;
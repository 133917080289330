import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import hexToRgb from '../../../../utils/hexToRgb';
import GynsuccessLogo from '../../../../components/assets/images/gynsuccess-logo.jpg';

/**
 * Captures a DOM element as an image using html2canvas
 * @param {string} elementId - ID of the DOM element to capture
 * @param {Object} options - Additional options for html2canvas
 * @returns {Promise<string>} - Promise resolving to image data URL
 */
export const captureElementAsImage = (elementId, options = {}) => {
    const chartElement = document.getElementById(elementId);
    if (!chartElement) {
        console.error(`Element with ID "${elementId}" not found`);
        return Promise.resolve(null);
    }
    
    return html2canvas(chartElement, {
        backgroundColor: null,
        scale: 2, // Higher scale for better quality
        useCORS: true,
        logging: false, // Disable logging for production
        ...options
    }).then(canvas => {
        return canvas.toDataURL('image/png');
    }).catch(error => {
        console.error('Error capturing element:', error);
        return null;
    });
};

/**
 * Generates a PDF report for GOÄ Abrechnungskompass
 * @param {Object} data - The data needed to generate the PDF
 * @param {Object} data.tableData - The table data structure
 * @param {string} data.chartImgData - Image data URL for the chart
 * @param {string} data.displayInfoOutput - Display information text
 * @param {Object} data.colorName - Object containing color definitions
 * @param {Function} onProgress - Optional callback for progress updates
 * @param {Function} onComplete - Optional callback when PDF generation is complete
 * @returns {Promise<Blob>} - Promise resolving to PDF blob
 */
export const generateGOAEPdf = async ({
    tableData,
    chartImgData,
    displayInfoOutput,
    colorName,
    setLoadingCapture
}, onProgress, onComplete) => {
    if (!tableData?.tables?.[0]) {
        throw new Error('No table data available');
    }
    
    try {
        // Initialize PDF with correct format
        const pdf = new jsPDF('p', 'mm', 'a4');

        // Define consistent margins to be used throughout the document
        const marginLeft = 10;
        const marginRight = 10;
        const position = 15;
        const today = new Date();
        const date = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
        
        // Title and Logo - GOÄ Abrechnungskompass
        const logoWidth = 56; // Width of the logo in mm
        const logoHeight = 10; // Height of the logo in mm

        // Add white background rectangle for the logo area
        pdf.setFillColor(255, 255, 255);
        pdf.rect(marginLeft + 4, position - 5, logoWidth, logoHeight, 'F');

        // Add the logo image
        pdf.addImage(GynsuccessLogo, 'PNG', marginLeft, position - 5, logoWidth, logoHeight);

        // Set title after the logo
        pdf.setFontSize(16);
        const pageWidth = pdf.internal.pageSize.getWidth();
        pdf.text('GOÄ Abrechnungskompass', pageWidth - marginRight, position + 2.5, { align: 'right' });

        // Display info - third line with more spacing
        pdf.setFontSize(10);
        // Add text splitting to handle long lines properly
        const textWidth = pageWidth - marginLeft - marginRight;
        const infoLines = pdf.splitTextToSize(displayInfoOutput, textWidth);
        pdf.text(infoLines, marginLeft, position + 16);

        // Calculate starting position for tables based on number of lines in info text
        const infoLinesCount = infoLines.length;
        let yPosition = position + 20 + (infoLinesCount * 4);
        
        // Add chart image if available
        if (chartImgData) {
            // Calculate dimensions to fit chart on page
            const imgWidth = pageWidth - marginLeft - marginRight;
            const imgHeight = 53; // Fixed height or calculate based on aspect ratio
            
            // Add chart to PDF
            pdf.addImage(chartImgData, 'PNG', marginLeft, yPosition, imgWidth, imgHeight);
            
            // Update position for tables
            yPosition += imgHeight + 5; // 15mm spacing after chart
        }

        // Process each table in the tableData array
        tableData.tables.forEach((table, tableIndex) => {
            // Add table title if available
            if (table.title) {
                pdf.setFontSize(10);
                pdf.text(table.title, marginLeft, yPosition);
                yPosition += 8;
            }
            
            // Format table data
            let processedData = [];
            
            // Process rows for this table
            if (Array.isArray(table.rows)) {
                table.rows.forEach(row => {
                    // Create a properly structured row array for the PDF table
                    processedData.push([
                        row.ziffer || '',
                        row.title || '',
                        row.anzahl?.toString() || '',
                        row.ideal_number_of_treatments?.toString() || '',
                        row.deviation?.toString() || '',
                        row.deviation_in_percent?.toString() || '',
                        row.gesamtpotenzial?.toString() || '',     // Changed from outputDeviationInPercentStatus
                        row.ihr_potenzial?.toString() || '',       // Changed from backgroundColorRow
                        row.backgroundColorRow || '',              // Now kept as 8th column for styling
                        row.colorTextRow || ''                     // Now kept as 9th column for styling
                    ]);
                });
            }
 
            // Generate this table using autoTable
            autoTable(pdf, {
                startY: yPosition,
                head: table.columns ? [table.columns] : undefined,
                body: processedData,
                theme: 'grid',
                showHead: 'firstPage',
                margin: { left: marginLeft, right: marginRight },   // Added for better page fitting
                tableWidth: 'auto',                // Let table calculate its optimal width
                styles: {
                    fontSize: 8,
                    cellPadding: 2,
                    lineWidth: 0.1,
                    lineColor: [80, 80, 80],
                    overflow: 'linebreak'
                },
                headStyles: {
                    fillColor: hexToRgb(colorName.green),
                    textColor: [255, 255, 255],
                    fontStyle: 'bold',
                    fontSize: 7
                },
                columnStyles: {
                    0: { cellWidth: 18 },
                    1: { cellWidth: 44 },
                    2: { cellWidth: 15, halign: 'right' },
                    3: { cellWidth: 14, halign: 'right' },
                    4: { cellWidth: 22, halign: 'right' },
                    5: { cellWidth: 24, halign: 'right' },
                    6: { cellWidth: 29, halign: 'right' },
                    7: { cellWidth: 24, halign: 'right' }
                },
                didParseCell: function(data) {
                    // Format cells
                    if (data.column.index === 5) {
                        if (data.cell.text && data.cell.text.length > 0) {
                            data.cell.text = data.cell.text.map(text => text ? text : '');
                        }
                    }
                    if (data.column.index === 6 || data.column.index === 7) {
                        if (data.cell.text && data.cell.text.length > 0) {
                            data.cell.text = data.cell.text.map(text => text ? text : '');
                        }
                    }
                
                    // Apply background and text colors
                    if (data.section === 'body') {
                        const rowData = processedData[data.row.index];
                        const bgColorIndex = 8;
                        const textColorIndex = 9;
                        
                        // Replace the text color handling code block with this improved version
                        if (rowData && rowData[bgColorIndex]) {
                            try {
                                if (rowData[bgColorIndex].startsWith('#')) {
                                    data.cell.styles.fillColor = hexToRgb(rowData[bgColorIndex]);
                                    
                                    if (textColorIndex !== null && rowData[textColorIndex]) {
                                        if (rowData[textColorIndex].startsWith('#')) {
                                            data.cell.styles.textColor = hexToRgb(rowData[textColorIndex]);
                                        } else if (rowData[textColorIndex] === 'inherit') {
                                            data.cell.styles.textColor = [0, 0, 0];
                                        } else {
                                            data.cell.styles.textColor = [0, 0, 0];
                                        }
                                    } else {
                                        data.cell.styles.textColor = [0, 0, 0];
                                    }
                                }
                            } catch (error) {
                                console.error('Error applying background color:', error);
                                data.cell.styles.textColor = [0, 0, 0];
                            }
                        }
                    }
                
                    // Hide the backgroundColor and textColor columns
                    if (data.column.index === 8 || data.column.index === 9) {
                        data.cell.text = '';
                    }
                },
                didDrawPage: function(data) {
                    // Add page number at the bottom
                    pdf.setFontSize(8);
                    pdf.text(
                        `Seite ${data.pageNumber} von ${pdf.internal.pages.length - 1}`,
                        data.settings.margin.left,
                        pdf.internal.pageSize.height - 10
                    );
                }
            });
            
            // Get the final Y position after this table was created
            yPosition = pdf.lastAutoTable.finalY + 15;
            
            // Add page break between tables if needed
            if (tableIndex < tableData.tables.length - 1) {
                if (yPosition > pdf.internal.pageSize.height - 40) {
                    pdf.addPage();
                    yPosition = 20;
                }
            }
        });
        
        // Return the PDF as blob for flexible handling
        const pdfBlob = pdf.output('blob');
        const outputFilename = `GOÄ_Abrechnungskompass_${date}.pdf`;

        // Create the result object with methods
        const result = {
            blob: pdfBlob,
            filename: outputFilename,
            preview: () => {
                previewPdf(pdfBlob);
                // Set loading to false after preview is opened
                if (setLoadingCapture) setLoadingCapture(false);
            },
            download: () => {
                
                // Set loading to false after download is triggered
                if (setLoadingCapture) setLoadingCapture(false);
                
                setTimeout(() => {
                    savePdf(pdfBlob, outputFilename);
                }, 1000);
            }
        };

        if (onComplete) {
            onComplete(result.blob, result.filename);
        }
        
        return result;
        
    } catch (error) {
        console.error('Error generating PDF:', error);
        throw error;
    }
};

/**
 * Helper function to preview the generated PDF in a new browser tab
 * @param {Blob} pdfBlob - The PDF blob
 */
const previewPdf = (pdfBlob) => {
    const blobUrl = URL.createObjectURL(pdfBlob);
    window.open(blobUrl, '_blank');
    // Don't immediately revoke the URL as it's needed for the preview
    // The browser will clean it up when the tab is closed
};

/**
 * Helper function to save the generated PDF
 * @param {Blob} pdfBlob - The PDF blob
 * @param {string} filename - The filename to save as
 */
const savePdf = (pdfBlob, filename) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
};
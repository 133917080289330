import React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';

export default function TabsContainer({ 
    activeTab,
    onTabChange,
    tabs,
    hideTabList = false
}) {
    return (
        <TabContext value={activeTab}>
            <TabList 
                onChange={onTabChange} 
                aria-label="tabs" 
                sx={{ display: hideTabList ? 'none' : 'flex' }}
            >
                {tabs.map((tab) => (
                    <Tab 
                        key={tab.value} 
                        label={tab.label} 
                        value={tab.value}
                    />
                ))}
            </TabList>
            
            {tabs.map((tab) => (
                <TabPanel 
                    key={tab.value} 
                    value={tab.value} 
                    sx={{ padding: 0 }}
                >
                    {tab.content}
                </TabPanel>
            ))}
        </TabContext>
    );
}
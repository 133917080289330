export const MAMMASONOGRAPHIE_SELBEN_TAG_FACTOR_MAPPING = [
    {
        title: "Tag 1",
        list: [
            { ziffer: "1", anzahl: "1" },
            { ziffer: "7", anzahl: "1" },
            { ziffer: "1070", anzahl: "1" },
            { ziffer: "1075", anzahl: "1" },
            { ziffer: "298", anzahl: "3" },
            { ziffer: "3508", anzahl: "1" },
            { ziffer: "3509", anzahl: "1" },
            { ziffer: "3511", anzahl: "1" },
            { ziffer: "3532", anzahl: "1" },
            { ziffer: "3714", anzahl: "1" },
            { ziffer: "3736", anzahl: "1" },
            { ziffer: "4711", anzahl: "1" },
            { ziffer: "4715", anzahl: "1" },
            { ziffer: "4749", anzahl: "1" },
            { ziffer: "4525", anzahl: "1" },
            { ziffer: "75", anzahl: "1" },
            { ziffer: "4850", anzahl: "1" },
            { ziffer: "3911H3", anzahl: "1" },
            { ziffer: "403", anzahl: "1" },
            { ziffer: "418", anzahl: "1", pre_assigned_factor: "3,5" },
            { ziffer: "420", anzahl: "3", pre_assigned_factor: "3,5" },
            { ziffer: "401", anzahl: "1" },
            { ziffer: "404", anzahl: "1" },
            { ziffer: "3741", anzahl: "1" },
            { ziffer: "4138", anzahl: "1" },
            { ziffer: "4405", anzahl: "1" },
            { ziffer: "3747", anzahl: "1" },
            { ziffer: "3911H3", anzahl: "1" },
            { ziffer: "3550", anzahl: "1" },
            { ziffer: "3551", anzahl: "1" },
            { ziffer: "3561", anzahl: "1" },
            { ziffer: "3557", anzahl: "1" },
            { ziffer: "3558", anzahl: "1" },
            { ziffer: "3555", anzahl: "1" },
            { ziffer: "3562H1", anzahl: "1" },
            { ziffer: "3563H1", anzahl: "1" },
            { ziffer: "3564H1", anzahl: "1" },
            { ziffer: "3565H1", anzahl: "1" },
            { ziffer: "3560", anzahl: "1" },
            { ziffer: "3588H1", anzahl: "1" },
            { ziffer: "3598H1", anzahl: "1" },
            { ziffer: "3581H1", anzahl: "1" },
            { ziffer: "3594H1", anzahl: "1" },
            { ziffer: "3595H1", anzahl: "1" },
            { ziffer: "3592H1", anzahl: "1" },
            { ziffer: "3587H1", anzahl: "1" },
            { ziffer: "3597H1", anzahl: "1" },
            { ziffer: "3583H1", anzahl: "1" },
            { ziffer: "3585H1", anzahl: "1" },
            { ziffer: "3574", anzahl: "1" },
            { ziffer: "3573H1", anzahl: "1" },
            { ziffer: "3575", anzahl: "1" },
            { ziffer: "3560", anzahl: "1" },
            { ziffer: "250", anzahl: "1" },
            { ziffer: "3541H", anzahl: "1" },
        ],
    },
    {
        title: "Tag 2",
        list: [
            { ziffer: "3", anzahl: "1" }
        ],
    }
];
import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Typography, Tooltip, Button, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StorageIcon from '@mui/icons-material/Storage';
import AddIcon from '@mui/icons-material/Add';
import SearchInput from '../../../components/SearchInput';

const EnhancedTableToolbar = (props) => {
    const { numSelected, onDelete, setSearch, setPage, search, tableTitle, roleFilter, handleRoleFilterChange } = props;
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                paddingRight: '16px!important',
                display: { xs: 'block', sm: 'flex' },
                paddingTop: { xs: '8px', sm: '0' },
                paddingBottom: { xs: '14px', sm: '0' },
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    { tableTitle } {/*<Button variant="outlined" onClick={handleAddUser} ><AddIcon /></Button>*/}
                </Typography>
            )}
            {numSelected > 0 ? (
                <>
                    <Tooltip title="Delete">
                        <Button color="error" variant="contained" onClick={onDelete}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </>
            ) : (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl 
                            variant="outlined" 
                            size="small"
                            sx={{ 
                                marginRight: '10px'
                            }}
                        >
                            <InputLabel id="role-select-label">Role</InputLabel>
                            <Select
                                labelId="role-select-label"
                                id="role-select"
                                name="role_id"
                                label="Role"
                                value={props.roleFilter || 'Alle'}
                                onChange={props.handleRoleFilterChange}
                                sx={{  
                                        '& .MuiSelect-select': {
                                            padding: '6.5px 14px'
                                        }, 
                                }}
                            >
                                <MenuItem value="Alle">Alle</MenuItem>
                                {props.listAllUserRoles.map((role) => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.role_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <SearchInput setSearch={setSearch} setPage={setPage} search={search} />
                    </Box>
                </>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    setSearch: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    listAllUserRoles: PropTypes.func.isRequired,
    roleFilter: PropTypes.string,
    handleRoleFilterChange: PropTypes.func,
};

export default EnhancedTableToolbar;
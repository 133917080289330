import React, { useEffect } from 'react';
import MuiDrawer from '../../components/MuiDrawer';
import GOÄ_Abrechnungskompass_Calculation from './GOÄ_Abrechnungskompass_Calculation';

export default function GOÄ_Abrechnungskompass_Calculation_Main() {

    useEffect(() => {
            window.scrollTo(0, 0)
    }, [])

  return (
            <MuiDrawer>
                <GOÄ_Abrechnungskompass_Calculation />
            </MuiDrawer>
          );
}
import React, { useState, useEffect, useMemo } from 'react';
import * as DatabaseService from '../../../services/DatabaseService';
import jsPDF from 'jspdf';
import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { checkDeviationPercentColor } from '../calculations/ebmDeviationConditions';
import countGesamtpotenzialNoTrap from '../calculations/countGesamtpotenzialNoTrap';
import countIhrPotencialNoTrap from '../calculations/countIhrPotencialNoTrap';
import countIhrPotencialIncluded from '../calculations/countIhrPotencialIncluded';
import countGesamtpotenzialIncluded from '../calculations/countGesamtpotenzialIncluded';
import TableSection from './components/Table_Section';
import Charts_Section from './components/Charts_Section';
import { LoadingSkeletonLayout } from '../components/Loading/LoadingSkeletonLayout';
import { useEnvData } from '../../../services/envService';
import DisplayInfoStyle from '../styles/DisplayInfoStyle';
import { colorName } from '../../../components/colors/Colors';
import { captureElementAsImage, generateEBMPdf  } from './utils/pdfGeneratorUtils';
import displayInfo from './utils/displayInfo';

const EBM_Abrechnungskompass_Results = (props) => {

    const { 
            scheine=0, 
            kv=0, 
            kvName='', 
            gopNumbersInput={}, 
            viewOnlyMode, 
            userStatus, 
            debug=false, 
            userInfo={}, 
            abrechnungBillingOption, 
            offeneSprechstunde, 
            created_at, 
            abrechnungskompassCSV_ID, 
            userRoleID 
    } = props;

    const { isDevMode } = useEnvData();
    const [rowsFromDB, setRowsFromDB] = useState([]);
    const [rowsResult, setRowsResult] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [loadingCapture, setLoadingCapture] = useState(false); // Add loading state
    const [tableMaxHeight, setTableMaxHeight] = useState(500);
    const [tableData, setTableData] = useState(null);

    const handleTableData = (data) => {
        setTableData(data);
    };

    const getEBMRowsData = async (scheine, kv) => {
        try {
          const JSONData = await DatabaseService.getEBMAbrechnungskompassSearchByStates(scheine, kv);
          if (JSONData && JSONData.rows) {
            setRowsFromDB(JSONData.rows);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } 
    };

    useEffect(() => {
        getEBMRowsData(scheine, kv); // Fetch initial data
    }, [kv]);

    let displayInfoOutput = displayInfo(userInfo, scheine, kvName, created_at);

    const getAbrechnungskompassInputOutputCSVData = async (abrechnungskompassCSV_ID) => {

        try {
            const JSONData = await DatabaseService.getAbrechnungskompassInputOutputCSVData(1, 1, "", abrechnungskompassCSV_ID);
            if (JSONData && JSONData.rows) {
                return JSONData.rows[0]?.note || '';
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleExportToPDF = async () => {

        setLoadingCapture(true);
        
        try {
            
            const currentNote = (abrechnungskompassCSV_ID !== undefined && abrechnungskompassCSV_ID !== null) 
                                    ? await getAbrechnungskompassInputOutputCSVData(abrechnungskompassCSV_ID) : '';

            // First capture the chart as an image
            const chartImgData = await captureElementAsImage('capture');
            
            // Generate the PDF
            const result = await generateEBMPdf({ 
                tableData, 
                chartImgData, 
                displayInfoOutput, 
                colorName,
                currentNote,
                setLoadingCapture 
            });

            // Save the PDF
            result.download();

            // Optional: Preview the PDF in a new tab
            //result.preview();
            
        } catch (error) {
            console.error('Error generating PDF:', error);
            // Create a basic PDF with error message as fallback
            try {
                const pdf = new jsPDF();
                pdf.text('Error generating PDF: ' + error.message, 20, 20);
                pdf.save('EBM_Abrechnungskompass_error.pdf');
            } catch (e) {
                console.error('Critical error creating fallback PDF:', e);
            }
        }
    };

    useEffect(() => {
        if (rowsResult.length > 0) {
            setLoading(false); // Set loading to false when data is fetched
        }
    }, [rowsResult]);

    const memoizedRowsResult = useMemo(() => {
        if (rowsFromDB.length > 0) {
            let anzahl_input_01770 = 0;
            return rowsFromDB.map(row => {
                let euro_potencial = 0;
                let euro_potencial_mutterschaftsvorsorge_ziffern = 0;
                let ideal_number_of_treatments = 0;
                let anzahl = gopNumbersInput[`fallzahl_${row.gop}`] || 0; 

                if(row.gop === '01770' && parseInt(anzahl) > 0){
                    anzahl_input_01770 = parseInt(anzahl);
                }

                if(anzahl_input_01770 > 0){ //Mutterschaftsvorsorge-Ziffern formula
                    ideal_number_of_treatments = (parseInt(row.exclude_scheine) === 1) 
                        ? (anzahl_input_01770 * row.ebm_koeffizient) 
                        : row.ideal_number_of_treatments;
                    euro_potencial_mutterschaftsvorsorge_ziffern = ideal_number_of_treatments * row.euro_value_of_a_treatment;
                } else {
                    ideal_number_of_treatments = (parseInt(row.exclude_scheine) === 1) 
                        ? (anzahl * row.ebm_koeffizient) 
                        : row.ideal_number_of_treatments;
                }

                let deviation = anzahl - ideal_number_of_treatments;
                let deviation_in_percent = (deviation / ideal_number_of_treatments) * 100;
                euro_potencial = Math.abs(parseFloat(deviation)) * parseFloat(row.euro_value_of_a_treatment);
                let euro_potencial_show = 1;

                if(parseInt(anzahl) <= parseInt(ideal_number_of_treatments)){
                    euro_potencial_show = 1;
                } else {
                    euro_potencial_show = 0;
                }

                let deviation_in_percent_status = checkDeviationPercentColor(deviation_in_percent, row.gop);

                row.anzahl = anzahl;
                row.deviation = deviation;
                row.deviation_in_percent = deviation_in_percent;
                row.euro_potencial_show = euro_potencial_show;
                row.adjusted_euro_potencial = euro_potencial;
                row.ideal_number_of_treatments = ideal_number_of_treatments;
                row.euro_potencial_mutterschaftsvorsorge_ziffern = euro_potencial_mutterschaftsvorsorge_ziffern;
                row.deviation_in_percent_status = deviation_in_percent_status;
                return { ...row };
            });
        }
        return [];
    }, [rowsFromDB, scheine, gopNumbersInput]);

    useEffect(() => {
        if (rowsFromDB.length > 0) {
            setRowsResult(memoizedRowsResult);
        }
    }, [memoizedRowsResult]);

    const gesamtpotenzial_notrap = countGesamtpotenzialNoTrap(rowsResult, false);
    const ihrpotenzial_notrap = countIhrPotencialNoTrap(rowsResult, false);
    const ihrPotencialIncluded = countIhrPotencialIncluded(rowsResult, false, gopNumbersInput);
    const gesamtpotenzial_included = countGesamtpotenzialIncluded(rowsResult, false, gopNumbersInput);
    const ihrPotencialIncluded_excludedGOP = countIhrPotencialIncluded(rowsResult, false, gopNumbersInput, true);
    const gesamtpotenzial_included_excludedGOP = countGesamtpotenzialIncluded(rowsResult, false, gopNumbersInput, true);

    /*console.log('rowsResult: ', rowsResult);
    console.log('Total gesamtpotenzial_notrap: ', gesamtpotenzial_notrap);
    console.log('Total ihrpotenzial_notrap: ', ihrpotenzial_notrap);
    console.log('Total ihrPotencialIncluded: ', ihrPotencialIncluded);
    console.log('Total gesamtpotenzial_included: ', gesamtpotenzial_included);*/

    return (
        <>  
            {loading ? <LoadingSkeletonLayout /> : <>
                    
                    {
                        (parseInt(userStatus) === 1) && <>

                            <Box sx={{textAlign:'right'}}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={handleExportToPDF} 
                                    disabled={loadingCapture}
                                    startIcon={loadingCapture && <CircularProgress size={20} />}
                                >
                                    {loadingCapture ? 'Einfangend...' : 'PDF herunterladen'}
                                </Button>
                            </Box>
                            <br />
                        </>
                    }
                    
                    <div style={{ marginTop: parseInt(userStatus) === 1 ? '-55px' : '0' }}>
                        <DisplayInfoStyle>{ displayInfoOutput }</DisplayInfoStyle>
                    </div>

                    <br />
                    
                    <div id="capture">
                        <Charts_Section 
                            rowsResult={rowsResult}
                            gesamtpotenzial_included={gesamtpotenzial_included} 
                            ihrPotencialIncluded={ihrPotencialIncluded}
                            gesamtpotenzial_included_excludedGOP={gesamtpotenzial_included_excludedGOP}
                            ihrPotencialIncluded_excludedGOP={ihrPotencialIncluded_excludedGOP}
                            gesamtpotenzial_notrap={gesamtpotenzial_notrap}
                            ihrpotenzial_notrap={ihrpotenzial_notrap}
                            scheine={scheine}
                            offeneSprechstunde={offeneSprechstunde}
                        />
                    </div> 
                    
                    <br />
                    
                    <TableSection 
                        rowsResult={rowsResult} 
                        scheine={scheine} 
                        gopNumbersInput={gopNumbersInput} 
                        kv={kv} 
                        viewOnlyMode={viewOnlyMode} 
                        userStatus={userStatus} 
                        abrechnungBillingOption={abrechnungBillingOption} 
                        debug={debug} 
                        maxHeight={tableMaxHeight} 
                        onDataChange={handleTableData}
                        abrechnungskompassCSV_ID={abrechnungskompassCSV_ID}
                        userRoleID={userRoleID}
                    />        
                    
                    <br /><br />
            </>}
        </>
    );
};

export default EBM_Abrechnungskompass_Results;
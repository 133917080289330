import { apiCall } from '../../apiHelpers/apiCall';

export const insertEBMCoefficient = async (inserts) => {
    try {
        // Validate input is an array
        if (!Array.isArray(inserts)) {
            throw new Error('Inserts must be provided as an array');
        }

        // Validate each insert object has required fields
        const invalidInserts = inserts.some(insert => !insert.state_no || !insert.gop);
        if (invalidInserts) {
            throw new Error('All inserts must include state_no and gop');
        }

        const response = await apiCall('/api/ebm_insert_coefficient', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inserts)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        return result;

    } catch (error) {
        console.error('Error inserting EBM coefficients:', error);
        throw error;
    }
};
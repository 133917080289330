import { userSalutationList, userTitleList } from '../../../../constants/userOptionsList';
import readableDateFormat from '../../../../utils/readableDateFormat';
import germanFormatCurrency from '../../../../utils/germanFormatCurrency';

const displayInfo = (userInfo, scheine, kvName, created_at = "") => {
    const showColon = kvName || created_at ? ':' : '';
    const scheineInput = scheine ? `${germanFormatCurrency(parseInt(scheine), false, false)} Scheine` : '';
    const showComma1 = (scheineInput && kvName) ? ',' : '';
    const kv_Name = kvName ? `KV ${kvName}` : '';
    const userSalutation = userSalutationList.find(salutation => salutation.id === userInfo.salutation && salutation.id !== '')?.title || '';
    const userTitle = userTitleList.find(title => title.id === userInfo.user_title && title.id !== '')?.title || '';
    const formattedDate = created_at ? 'Erstellungsdatum: ' + readableDateFormat(created_at) : '';
    const showComma2 = (kvName && created_at) ? ',' : '';

    return `EBM-Abrechnungsergebnis für ${userSalutation} ${userTitle} ${userInfo.fname} ${userInfo.lname}${showColon} ${scheineInput}${showComma1} ${kv_Name}${showComma2} ${formattedDate}`;
};

export default displayInfo;
import React from 'react';
import Typography from '@mui/material/Typography';
import DocumentUploadActivity from './sections/DocumentUploadActivity';
import RemindedUsers from './sections/RemindedUsers';
import NewUsers from './sections/NewUsers';
import FullMemberUsers from './sections/FullMemberUsers';
import CONUploadActivity from './sections/CONUploadActivity';

export default function ActivityLog() {    

    return (
        <div>
            <Typography variant="h5" component="h1" gutterBottom sx={{color: '#014017'}}>
              Activity Log
            </Typography>
            <DocumentUploadActivity />
            <br />
            <RemindedUsers />
            <br />
            <NewUsers />
            <br />
            <FullMemberUsers />
            <br />
            <CONUploadActivity />
        </div>
    );
}
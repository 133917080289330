import { apiCall } from '../../apiHelpers/apiCall';

export const updateAbrechnungskompassCSVNotes = async (updates) => {
    try {
        // Validate input is an array
        if (!Array.isArray(updates)) {
            throw new Error('Updates must be provided as an array');
        }

        // Validate each update object has required fields
        const invalidUpdates = updates.some(update => 
            !update.id
        );
        
        if (invalidUpdates) {
            throw new Error('All updates must include id');
        }

        const response = await apiCall('/api/ebm_update_abrechnungskompass_notes', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updates)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        return result;

    } catch (error) {
        console.log("abrechnungskompass_notes_update");
        console.error('Error updating Abrechnungskompass notes:', error);
        throw error;
    }
}
import React, { useEffect, useState, useRef } from 'react';
import * as DatabaseService from '../../../services/DatabaseService';
import Table_Section from '../output/Table_Section';
import readableDateFormat from '../../../utils/readableDateFormat';

export default function FullMemberUsers() {    
    const [listJSONData, setJSONData] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalRowsCount, setTotalRowsCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [dialogData, setDialogInformation] = useState({ title: "", information: "", status: "" });
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [dense, setDense] = useState(false);

    const getAllUsersData = async (page, rowsPerPage, search) => {
    
            try {
                const JSONData = await DatabaseService.getAllUsersData((page + 1), rowsPerPage, search, false, false, true);
                if (JSONData && JSONData.rows) {
                    setJSONData(JSONData.rows);
                }
                
                if (JSONData && 'totalCount' in JSONData) {
                    const totalCount = parseInt(JSONData.totalCount);
                    if (!isNaN(totalCount)) {
                        setTotalRowsCount(totalCount);
                    } else {
                        console.warn('Invalid total count value:', JSONData.totalCount);
                        setTotalRowsCount(0);
                    }
                } else {
                    console.warn('Total count property missing in response');
                    setTotalRowsCount(0);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
    }

    useEffect(() => {
        setDialogInformation({ information: "Loading...", status: "loading" });
        setLoading(true);
        getAllUsersData(page, rowsPerPage, search)
            .then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [page, rowsPerPage, search]);

    useEffect(() => {
        const updatedRows = listJSONData.map(item => ({
            id: item.id,
            customer_id: item.customerId,
            fname: item.fname,
            lname: item.lname,
            username: item.username,
            rolename: item.role.roleName,
            became_full_member_at: item.becameFullMemberAt
        }));
        setRows(updatedRows);
    }, [listJSONData]);

    const onDelete = (event) => {
        /*setDialogInformation({ information: "Löschend...", status: "loading" });
        setOpen(true);
        DatabaseService.delCONData(selected)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to delete file');
                }
            })
            .then(result => {
                setOpen(false);
                getCONData(page, rowsPerPage, search);
                setSelected([]);
            })
            .catch(error => {
                console.error('Error deleting file:', error);
                setOpen(false);
            });*/
    };

    const HEAD_CELLS = [
        {
            id: 'customer_id',
            numeric: false,
            disablePadding: true,
            label: 'Kundennummer',
        },
        {
            id: 'fname',
            numeric: false,
            disablePadding: true,
            label: 'Vorname',
        },
        {
            id: 'lname',
            numeric: false,
            disablePadding: true,
            label: 'Nachname',
        },
        {
            id: 'username',
            numeric: false,
            disablePadding: true,
            label: 'Username',
        },
        {
            id: 'rolename',
            numeric: false,
            disablePadding: true,
            label: 'Role Name',
        },
        {
            id: 'became_full_member_at',
            numeric: false,
            disablePadding: true,
            label: 'Full Member Date',
        }
    ];

    const TABLE_COLUMNS = [
        {
            id: 'customer_id',
            label: 'Kundennummer',
            format: (value) => value,
            width: '15%'
        },
        {
            id: 'fname',
            label: 'Vorname',
            format: (value) => value,
            width: '15%'
        },
        {
            id: 'lname',
            label: 'Nachname',
            format: (value) => value,
            width: '15%'
        },
        {
            id: 'username',
            label: 'Username',
            format: (value) => value,
            width: '20%'
        },
        {
            id: 'rolename',
            label: 'Role Name',
            format: (value) => value,
            width: '20%'
        },
        {
            id: 'became_full_member_at',
            label: 'Full Member Date',
            format: (value) => value ? readableDateFormat(value) : 'Date was not recorded',
            width: '20%'
        }
    ];

    return (
        <div>
            <Table_Section 
                tableTitle={`Full Member Users`}
                loading={loading}
                rows={rows}
                order={order}
                orderBy={orderBy}
                selected={selected}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRowsCount={totalRowsCount}
                dense={dense}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setSelected={setSelected}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setDense={setDense}
                onDelete={onDelete}
                setSearch={setSearch}
                search={search}
                HEAD_CELLS={HEAD_CELLS}
                TABLE_COLUMNS={TABLE_COLUMNS}
                showCheckbox={false}
                showCompactView={false}
                noResultsMessage="No new users found"
            />
        </div>
    );
}
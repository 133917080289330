import MuiDrawer from '../../components/MuiDrawer';
import Users from './Users';

export default function EBM_Editor_Main() {

  return (
            <MuiDrawer>
                <Users />
            </MuiDrawer>
          );
}
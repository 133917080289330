import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

export function EBM_Loading_Input(props) {

    const { loadingData, rowType=1, con_data } = props;

    return (
        <React.Fragment>
            
            { 
                (rowType === 1 && !loadingData.hideFirstRow) ? <>
                    <Box display="flex" flexDirection="row" gap={2}>
                            <Skeleton animation="wave" variant="rectangular" width={215} height={56} />                            
                            <Skeleton animation="wave" variant="rectangular" width={209.38} height={56} />
                            {
                                con_data <= 0 && 
                                <>
                                    <Skeleton animation="wave" variant="rectangular" width={237.86} height={56} />
                                </>
                            }
                    </Box>
                <br/>
                </> : <></>
            }

            { 
               (rowType === 2 && !loadingData.hideSecondRow) ? <>
                    <Box>
                        <Grid container spacing={2}>
                        {
                        Array.from(new Array(3)).map((_, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} />
                                    </Grid>
                                </React.Fragment>
                            ))
                        }
                        </Grid>
                    </Box>
                    </> : <></>
            }
        </React.Fragment>
    );
}
import MuiDrawer from '../../../../../../components/MuiDrawer';
import EBM_Description_Editor from './EBM_Description_Editor';

export default function EBM_Description_Editor_Main() {

  return (
            <MuiDrawer>
                <EBM_Description_Editor />
            </MuiDrawer>
          );
}
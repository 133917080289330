import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Card,
  CardContent,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/system';
import MuiDrawer from '../../components/MuiDrawer';
import teamGS from '../../components/assets/images/template/blog_drobermeier1.webp';
import GS_17 from '../../components/assets/images/template/GS_17.webp';
import Icon1 from '../../components/assets/images/template/Sachzuwendungen_Sachgutscheine_adjusted.webp';
import Icon2 from '../../components/assets/images/template/Fahrkostenzuschuss_adjusted.webp';
import Icon3 from '../../components/assets/images/template/Ueberlassung_adjusted.webp';
import Icon4 from '../../components/assets/images/template/Datenverarbeitungsgeraete_adjusted.webp';
import Icon5 from '../../components/assets/images/template/Erholungsbeihilfen_adjusted.webp';
import Icon6 from '../../components/assets/images/template/Kindergartengebuehr_adjusted.webp';
import Dr_meier from '../../components/assets/images/template/Dr_obermeier_ralph_img_opt.webp';

const BlogContainer = styled(Box)({
  maxWidth: '1200px',
  margin: 'auto',
  padding: '20px',
  fontFamily: 'BR Firma Light',
});

const BlogImage = styled('img')({
  width: '50%',
  height: 'auto',
  borderRadius: '10px',
  marginBottom: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
  [`@media (max-width: 600px)`]: {
    width: '100%!important',
  },
});

const BlogContent = styled(Box)({
  fontSize: '18px',
  color: '#333333',
  fontFamily: 'BR Firma Light',
});

const BlogPost = () => {
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState(null);

  useEffect(() => {
    // Simulate a database fetch
    setTimeout(() => {
      setPost({
        title:
          'Gehaltsoptimierung bei Mitarbeiterinnen und Mitarbeitern in Ihrer gynäkologischen Praxis',
        image: teamGS,
        //  content: [
        //       'Mit großer Freude haben wir den offiziellen Start von GynSuccess im Rahmen des Kongresses Gynäkologentreff – 14. Wissenschaftlichen Tagung vom 05. bis 07. September 2024 gefeiert. Auf Einladung der Kongressorganisatorin, Frau Dr.med. Susanne Hampel, Frauenärztin aus Berlin und Mitbegründerin des Kongresses, hatten wir die einmalige Gelegenheit, unser Unternehmen und unsere Vision vor einem engagierten Fachpublikum zu präsentieren. Seit diesem Tag sind wir offiziell online!',
        //     ],
        secondSection: {
          content: [
            'Im Zuge des Inflationsanstiegs sowie dem generellen und auch speziellen Arbeitskräftemangel in der Arztpraxis sehen sich Praxisinhaber steigenden Gehaltsforderungen ihrer Mitarbeiter ausgesetzt. Zum 31.12.2024 ist zudem die Inflations-ausgleichsprämie ausgelaufen. Diese hatte steuer- und sozialversicherungsfreie Zahlungen an die Mitarbeiter bis zu EUR 3.000,– ermöglicht.',
          ],
        },
        thirdSection: {
          image: GS_17,
          title: 'Ausgangsfall:',
          content: [
            'Eine Angestellte in der Arztpraxis verdient EUR 2.000,– netto (d.h. nach Abzug von Lohnsteuer und Sozialversicherungsbeiträgen). Dies ent-spricht in unserem Fall einem Bruttogehalt von EUR 2.974,38 (Lohnsteuerklasse 1, ledig, römisch-katholisch und versichert bei der AOK Bayern). Im anstehenden Gehaltsgespräch fordert die Angestellte von Ihrem Arbeitgeber eine Lohnerhöhung von EUR 100,– netto. Da dem Arbeitgeber der Unterschied zwischen netto und brutto nicht bekannt ist, erfüllt er den Wunsch.',
          ],
        },
        fourthSection: {
          title: 'Lösung:',
          content: [
            <>
              Der mit der Lohnabrechnung beauftragte Steuerberater rechnet die
              gewünschte Nettolohnerhöhung auf ein Bruttogehalt um. Hierbei
              ergibt sich, dass eine Erhöhung des Bruttogehalts um EUR 184,44
              notwendig ist, damit nach Abzug von Lohnsteuer und den
              Arbeitnehmeranteilen zur Sozialversicherung ein netto von EUR
              100,– beim Arbeitnehmer verbleibt. Die Arbeitgeberkosten sind
              jedoch deutlich höher, da auf das Bruttogehalt die
              Arbeitgeberkosten zur Sozialversicherung gerechnet werden müssen.
              <strong>
                Im Beispielsfall löst eine Nettolohnerhöhung von EUR 100,–
                deshalb monatliche Arbeitgeberkosten von EUR 228,51 aus.
              </strong>
            </>,
            <>
              Wenn Sie als Arbeitgeber ihrem Arbeitnehmer die EUR 100,– steuer-
              und sozial-versicherungsfrei zuwenden, könnten Sie Personalkosten
              von EUR 128,51 monatlich einsparen. Bei einem Arbeitnehmer reden
              wir über ein jährliches
              <strong>Einsparpotential</strong> von über EUR 1.540,–, bei fünf
              Arbeitnehmern multipliziert sich dieser Vorteil auf EUR 7.700,–.
            </>,
            <>
              Deswegen empfehle ich Ihnen, sich mit den Möglichkeiten der{' '}
              <strong>
                steuer- und sozialversicherungsrechtlichen Gehaltsoptimierung
              </strong>{' '}
              auseinanderzusetzen. Indem sie dem Gehaltswunsch des Arbeitnehmers
              entgegenkommen und gleichzeitig ihren Geldbeutel schonen, führen
              Sie eine <strong>Win-Win-Situation</strong> mit ihrer
              Mitarbeiterin herbei.
            </>,
            <>
              Der Gesetzgeber hat eine Vielzahl von steuerfreien bzw.
              steuergünstigen und sozialversicherungsfreien Gehaltsbestandteilen
              geschaffen. Einige kennen Sie möglicherweise bereits und haben Sie
              auch in ihrer Praxis im Einsatz. In der Folge darf ich Ihnen
              einige Gestaltungsmöglichkeiten vorstellen.
            </>,
          ],
        },

        fifthSection: {
          image: Icon1,
          title: 'Sachzuwendungen bis EUR 50,– monatlich',
          content: [
            <>
              Sachzuwendungen bis EUR 50,– monatlich sind komplett steuer- und
              sozialversicherungsfrei. Die Befreiung setzt voraus, dass{' '}
              <strong>eine „Sache“ und nicht „Geld“</strong> zugewendet wird.
              Die nachträgliche Erstattung einer Lebensmittelrechnung ist eine
              Geld- und keine begünstigte Sachleistung.
            </>,
            <>
              Eine <strong>Einkaufskarte</strong> in einem Lebensmittelladen bis
              zu EUR 50,– monatlich ist dagegen eine Sachleistung, wenn
              sichergestellt ist, dass der Empfänger lediglich eine Ware
              beziehen, aber sich kein Geld auszahlen lassen kann. Neben diesen
              Einkaufskarten sind sogenannte <strong>Firmenkreditkarten</strong>
              , die bei einer Vielzahl von Geschäften verwendet werden können,
              bei Mitarbeitern sehr beliebt (z.B. von Spendit, givve oder
              Edenred). Zunehmend beliebter werden Angebote von beispielsweise
              Wellpass oder Wellhub, welche den Zugang zu mehreren
              Fitnessstudios, Sportkursen und Apps mit Gesundheitsangeboten
              gleichzeitig ermöglichen.
            </>,
            <>
              Eine Werkstudentin in unserer Kanzlei hat im Vorstellungsgespräch
              geäußert, dass Sie zu keinem Arbeitgeber gehen würde, der nicht
              den vorgenannten <strong>„Fitnesspass“ </strong>im Angebot hat.
              Zum Glück bieten wir in unserer Kanzlei alternativ zu unserer
              Firmenkreditkarte einen Fitnesspass im Wert von EUR 50,– monatlich
              an.
            </>,
            <>
              Wichtig für die Steuer- und Sozialversicherungsfreiheit der
              meisten hier vorgestellten, optimierten Gehaltsbestandteile ist,
              dass diese{' '}
              <strong>zusätzlich zum ursprünglich vereinbarten Gehalt</strong>{' '}
              bezahlt werden. Eine Gehaltsumwandlung von bereits vereinbartem
              Gehalt ist dagegen grundsätzlich nicht möglich.&nbsp; Dies gilt
              auch für die vorgenannten Sachzuwendungen.{' '}
              <strong>
                Deswegen kommen diese optimierten Gehaltsbestandteile vor allem
                bei Gehaltserhöhungen oder bei der Neueinstellung eines
                Mitarbeiters zum Tragen.
              </strong>
            </>,
          ],
        },

        sixthSection: {
         image: Icon2,
          title: 'Fahrtkostenzuschuss für die Fahrten zwischen Wohnung und Arbeitsstätte',
          content: [
            'Sie können ihrem Arbeitnehmer pro Entfernungskilometer, d.h. für die einfache Fahrt zwischen Wohnung und Arbeitsstätte EUR 0,30 für die ersten 20 Kilometer und ab dem 21. Kilometer EUR 0,38 erstatten.',
            'Wohnt der Arbeitnehmer beispielsweise 30 Kilometer entfernt, können Sie bei durchschnittlich 15 Arbeitstagen im Monat folgenden Betrag zusätzlich zum Gehalt erstatten: (20 x EUR 0,30 + 10 x EUR 0,38) x 15 Arbeitstage = EUR 147,–',
            'Dieser Betrag im Beispiel ist für den Arbeitnehmer steuer- und sozialversicherungsfrei. Sie als Arbeitgeber müssen lediglich 15 % pauschale Lohnsteuer an das Finanzamt abführen. Im Beispiel wären das lediglich EUR 22,05.',
            'Eine Gehaltsumwandlung ist auch hier leider nicht möglich.',
          ],
        },
        seventhSection: {
        image: Icon3,
          title: 'Kostenlose Überlassung von Telekommunikationsgeräten und Datenverarbeitungsgeräten',
          content: [
            'Der Arzt kann seinen Mitarbeitern betriebliche Datenverarbeitungsgeräte (z.B. PC, Laptop, Tablet), Telekommunikationsgeräte (z.B. Telefon, Handy, Smartphone) und Zubehör (z.B. Monitor, Drucker, Modem) zur teilweisen oder ausschließlich privaten Nutzung steuer- und sozialabgabenfrei überlassen und die entsprechenden laufenden Kosten (z.B. Flatrate) übernehmen.',
            'Voraussetzung für die Steuer- und Sozialversicherungsfreiheit ist, dass das jeweilige Gerät Firmeneigentum des Arztes ist und es sich um eine Zusatzleistung zum bisherigen Gehalt handelt.',
          ],
        },
        eightSection: {
            image: Icon4,
              title: 'Übereignung von Datenverarbeitungsgeräten und Zubehör an den Arbeitnehmer',
              content: [
                'Der Unterschied zur vorherigen Gestaltung ist, dass die Geräte Eigentum des Arbeitnehmers werden, er also nach Ende des Arbeitsverhältnisses frei darüber verfügen kann; Telekommunikationsgeräte sind von dieser Regelung ausgenommen.',
                'Der Arbeitgeber ist dabei lediglich mit 25 % pauschaler Lohnsteuer belastet. Für den Arbeitnehmer ist die Übereignung steuer- und sozialversicherungsfrei.',
                'Die vorgenannte Zuwendung bietet sich beispielsweise an, wenn der Arzt seinem Mitarbeiter einen Bonus zukommen lassen möchte, z.B. für den Verkauf von IGeL-Leistungen. Statt eines Bonus in Form von Geld könnte die Zuwendung eines Tablet eine steuersparende Alternative sein. Wichtig ist hierbei, dass nicht zuvor ein Geldbonus vereinbart war, da eine Gehaltsumwandlung die Steuer- und Sozialversicherungsfreiheit gefährden würde.',
              ],
            },
        ninthSection: {
            image: Icon5,
              title: 'Erholungsbeihilfen',
              content: [
                'Als sogenannte Erholungsbeihilfen sind Zuzahlungen des Arztes an seine Mitarbeiter pro Jahr bis zu EUR 156,– steuer- und sozialversicherungsfrei. Dieser Betrag erhöht sich für den Ehepartner um bis zu EUR 104,– und bis zu EUR 52,– für jedes Kind. Der Arbeitgeber trägt lediglich 25 % pauschale Lohnsteuer.',
                'Diese Erholungsbeihilfen sollen, wie der Name sagt, der Erholung dienen. Voraussetzung für die Steuer- und Sozialversicherungsfreiheit ist nach Ansicht der Finanzverwaltung, dass der Mitarbeiter innerhalb von drei Monaten vor oder nach der Auszahlung einen Erholungsurlaub antritt. Ein Nachweis, dass der Mitarbeiter auch weggefahren ist, ist dabei nicht notwendig.',
                'Durch diese einfache Möglichkeit können beispielsweise verheirateten Mitarbeiterinnen mit zwei Kindern im Jahr EUR 156 + EUR 104 + 2x EUR 52,– = EUR 364,– im Jahr steuer- und sozialversicherungsfrei zugewendet werden.',
              ],
            },
        tenthSection: {
            image: Icon6,
              title: 'Gebühren für Kindergarten/Kindertagesstätte',
              content: [
                'Eine Mitarbeiterin möchte nach ihrer Schwangerschaft frühzeitig wieder bei Ihnen in der Arztpraxis als Angestellte arbeiten. Die Kindertagesstätte zur Betreuung ihres Kindes kostet EUR 800,– im  Monat inkl. Verpflegung.',
                'Die Unterbringung und Betreuung von nicht schulpflichtigen Kindern ist besonders gefördert. So können Sie der Mitarbeiterin die Betreuung und Verpflegung, im Beispielsfall EUR 800,– monatlich, in vollem Umfang steuer- und sozialversicherungsfrei erstatten. Voraussetzung ist allerdings, dass die Erstattung zuzüglich zum üblichen Lohn erfolgt (keine Gehaltsumwandlung) und Ihnen die Mitarbeiterin Nachweise zu den Kosten im Original vorlegt.',
              ],
            },
        eleventhSection: {
              title: 'Fazit',
              content: [
                'Vom Bruttogehalt des Arbeitnehmers verbleibt diesem nach Abzug von Steuer und Sozialversicherungsbeiträgen netto üblicherweise nur ein Bruchteil. Die Arbeitgeberkosten sind aufgrund der Arbeitgeberanteile zur Sozialversicherung sogar nochmals deutlich höher als das Bruttogehalt.',
                'Dieser Artikel hat Ihnen verschiedene Gestaltungsmöglichkeiten aufgezeigt, ihren Mitarbeitern steuer- und sozialversicherungsfreie Gehaltsbestandteile zuzuwenden. Dadurch lassen sich im Personalbereich erhebliche Kosten einsparen.',
                'In einer Fortsetzung dieses Artikels, der im März erscheinen wird, möchte ich Ihnen weitere Gestaltungsmöglichkeiten zur Gehaltsoptimierung aufzeigen.',
            ],
            },
        twelfthSection: {
              image: Dr_meier,
              content: [
                'Dr. Ralph Obermeier ist Gesellschafter-geschäftsführer der ACTIO Revision und Treuhand GmbH, einer Steuerberatungs-gesellschaft mit ca. 75 Mitarbeitern im Süden von München, die sich u.a. auf die Beratung von Ärzten spezialisiert hat.',
                'Er berät mittelständische Unternehmen und Freiberufler (Ärzte, Zahnärzte, etc.) umfassend in steuerlicher und wirtschaftlicher Hinsicht. Sein Augenmerk gilt hierbei besonders der Steueroptimierung im Hinblick auf veränderte steuerliche Rahmenbedingungen sowie in Verkaufs- und Umstrukturierungsfällen.',
                'Dr. Ralph Obermeier fungiert seit Gründung der GynSuccess GmbH für diese als wissenschaftlicher Beirat.',
            ],
            },
      });
      setLoading(false);
    }, 2000); // Simulated loading delay
  }, []);

  return (
    <MuiDrawer>
      <BlogContainer>
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height={400} />
        ) : (
          <>
            {/* Main Blog Section */}
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{ color: '#75BB44', textAlign: 'center' }}
            >
              {post.title}
            </Typography>
            <Typography
              paragraph
              sx={{
                lineHeight: '1.8em',
                fontSize: '18px',
                textAlign: 'center',
              }}
            >
              {'von Dr. Ralph Obermeier / 03.02.2025 / Finanzen'}
            </Typography>
            <BlogImage src={post.image} alt={post.title} />
            {/* <BlogContent>
              {post.content.map((paragraph, index) => (
                <Typography key={index} paragraph sx={{ lineHeight: '1.8em', fontSize: '18px' }}>
                  {paragraph}
                </Typography>
              ))}
            </BlogContent> */}

            {/* Second Section */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
                marginTop: 2,
                marginBottom: 3,
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* Text fullwidth*/}
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                  '@media (max-width: 600px)': {
                    maxWidth: '100%!important', // Adjust width for mobile
                  },
                }}
              >
                <BlogContent>
                  {post.secondSection.content.map((paragraph, index) => (
                    <Typography
                      key={index}
                      paragraph
                      sx={{ lineHeight: '1.8em', fontSize: '18px' }}
                    >
                      {paragraph}
                      {
                        <p>
                          <strong>
                            Ziel dieses Artikels ist es, Ihnen einige
                            Möglichkeiten aufzuzeigen, wie Sie Mitarbeitern
                            ihren Wunsch nach Gehaltserhöhung erfüllen und dabei
                            ihre Personalkosten schonen können.
                          </strong>
                        </p>
                      }
                    </Typography>
                  ))}
                </BlogContent>
              </Box>

              {/* Image floated right */}
              {/* <Box sx={{ flex: 1 }}>
                <Box
                component ="img"
                  src={post.secondSection.image}
                  alt="GYNTreff"
                 sx={{ width: '100%', maxWidth:'250px', borderRadius: '8px', '@media (max-width: 600px)': { maxWidth: '100%!important', // Adjust width for mobile
               }, }}
                />
              </Box> */}
            </Box>

            {/* Third Section */}
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{ color: '#75BB44' }}
            >
              {post.thirdSection.title}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: 2,
                marginTop: 2,
                marginBottom: 3,
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* Text and Image on the right */}
              <Box sx={{ flexShrink: 0 }}>
                <Box
                  component="img"
                  src={post.thirdSection.image}
                  alt="Third Image"
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    maxWidth: '504px',
                    '@media (max-width: 600px)': {
                      maxWidth: '100%!important', // Adjust width for mobile
                    },
                  }}
                />
              </Box>
              <Box sx={{ flex: 1, textAlign: 'left', maxWidth: '504px' }}>
                <Typography sx={{ fontSize: '18px', lineHeight: '1.8em' }}>
                  {post.thirdSection.content}
                </Typography>
              </Box>
            </Box>
            {/* Fourth Section */}
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ color: '#75BB44' }}
            >
              {post.fourthSection.title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                gap: 2,
                marginTop: 2,
                width: '100%',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* Text and Image on the right */}
              <Box sx={{ flex: 1, textAlign: 'left' }}>
                {post.fourthSection.content.map((paragraph, index) => (
                  <Typography
                    key={index}
                    sx={{ fontSize: '18px', lineHeight: '1.8em' }}
                    paragraph
                  >
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </Box>

            {/* Fifth Section */}
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ color: '#75BB44' }}
            >
              {post.fifthSection.title}
            </Typography>

            <Box
              sx={{
                display: 'block',
                marginTop: 2,
                marginBottom: 3,
                flexDirection: { xs: 'column', sm: 'row' }, // Column on mobile, row on larger screens
              }}
            >
              {/* Image on the left */}
              <Box
                sx={{
                  flexShrink: 0,
                  maxWidth: '275px',
                  float: 'left',
                  marginRight: '15px',
                  marginTop: '8px',
                '@media (max-width: 600px)': { float: 'unset!important'},
                }}
              >
                <Box
                  component="img"
                  src={post.fifthSection.image}
                  alt="Fifth Image"
                  sx={{
                    maxWidth: '275px',
                    borderRadius: '8px',
                    width: '100%',
                    '@media (max-width: 600px)': { maxWidth: '100%!important' }, // Full width on mobile
                  }}
                />
              </Box>

              {/* Text on the right */}
              <Box sx={{ flex: 1, textAlign: 'left' }}>
                {post.fifthSection.content.map((paragraph, index) => (
                  <Typography
                    key={index}
                    sx={{ fontSize: '18px', lineHeight: '1.8em' }}
                    paragraph
                  >
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </Box>

            {/* Sixth Section */}
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ color: '#75BB44' }}
            >
              {post.sixthSection.title}
            </Typography>

            <Box
              sx={{
                display: 'block',
                marginTop: 2,
                marginBottom: 3,
                flexDirection: { xs: 'column', sm: 'row' }, // Column on mobile, row on larger screens
              }}
            >
              {/* Image on the left */}
              <Box
                sx={{
                  flexShrink: 0,
                  maxWidth: '275px',
                  float: 'left',
                  marginRight: '15px',
                  marginTop: '8px',
                '@media (max-width: 600px)': { float: 'unset!important'}, // Full width on mobile
                }}
              >
                <Box
                  component="img"
                  src={post.sixthSection.image}
                  alt="Fifth Image"
                  sx={{
                    maxWidth: '275px',
                    borderRadius: '8px',
                    width: '100%',
                    '@media (max-width: 600px)': { maxWidth: '100%!important' }, // Full width on mobile
                  }}
                />
              </Box>

              {/* Text on the right */}
              <Box sx={{ flex: 1, textAlign: 'left' }}>
                {post.sixthSection.content.map((paragraph, index) => (
                  <Typography
                    key={index}
                    sx={{ fontSize: '18px', lineHeight: '1.8em' }}
                    paragraph
                  >
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </Box>
            {/* Seventh Section */}
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ color: '#75BB44' }}
            >
              {post.seventhSection.title}
            </Typography>

            <Box
              sx={{
                display: 'block',
                marginTop: 2,
                marginBottom: 3,
                flexDirection: { xs: 'column', sm: 'row' }, // Column on mobile, row on larger screens
              }}
            >
              {/* Image on the left */}
              <Box
                sx={{
                  flexShrink: 0,
                  maxWidth: '275px',
                  float: 'left',
                  marginRight: '15px',
                  marginTop: '8px',
                  '@media (max-width: 600px)': { float: 'unset!important'},
                }}
              >
                <Box
                  component="img"
                  src={post.seventhSection.image}
                  alt="Fifth Image"
                  sx={{
                    maxWidth: '275px',
                    borderRadius: '8px',
                    width: '100%',
                    '@media (max-width: 600px)': { maxWidth: '100%!important' }, // Full width on mobile
                  }}
                />
              </Box>

              {/* Text on the right */}
              <Box sx={{ flex: 1, textAlign: 'left' }}>
                {post.seventhSection.content.map((paragraph, index) => (
                  <Typography
                    key={index}
                    sx={{ fontSize: '18px', lineHeight: '1.8em' }}
                    paragraph
                  >
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </Box>
            {/* Eight Section */}
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ color: '#75BB44' }}
            >
              {post.eightSection.title}
            </Typography>

            <Box
              sx={{
                display: 'block',
                marginTop: 2,
                marginBottom: 3,
                flexDirection: { xs: 'column', sm: 'row' }, // Column on mobile, row on larger screens
              }}
            >
              {/* Image on the left */}
              <Box
                sx={{
                  flexShrink: 0,
                  maxWidth: '275px',
                  float: 'left',
                  marginRight: '15px',
                  marginTop: '8px',
                  '@media (max-width: 600px)': { float: 'unset!important'},
                }}
              >
                <Box
                  component="img"
                  src={post.eightSection.image}
                  alt="Fifth Image"
                  sx={{
                    maxWidth: '275px',
                    borderRadius: '8px',
                    width: '100%',
                    '@media (max-width: 600px)': { maxWidth: '100%!important' }, // Full width on mobile
                  }}
                />
              </Box>

              {/* Text on the right */}
              <Box sx={{ flex: 1, textAlign: 'left' }}>
                {post.eightSection.content.map((paragraph, index) => (
                  <Typography
                    key={index}
                    sx={{ fontSize: '18px', lineHeight: '1.8em' }}
                    paragraph
                  >
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </Box>
            {/* Ninth Section */}
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ color: '#75BB44' }}
            >
              {post.ninthSection.title}
            </Typography>

            <Box
              sx={{
                display: 'block',
                marginTop: 2,
                marginBottom: 3,
                flexDirection: { xs: 'column', sm: 'row' }, // Column on mobile, row on larger screens
              }}
            >
              {/* Image on the left */}
              <Box
                sx={{
                  flexShrink: 0,
                  maxWidth: '275px',
                  float: 'left',
                  marginRight: '15px',
                  marginTop: '8px',
                  '@media (max-width: 600px)': { float: 'unset!important'},
                }}
              >
                <Box
                  component="img"
                  src={post.ninthSection.image}
                  alt="Fifth Image"
                  sx={{
                    maxWidth: '275px',
                    borderRadius: '8px',
                    width: '100%',
                    '@media (max-width: 600px)': { maxWidth: '100%!important' }, // Full width on mobile
                  }}
                />
              </Box>

              {/* Text on the right */}
              <Box sx={{ flex: 1, textAlign: 'left' }}>
                {post.ninthSection.content.map((paragraph, index) => (
                  <Typography
                    key={index}
                    sx={{ fontSize: '18px', lineHeight: '1.8em' }}
                    paragraph
                  >
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </Box>
            {/* Tenth Section */}
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ color: '#75BB44' }}
            >
              {post.tenthSection.title}
            </Typography>

            <Box
              sx={{
                display: 'block',
                marginTop: 2,
                marginBottom: 3,
                flexDirection: { xs: 'column', sm: 'row' }, // Column on mobile, row on larger screens
              }}
            >
              {/* Image on the left */}
              <Box
                sx={{
                  flexShrink: 0,
                  maxWidth: '275px',
                  float: 'left',
                  marginRight: '15px',
                  marginTop: '8px',
                  '@media (max-width: 600px)': { float: 'unset!important'},
                }}
              >
                <Box
                  component="img"
                  src={post.tenthSection.image}
                  alt="Fifth Image"
                  sx={{
                    maxWidth: '275px',
                    borderRadius: '8px',
                    width: '100%',
                    '@media (max-width: 600px)': { maxWidth: '100%!important' }, // Full width on mobile
                  }}
                />
              </Box>

              {/* Text on the right */}
              <Box sx={{ flex: 1, textAlign: 'left' }}>
                {post.tenthSection.content.map((paragraph, index) => (
                  <Typography
                    key={index}
                    sx={{ fontSize: '18px', lineHeight: '1.8em' }}
                    paragraph
                  >
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </Box>
            {/* Eleventh Section */}
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ color: '#75BB44' }}
            >
              {post.eleventhSection.title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                gap: 2,
                marginTop: 2,
                width: '100%',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* Text and Image on the right */}
              <Box sx={{ flex: 1, textAlign: 'left' }}>
                {post.eleventhSection.content.map((paragraph, index) => (
                  <Typography
                    key={index}
                    sx={{ fontSize: '18px', lineHeight: '1.8em' }}
                    paragraph
                  >
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </Box>
            {/* Twelfth Section */}
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{ color: '#75BB44' }}
            >
              {post.twelfthSection.title}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: 2,
                marginTop: 2,
                marginBottom: 3,
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* Text and Image on the right */}
              <Box sx={{ flexShrink: 0 }}>
                <Box
                  component="img"
                  src={post.twelfthSection.image}
                  alt="Third Image"
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    maxWidth: '300px',
                    '@media (max-width: 600px)': {
                      maxWidth: '100%!important', // Adjust width for mobile
                    },
                  }}
                />
              </Box>
              <Box sx={{ flex: 1, textAlign: 'left', maxWidth: '680px' }}>
                <Typography sx={{ fontSize: '18px', lineHeight: '1.8em' }}>
                  {post.twelfthSection.content}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </BlogContainer>
    </MuiDrawer>
  );
};

export default BlogPost;
//Page: Fuenfter Termin
export const TERMIN5_FACTOR_MAPPING = [
    {
        ziffer: "21",
        anzahl: "1"
    },
    {
        ziffer: "24",
        anzahl: "1"
    },
    {
        ziffer: "298",
        anzahl: "3"
    },
    {
        ziffer: "403",
        anzahl: "1",
        pre_assigned_factor: "1,8"
    },
    {
        ziffer: "420",
        anzahl: "3"
    },
    {
        ziffer: "602",
        anzahl: "1"
    },
    {
        ziffer: "1001",
        anzahl: "1"
    },
    {
        ziffer: "1070",
        anzahl: "1"
    },
    {
        ziffer: "1075",
        anzahl: "1"
    },
    {
        ziffer: "3508",
        anzahl: "1"
    },
    {
        ziffer: "3509",
        anzahl: "1"
    },
    {
        ziffer: "3511",
        anzahl: "1"
    },
    {
        ziffer: "3532",
        anzahl: "1"
    },
    {
        ziffer: "3714",
        anzahl: "1"
    },
    {
        ziffer: "3736",
        anzahl: "1"
    },
    {
        ziffer: "4506",
        anzahl: "1"
    },
    {
        ziffer: "4711",
        anzahl: "1"
    },
    {
        ziffer: "A5373",
        anzahl: "1"
    },
    {
        ziffer: "A1008",
        anzahl: "1"
    }
];
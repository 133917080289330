import React, { useEffect, useState, useRef } from 'react';
import * as DatabaseService from '../../../../services/DatabaseService';
import Controls from '../../../../components/controls/Controls';
import Table_Section from './output/Table_Section';
import DialogOutput from '../../../../components/DialogOutput';
import GopSearch from './components/GopSearch';
import InsertEBMCoefficientForm from './components/InsertEBMCoefficientForm';
import UpdateEBMCoefficientForm from './components/UpdateEBMCoefficientForm';
import EBM_Description_Editor from './subpages/EBM_Description_Editor/EBM_Description_Editor';
import { EBM_Loading_Input } from './components/Loading/LoadingSkeletonLayout';

export default function EBM_Editor() {
    const [listJSONData, setJSONData] = useState([]);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [totalRowsCount, setTotalRowsCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [loadingData, setLoadingData] = useState({ hideFirstRow: false, hideSecondRow: false });
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const [dialogData, setDialogInformation] = useState({ title: "", information: "", status: "" });
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [dense, setDense] = useState(false);
    const fileInput = useRef(null);

    const [listStates, setListStates] = useState([]);
    const [disableDropdownKV, setDisableDropdownKV] = useState(false);
    const [searchGop, setSearchGop] = useState([]);
    
    const [kv, setKv] = useState('');
    const [kvName, setKvName] = useState('');

    const [isKvLoaded, setIsKvLoaded] = useState(false);

    //const getEBMInputRowsData = async (page, rowsPerPage, search) => {
    const getEBMInputRowsData = async (kv) => {
        try {
            const defaultScheine = 0;
            const JSONData = await DatabaseService.getEBMAbrechnungskompassSearchByStates(defaultScheine, kv, true);
            if (JSONData && JSONData.stateRows) {
                setListStates(JSONData.stateRows);
        
                // Set initial KV if not already set
                if (!kv && JSONData.stateRows.length > 0) {
                    const firstState = JSONData.stateRows[0];
                    setKv(firstState.id.toString());
                    setKvName(firstState.title);
                } else {
                    // Find and set KV name for selected KV
                    const selectedState = JSONData.stateRows.find(state => state.id === parseInt(kv));
                    if (selectedState) {
                        setKvName(selectedState.title);
                    }
                }
                setIsKvLoaded(true);
                setLoadingData({ hideFirstRow: true, hideSecondRow: true });
            }

            if (JSONData && JSONData.rows && kv) {
                setJSONData(JSONData.rows);
                setTotalRowsCount(JSONData.totalCount);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        setDialogInformation({ information: "Loading...", status: "loading" });
        setLoading(true);
        getEBMInputRowsData(kv)
            .then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [kv]);

    useEffect(() => {
        //if (isKvLoaded && kv) {
            const updatedRows = listJSONData.map(item => ({
                id: item.id, 
                gop: item.gop, 
                ebm_koeffizient: item.ebm_koeffizient,
                include_chart: item.include_chart, 
                hide: item.hide
            }));
            setRows(updatedRows);
            setFilteredRows(updatedRows);
        //}
    }, [listJSONData]);

    const handleKvChange = (e) => {
        const newKv = e.target.value;

        setLoading(true);

         // Clear search states when changing KV
        setSearchGop([]);
        setSearch('');

        // Clear existing data before loading new
        setRows([]);
        setFilteredRows([])

        setKv(newKv);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Modify the search effect to include both text search and GOP tags
    useEffect(() => {
        // Skip filtering if data is still loading or KV isn't loaded
        if (!isKvLoaded || loading) {
            return;
        }
    
        if (!search.trim() && searchGop.length === 0) {
            setFilteredRows(rows);
            return;
        }
    
        const searchLower = search.toLowerCase();
        const filtered = rows.filter(row => {
            const matchesTextSearch = !search.trim() || 
                row.gop?.toString().toLowerCase().includes(searchLower) ||
                row.ebm_koeffizient?.toString().toLowerCase().includes(searchLower);
                
            const matchesGopTags = searchGop.length === 0 || 
                searchGop.includes(row.gop);
    
            return matchesTextSearch && matchesGopTags;
        });
        
        setFilteredRows(filtered);
        setPage(0);
    }, [search, rows, searchGop, isKvLoaded, loading]);

    const onDelete = async () => {
        if (!window.confirm('Sind Sie sicher, dass Sie diesen Koeffizienten löschen möchten?')) {
            return;
        }

        setDialogInformation({ information: "Löschend...", status: "loading" });
        setOpen(true);
    
        try {
            const results = await Promise.all(
                selected.map(id => DatabaseService.deleteEBMCoefficient(id))
            );
    
            const failures = results.filter(result => !result.success);
            
            if (failures.length > 0) {
                setDialogInformation({ 
                    information: `Fehler beim Löschen: ${failures.map(f => f.message).join(', ')}`, 
                    status: "error" 
                });
            } else {
                setDialogInformation({ 
                    information: "Erfolgreich gelöscht", 
                    status: "success" 
                });
                await getEBMInputRowsData(kv);
                setSelected([]);
            }
        } catch (error) {
            console.error('Error deleting descriptions:', error);
            setDialogInformation({ 
                information: "Fehler beim Löschen der Beschreibungen", 
                status: "error" 
            });
        } finally {
            setTimeout(() => setOpen(false), 2000);
        }
    };

    const onAddCoefficient = async () => {
        setDialogInformation({ title: "Koeffizient Einfügen", status: "view", action: "insert" });
        setOpen(true);
    };

    const onUpdateCoefficient = async (row) => {
        setDialogInformation({ 
            title: "Koeffizient Aktualisieren", 
            information: row, 
            status: "view", 
            action: "update" 
        });
        setOpen(true);
    };

    const onUpdateZifferDescriptions = async (ziffer) => {
        setDialogInformation({ 
            title: "Beschreibungen Aktualisieren", 
            information: ziffer, 
            status: "view", 
            action: "update_description" 
        });
        setOpen(true);
    }

    const renderDialogContent = () => {
        if (dialogData.status !== "view") return null;
    
        switch (dialogData.action) {
            case "insert":
                return (
                    <InsertEBMCoefficientForm 
                        kv={kv} 
                        onInsertSuccess={() => getEBMInputRowsData(kv)} 
                    />
                );
                
            case "update":
                return (
                    <UpdateEBMCoefficientForm 
                        kv={kv} 
                        onUpdateSuccess={(updatedRow) => {
                            setJSONData(prevData => 
                                prevData.map(row => 
                                    row.id === updatedRow.id ? updatedRow : row
                                )
                            );
                            setFilteredRows(prevRows =>
                                prevRows.map(row =>
                                    row.id === updatedRow.id ? updatedRow : row
                                )
                            );
                            setDialogInformation(prev => ({
                                ...prev,
                                information: updatedRow
                            }));
                        }} 
                        row={dialogData.information} 
                    />
                );
            case "update_description":
                return (
                    <EBM_Description_Editor 
                        zifferValue={dialogData.information} 
                    />
                );
            default:
                return null;
        }
    };

    const filteredListStates = listStates;

    return (
        <>
             { 
                !loadingData.hideFirstRow ? <EBM_Loading_Input loadingData={loadingData} rowType={1} /> : <>
                    <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                        <div>
                            <Controls.Select
                                name="state_id"
                                label="KV"
                                value={kv}
                                onChange={handleKvChange}
                                showNoneOption={false}
                                options={filteredListStates}
                                disabled={disableDropdownKV}
                                sx={{ '& .MuiInputBase-input.Mui-disabled': { WebkitTextFillColor: 'black', color: 'black' }}}
                            />
                        </div>
                        <div>
                            <GopSearch 
                                rows={rows}
                                searchGop={searchGop}
                                setSearchGop={setSearchGop}
                            />
                        </div>
                    </div>
                </>
            }

            <Table_Section 
                tableTitle="EBM Editor"
                loading={loading}
                rows={filteredRows}
                order={order}
                orderBy={orderBy}
                selected={selected}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRowsCount={totalRowsCount}
                dense={dense}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setSelected={setSelected}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setDense={setDense}
                onDelete={onDelete}
                setSearch={setSearch}
                search={search}
                onAddCoefficient={onAddCoefficient}
                onUpdateCoefficient={onUpdateCoefficient}
                onUpdateZifferDescriptions={onUpdateZifferDescriptions}
            />

            {
                dialogData.status === "loading" || dialogData.status === "view" ? (
                <DialogOutput
                    fullScreen={false}
                    open={open}
                    dialogData={dialogData}
                    handleClose={handleClose}
                    output={renderDialogContent()}
                />
                ) : null
            }
        </>
    );
}
import { apiCall } from '../../apiHelpers/apiCall';

export const deleteEBMCoefficient = async (id) => {
    try {
        const response = await apiCall('/api/del/gop_median', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: id }),
        });
        
        if (!response.ok) {
            throw new Error('Netzwerkantwort war nicht in Ordnung');
        }
        
        const result = await response.json();
        return {
            success: result > 0,
            message: result > 0 ? 'Erfolgreich gelöscht' : 'Keine Zeilen gelöscht'
        };
    } catch (error) {
        return {
            success: false,
            message: error.message || 'Fehler beim Löschen des Koeffizienten'
        };
    }
};
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Paper, TableContainer, CircularProgress, Table, TableBody, TableRow, TableCell, Checkbox, Button, TablePagination, FormControlLabel, Switch } from '@mui/material';
import EnhancedTableToolbar from '../components/EnhancedTableToolbar';
import EnhancedTableHead from '../components/EnhancedTableHead';
import LoadingTable from '../style/LoadingTable';
import TablePaginationActions from '../../../../../../../components/TablePaginationActions';
import readableDateFormat from '../../../../../../../utils/readableDateFormat';
import { TextField } from '@mui/material';
import { Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import ReadOnlyTextField from '../../../../../../../components/ReadOnlyTextField';

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

export default function Table_Section(props) {

    const { 
            tableTitle,
            loading, 
            rows, 
            order, 
            orderBy, 
            selected, 
            page, 
            rowsPerPage, 
            totalRowsCount, 
            dense
        } = props;

    const headCells = [
        {
            id: 'gop',
            numeric: false,
            disablePadding: true,
            label: 'Ziffer',
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: true,
            label: 'Description',
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: true,
            label: 'Title',
        },
        {
            id: 'header_no',
            numeric: false,
            disablePadding: true,
            label: 'Header Group',
        },
        {
            id: 'edit',
            numeric: false,
            disablePadding: true,
            label: '',
        }
    ];

    const visibleRows = stableSort(rows, getComparator(order, orderBy));

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        props.setOrder(isAsc ? 'desc' : 'asc');
        props.setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            props.setSelected(newSelected);
            return;
        }
        props.setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        props.setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (<>

            <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <EnhancedTableToolbar 
                            tableTitle={tableTitle}
                            numSelected={selected.length} 
                            onDelete={props.onDelete} 
                            onAddZifferDescription={props.onAddZifferDescription}
                            setSearch={props.setSearch} 
                            setPage={props.setPage} 
                            search={props.search} 
                        />
                        <TableContainer sx={{ position: 'relative' }}>
                            {loading && (
                                <LoadingTable>
                                    <div>
                                        <CircularProgress />
                                    </div>
                                </LoadingTable>
                            )}
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                />
                                <TableBody>
                                    {visibleRows.length > 0 ? visibleRows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                                sx={{ cursor: 'pointer' }}
                                                className='checkboxId'
                                                data-id={row.id}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        onClick={(event) => handleClick(event, row.id)}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                    sx={{ paddingRight: '1em' }}
                                                >
                                                    {row.gop}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                    sx={{ paddingRight: '1em' }}
                                                >
                                                    <ReadOnlyTextField value={row.description} />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                    sx={{ paddingRight: '1em' }}
                                                >
                                                    <ReadOnlyTextField value={row.title} />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                    sx={{ paddingRight: '1em' }}
                                                >
                                                    {parseInt(row.header_no) > 0 ? (<Chip color="success" size="small" label="Assigned" />) : (<Chip color="warning" size="small" label="Not Yet Assigned" />)}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                    sx={{ paddingRight: '1em' }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => props.onUpdateZifferDescriptions(row.gop)}
                                                    >
                                                        Edit
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : loading ? (
                                        <TableRow style={{ height: '120px' }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={parseInt(totalRowsCount)}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelRowsPerPage="Zeilen pro Seite"
                        />
                    </Paper>
                </Box>

    </>)

}
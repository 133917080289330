import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Controls from '../../../components/controls/Controls';
import * as DatabaseService from '../../../services/DatabaseService';

const InsertUserForm = ({ 
    listAllUserRoles, 
    insertUserData_func, 
    onSuccess, 
    setOpen 
}) => {
    
    const [registrationData, setRegistrationData] = useState({ 
        username: '', 
        password: '', 
        password1: '', 
        email: '', 
        fname: '', 
        lname: '', 
        role: '' 
    });
    const [errorRegistration, setErrorRegistration] = useState({});
    const [enableValidation, setEnableValidation] = useState(false);
    const [alertRegistration, setAlertRegistration] = useState({ text: '', appear: false, severity: '' });
    const [registerButtonDisabled, setRegisterButtonDisabled] = useState(false);
    const [emailExist, setEmailExist] = useState(0);
    const [usernameExist, setUsernameExist] = useState(0);

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const checkEmail = async (data) => {
        return await DatabaseService.checkEmail(data);
    }

    const checkUsername = async (data) => {
        return await DatabaseService.checkUsername(data);
    }

    function changeRegistrationData(e) {
        setRegistrationData({ ...registrationData, [e.target.name]: e.target.value });
    }

    async function onRegistration() {
        setEnableValidation(true);

        if (!validation(registrationData, true)) {
            setRegisterButtonDisabled(true);
            
            insertUserData_func(registrationData)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Failed to add user');
                    }
                })
                .then(result => {
                    setAlertRegistration({
                        text: result.message,
                        appear: true,
                        severity: 'success'
                    });

                    setTimeout(() => {
                        setOpen(false);

                        // Update table data after successful addition
                        if (typeof onSuccess === 'function') {
                            onSuccess();
                        }
                    }, 2000);
                })
                .catch(error => {
                    console.error('Error adding user:', error);
                    setOpen(false);
                    // Handle error here, show message or take appropriate action
                });
        }
    }

    function onRegistrationValidation() {
        checkEmail(registrationData.email).then(r => r.json()).then(result => {
            setEmailExist(result.rows);
        });

        checkUsername(registrationData.username).then(r => r.json()).then(result => {
            setUsernameExist(result.rows);
        });

        validation(registrationData, enableValidation);
    }

    function onRegistrationValidationFocusOut() {
        validation(registrationData, enableValidation);
    }

    function validation(registrationData, validate) {
        let error = {}

        if (validate) {
            error.email = (/$^|.+@.+..+/).test(registrationData.email) 
                ? (emailExist) ? "E-Mail existiert bereits" : ""
                : "E-Mail ist ungültig";

            error.username = (registrationData.username === "") 
                ? "Benutzername ist erforderlich" 
                : (usernameExist) ? "Benutzername existiert bereits" : "";

            error.password = (registrationData.password === "")
                ? "Password is required"
                : (registrationData.password.length < 8) ? "Password length must be 8 characters"
                : "";

            error.role = (registrationData.role === "") ? "Role is required" : "";
        }

        setErrorRegistration(error);

        if (Object.values(error).every(v => !v)) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <>
            <TextField
                required
                margin="dense"
                id="fname"
                label="First Name"
                name="fname"
                fullWidth
                type="text"
                variant="standard"
                error={(enableValidation) ? (errorRegistration.fname !== "") ? true : false : false}
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}
                helperText={errorRegistration.fname}
            />
            <TextField
                required
                margin="dense"
                id="lname"
                label="Last Name"
                name="lname"
                fullWidth
                type="text"
                variant="standard"
                error={(enableValidation) ? (errorRegistration.lname !== "") ? true : false : false}
                helperText={errorRegistration.lname}    
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}    
            />

            <TextField
                required
                margin="dense"
                id="email"
                label="Email Address"
                name="email"
                fullWidth
                type="email"
                variant="standard"
                error={(enableValidation) ? (errorRegistration.email !== "") ? true : false : false}
                helperText={errorRegistration.email}
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}
                onBlur={onRegistrationValidationFocusOut}
            />

            <TextField
                required
                margin="dense"
                id="username"
                label="Benutzername"
                name="username"
                fullWidth
                autoComplete="off"
                type="text"
                variant="standard"
                error={(enableValidation) ? (errorRegistration.username !== "") ? true : false : false}
                helperText={errorRegistration.username}
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}
                onBlur={onRegistrationValidationFocusOut}
            />

            <TextField
                required
                margin="dense"
                id="password"
                label="Passwort"
                name="password"
                fullWidth
                autoComplete="off"
                variant="standard"
                error={(enableValidation) ?(errorRegistration.password !== "") ? true : false : false}
                helperText={errorRegistration.password}
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}
                onBlur={onRegistrationValidationFocusOut}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                }}
            />

            <TextField
                id="role"
                select
                name="role"
                margin="dense"
                label="Role"
                defaultValue=""
                variant="standard"
                fullWidth
                error={(enableValidation) ? (errorRegistration.role !== "") ? true : false : false}
                helperText={errorRegistration.role}
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}
                onBlur={onRegistrationValidationFocusOut}
            >
                {listAllUserRoles.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.role_name}
                    </MenuItem>
                ))}
            </TextField>
            
            <Box sx={{ mt: 2, mb: 2 }}>
                <Button autoFocus variant="contained" color="primary" onClick={onRegistration} disabled={registerButtonDisabled} >
                    Insert
                </Button>
            </Box>

            <Controls.Alert 
                severity={alertRegistration.severity} 
                appear={alertRegistration.appear} 
                text={alertRegistration.text} 
                sx={{ marginTop: "10px" }} 
            />
        </>
    );
}

export default InsertUserForm;
import MuiDrawer from '../../components/MuiDrawer';
import ActivityLog from './ActivityLog';

export default function ActivityLog_Main() {

  return (
            <MuiDrawer>
                <ActivityLog />
            </MuiDrawer>
          );
}
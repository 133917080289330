import MuiDrawer from '../../../../../../components/MuiDrawer';
import EBM_Description_Viewer from './EBM_Description_Viewer';

export default function EBM_Description_Viewer_Main() {

  return (
            <MuiDrawer>
                <EBM_Description_Viewer />
            </MuiDrawer>
          );
}
import React from 'react';
import { TextField } from '@mui/material';

const ReadOnlyTextField = ({ value, ...props }) => {
    const defaultStyles = {
        fontSize: '14px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
        },
    };

    const defaultInputProps = {
        style: {
            padding: '3px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    };

    return (
        <TextField
            value={value}
            size="small"
            fullWidth
            InputProps={{
                readOnly: true,
                sx: { ...defaultStyles, ...(props.sx || {}) },
            }}
            inputProps={{
                ...defaultInputProps,
                ...(props.inputProps || {}),
            }}
            {...props}
        />
    );
};

export default ReadOnlyTextField;
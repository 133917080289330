export const getAnzahlFromMapping = (ziffer, factorMapping, defaultAnzahl, mappingTitle) => {
    // Guard clause for missing or invalid inputs
    if (!ziffer || !Array.isArray(factorMapping)) {
        return defaultAnzahl;
    }

    // Filter for the specific mapping title first
    const mappingSection = factorMapping.find(mapping => mapping.title === mappingTitle);
    if (!mappingSection || !Array.isArray(mappingSection.list)) {
        return defaultAnzahl;
    }

    // Search through the specific mapping's list
    const mappedItem = mappingSection.list.find(item => item && item.ziffer === ziffer);

    // Get the anzahl or default
    return mappedItem?.anzahl || defaultAnzahl;
};
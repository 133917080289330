import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Typography, Tooltip, Button, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StorageIcon from '@mui/icons-material/Storage';
import SearchInput from '../../../components/SearchInput';

const EnhancedTableToolbar = (props) => {
    const { numSelected, onDelete, onCONtoCSV, setSearch, setPage, search, tableTitle } = props;
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                paddingRight: '16px!important',
                display: { xs: 'block', sm: 'flex' },
                paddingTop: { xs: '8px', sm: '0' },
                paddingBottom: { xs: '14px', sm: '0' },
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    { tableTitle }
                </Typography>
            )}
            {numSelected > 0 ? (
                <>
                    <Tooltip title="Delete">
                        <Button color="error" variant="contained" onClick={onDelete}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </>
            ) : (
                <SearchInput setSearch={setSearch} setPage={setPage} search={search} />
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    setSearch: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
};

export default EnhancedTableToolbar;
import React, { useEffect, useState } from 'react';
import * as DatabaseService from '../../services/DatabaseService';
import Table_Section from './output/Table_Section';
import DialogOutput from '../../components/DialogOutput';
import ViewPDFFiles from '../SubmitFiles/SubmitFiles';
import { colorName } from '../../components/colors/Colors';
import InsertUserForm from './components/InsertUserForm';
import UpdateUserForm from './components/UpdateUserForm';

const colorDescriptions = [
    { color: colorName.yellow, description: 'Mehr als 2 Kassensitze*' },
];

export default function UsersPage() {

    const [listJSONData, setJSONData] = useState([]); //List user data
    const [listAllUserRoles, setUserRolesListData] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalRowsCount, setTotalRowsCount] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [loading, setLoading] = useState(false); 
    const [search, setSearch] = React.useState("");
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [dense, setDense] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [user_id_view_pdf, setUserIDViewPDF] = useState({});
    const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "", fullScreen: false });
    const [roleFilter, setRoleFilter] = useState("");

    // Fetch users data and update the state
    const getUsersData = async (page, rowsPerPage, search, roleFilter) => {
        try {
            const usersData = await DatabaseService.getAllUsersData((page + 1), rowsPerPage, search, false, false, false, roleFilter);
            
            if (usersData && usersData.rows) {
                setJSONData(usersData.rows); // Set the user data array to listJSONData
            }

            if (usersData && usersData.totalCount) {
                setTotalRowsCount(usersData.totalCount); // Set the user data array to listJSONData
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    const getUsersRolesData = async () => {
        try {

            const userRolesData = await DatabaseService.getAllUsersRolesData();

            if (userRolesData && userRolesData.rows) {
                setUserRolesListData(userRolesData.rows);
            }
            
        } catch (error) {
            console.error('Error fetching user roles data:', error);
        }
    }

    useEffect(() => {

        setLoading(true); // Set loading to true when fetching data

        getUsersData(page, rowsPerPage, search, roleFilter)
            .then(() => setLoading(false))
            .catch(error => {   
                console.error('Error fetching user data:', error);
                setLoading(false);
            });

    }, [page, rowsPerPage, search, roleFilter]);

    useEffect(() => {
        const updatedRows = listJSONData.map(item => {
            let rowColor = '';
            if (item.profile?.insuranceSeats === 'moreThanTwo') {
                rowColor = colorDescriptions[0].color;
            }
    
            return {
                id: item.id,
                customer_id: item.customerId,
                username: item.username,
                email: item.email,
                fname: item.fname,
                lname: item.lname,
                roleId: item.role.id,
                roleName: item.role.roleName,
                rowColor: rowColor
            };
        });
    
        setRows(updatedRows);
    
    }, [listJSONData]); // Watch for changes in listJSONData

    useEffect(() => {
        getUsersRolesData();
    }, []);

    const handleRoleFilterChange = (event) => {
        const value = event.target.value === "Alle" ? "" : event.target.value;
        setRoleFilter(value);
        setPage(0);
    };

    //POST Database Service
    function delUser_func(data) {
        return DatabaseService.delUserData(data)
    }

    function insertUserData_func(data) {
        return DatabaseService.insertUserData(data)
    }

    function updateUserData_func(id, data) {
        return DatabaseService.updateUserData(id, data)
    }
    //END of POST Database Service

    const onSuccess = () => {
        getUsersData(page, rowsPerPage, search, roleFilter);
        setSelected([]);
    };

    const onDelete = (event) => {
        
        setDialogInformation({ information: "Löschen...", status: "loading" });
        setOpen(true);

        delUser_func(selected)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to delete user');
                }
            })
            .then(result => {
                console.log(result);
                setOpen(false);

                // Update table data after successful deletion
                if (typeof onSuccess === 'function') {
                    onSuccess();
                }
            })
            .catch(error => {
                console.error('Error deleting user:', error);
                setOpen(false);
                // Handle error here, show message or take appropriate action
            });
    };
    
    /* Dialog Box */
    const handleUpdate = (rowData) => {
        const user = listJSONData.find(user => user.id === rowData.id);
        setDialogInformation({ title: "Benutzer aktualisieren", information: user, status: "view", action: "update" });
        setOpen(true);
    }

    const handleAddUser = (event) => {
        setDialogInformation({ title: "Benutzer hinzufügen", information: "Test", status: "view", action: "insert" });
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };
    /* Dialog Box */

    const handleViewPDFFile = (user_id) => {
        setUserIDViewPDF(user_id);
        setDialogInformation({ title: "PDF-Dateien", status: "view", action: "view_pdf", fullScreen: true });
        setOpen(true);
    } 

    const renderDialogContent = () => {
        if (dialogData.status !== "view") return null;
    
        switch (dialogData.action) {
            case "insert":
                return (
                    <>
                        <InsertUserForm 
                            listAllUserRoles={listAllUserRoles}
                            insertUserData_func={insertUserData_func}
                            onSuccess={onSuccess}
                            setOpen={setOpen}
                        />
                    </>
                );
            case "update":
                return (
                    <>
                        <UpdateUserForm 
                            user={dialogData.information}
                            listAllUserRoles={listAllUserRoles}
                            updateUserData_func={updateUserData_func}
                            onSuccess={onSuccess}
                            setOpen={setOpen}
                        />
                    </>
                );
            case "view_pdf":
                return (
                    <>
                        <ViewPDFFiles paramater_user_id={user_id_view_pdf} />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>     
            <br></br>
            <Table_Section 
                tableTitle="Benutzer"
                loading={loading}
                rows={rows}
                order={order}
                orderBy={orderBy}
                selected={selected}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRowsCount={totalRowsCount}
                dense={dense}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setSelected={setSelected}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setDense={setDense}
                onDelete={onDelete}
                setSearch={setSearch}
                search={search}
                handleViewPDFFile={handleViewPDFFile}
                handleUpdate={handleUpdate}
                noResultsMessage="Keine Benutzer gefunden"
                listAllUserRoles={listAllUserRoles}
                roleFilter={roleFilter}
                handleRoleFilterChange={handleRoleFilterChange}
            />

            {
                dialogData.status === "loading" || dialogData.status === "view" ? (
                    <DialogOutput
                        fullScreen={ (dialogData.fullScreen) ? true : false }
                        open={open}
                        dialogData={dialogData}
                        handleClose={handleClose}
                        backgroundColor={ (dialogData.fullScreen) ? colorName.gray : undefined }
                        output={renderDialogContent()}
                    />
                ) : null
            }
        </>
    );
}
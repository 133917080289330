import React, { useState, useEffect } from 'react';
import * as DatabaseService from '../../../../../../services/DatabaseService'
import { useParams, useLocation } from 'react-router-dom';
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
    CircularProgress,
    Snackbar,
    Alert,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel
} from '@mui/material';

const EBM_Description_Editor = (props) => {

    const { zifferValue, onSave } = props;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    //const ziffer = (zifferValue) ? zifferValue : (queryParams?.get('ziffer') || '');
    const ziffer = zifferValue;
    const [loading, setLoading] = useState(true);
    const [input_rows, setInput_rows] = useState([]);
    const [header_description_rows, setHeaderDescriptionRows] = useState([]);
  
    const [errors, setErrors] = useState({
            gop: false
    });
    
    const [EBMDescriptionData, setEBMDescriptionData] = useState({
        gop: '',
        title: '',
        description: '',
        header_no: ''
    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const getEBMDescription = async (ziffer) => {
        try {
            const JSONData = await DatabaseService.getEBMDescription(1, 1, '', ziffer);
            if (ziffer && JSONData && JSONData.rows && JSONData.rows.length > 0) {
                const row = JSONData.rows[0];
                setEBMDescriptionData({
                    gop: row.gop || '',
                    title: row.title || '',
                    description: row.description || '',
                    header_no: row.header_no?.toString() || ''
                });
            } else if (ziffer) {
                // Initialize with provided ziffer when no database entry exists
                setEBMDescriptionData({
                    gop: ziffer,
                    title: '',
                    description: '',
                    header_no: ''
                });
            } else {
                // Reset form data for new entry
                setEBMDescriptionData({
                    gop: '',
                    title: '',
                    description: '',
                    header_no: ''
                });
            }

            if (JSONData && JSONData.headerRows && JSONData.headerRows.length > 0) {
                setHeaderDescriptionRows(JSONData.headerRows);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
            getEBMDescription(ziffer); // Fetch initial data
    }, [ziffer]); 

    const handleChange = (field) => (event) => {
        setEBMDescriptionData(prev => ({
        ...prev,
        [field]: event.target.value
        }));
    };

    const handleSave = async () => {
        try {

            // Validate inputs
            const newErrors = {
                gop: !EBMDescriptionData.gop
            };
            setErrors(newErrors);

            // Validate required fields
            if (!EBMDescriptionData.gop) {
                setSnackbar({
                    open: true,
                    message: 'Bitte alle erforderlichen Felder ausfüllen',
                    severity: 'error'
                });
                return;
            }

            // Prepare update data
            const updates = [{
                gop: EBMDescriptionData.gop,
                new_gop: EBMDescriptionData.gop,
                title: EBMDescriptionData.title,
                description: EBMDescriptionData.description,
                header_no: EBMDescriptionData.header_no
            }];

            // Call update service
            const result = await DatabaseService.updateEBMDescription(updates);

            // Handle response
            setSnackbar({
                open: true,
                message: result.success ? 'Änderungen erfolgreich gespeichert' : result.message,
                severity: result.success ? 'success' : 'error'
            });

            if (result.success) {
                onSave?.();  // Call the callback if it exists
            }

        } catch (error) {
            console.error('Error updating EBM description:', error);
            setSnackbar({
                open: true,
                message: 'Fehler beim Speichern der Änderungen',
                severity: 'error'
            });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
            {(ziffer === '') ? 'Add' : 'Edit' } Ziffer Description
            </Typography>
            
            <TextField
                fullWidth
                label="Ziffer"
                margin="normal"
                value={EBMDescriptionData.gop}
                onChange={handleChange('gop')}
                error={errors.gop}
                disabled={!!ziffer} // Only disable if ziffer has a value
                required
            />

            <TextField
                fullWidth
                label="Title"
                value={EBMDescriptionData.title}
                onChange={handleChange('title')}
                margin="normal"
                multiline
                rows={2}
            />

            <TextField
                fullWidth
                label="Description"
                value={EBMDescriptionData.description}
                onChange={handleChange('description')}
                margin="normal"
                multiline
                minRows={4}
                maxRows={Infinity}
            />

            <FormControl component="fieldset" sx={{ mt: 3, width: '100%' }}>
                <FormLabel component="legend">Header Description</FormLabel>
                <RadioGroup
                    value={EBMDescriptionData.header_no}
                    onChange={handleChange('header_no')}
                >
                <Box 
                    component="ul" 
                    sx={{ 
                        listStyle: 'none', 
                        p: 0,
                        m: 0,
                        border: '1px solid #e0e0e0',
                        borderRadius: 1,
                    }}
                >
                    {header_description_rows.map((header) => (
                    <Box
                        component="li"
                        key={header.id}
                        onClick={(e) => {
                            e.preventDefault();
                            setEBMDescriptionData(prev => ({
                                ...prev,
                                header_no: prev.header_no === header.id.toString() ? '' : header.id.toString()
                            }));
                        }}
                        sx={{
                            borderBottom: '1px solid #e0e0e0',
                            '&:last-child': {
                                borderBottom: 'none'
                            },
                            cursor: 'pointer'
                        }}
                    >
                        <FormControlLabel
                            value={header.id.toString()}
                            control={<Radio />}
                            label={header.description}
                            sx={{
                                width: '100%',
                                m: 0,
                                py: 1,
                                px: 2,
                                '&:hover': {
                                backgroundColor: '#f5f5f5'
                                },
                                '& .MuiFormControlLabel-label': {
                                fontSize: '0.9rem',
                                ml: 1
                                }
                            }}
                        />
                    </Box>
                    ))}
                </Box>
                </RadioGroup>
            </FormControl>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
                variant="outlined"
                onClick={() => window.history.back()}
            >
                Schließen
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
            >
                Änderungen speichern
            </Button>
            </Box>

        <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbar.severity}
                sx={{ width: '100%' }}
            >
                {snackbar.message}
            </Alert>
        </Snackbar>
        <br/>
        <br/>
        </Box>
    );

};

export default EBM_Description_Editor;
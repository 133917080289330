import { styled } from '@mui/system';

const LoadingTable = styled('div')(({ theme }) =>
    theme.unstable_sx({
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.50)',
        zIndex: 0,
        paddingTop: '2.7em'
    }),
);

export default LoadingTable;
import React, { useState, useEffect } from 'react';
import * as DatabaseService from '../../../../../services/DatabaseService';
import { TextField, Button, Snackbar, Alert, Switch, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';

const UpdateEBMCoefficientForm = (props) => {

    const { kv, onUpdateSuccess, row } = props;

    const [gop, setGop] = useState(row?.gop || '');
    const [median, setMedian] = useState(row?.ebm_koeffizient || '');
    const [includeChart, setIncludeChart] = useState(row?.include_chart === 1);
    const [hide, setHide] = useState(row?.hide === true);
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    
    const handleAlertClose = () => {
        setAlert(prev => ({ ...prev, open: false }));
    };

    const [errors, setErrors] = useState({
        gop: false
    });

    // Update form when row prop changes
    useEffect(() => {
        if (row) {
            setGop(row.gop || '');
            setMedian(row.ebm_koeffizient || '');
            setIncludeChart(row.include_chart === 1);
            setHide(row.hide === true);
        }
    }, [row]);

    useEffect(() => {
        let timeoutId;
        if (alert.open) {
            timeoutId = setTimeout(() => {
                handleAlertClose();
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [alert.open]);

    const handleGopChange = (e) => {
        const value = e.target.value;
        const regex = /^[a-zA-Z0-9]*$/; // Only allow letters and numbers
        if (regex.test(value)) {
            setGop(value);
        }
    };

    const handleMedianChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9]*[,.]?[0-9]*$/; // Only allow numbers, comma, and dot

        if (regex.test(value)) {
            // Store the German format (with comma) in the UI
            const germanFormat = value.replace(/\./g, ',');
            setMedian(germanFormat);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate inputs
        const newErrors = {
            gop: !gop
        };
        setErrors(newErrors);

        if (newErrors.gop || newErrors.median) {
            setAlert({
                open: true,
                message: 'Bitte alle erforderlichen Felder ausfüllen',
                severity: 'error'
            });
            return;
        }

        const updates = [{
            id: row.id,
            median: median,
            state_no: kv,
            gop: gop,
            new_gop: gop || row.gop,
            include_chart: includeChart ? 1 : '',
            hide: hide
        }];

        try {
            const result = await DatabaseService.updateEBMCoefficient(updates);
            if (result.success) {
                setAlert({
                    open: true,
                    message: 'Update erfolgreich',
                    severity: 'success'
                });
                
                if (onUpdateSuccess) {
                    setTimeout(() => {
                        // Ensure we pass all necessary fields in the correct format
                        const updatedRow = {
                            ...row,
                            id: row.id,
                            gop: gop,
                            ebm_koeffizient: median, // Keep German format for display
                            include_chart: includeChart ? 1 : '',
                            hide: hide,
                            state_no: kv
                        };
                        onUpdateSuccess(updatedRow);    
                    }, 1000)
                }
            } else {
                throw new Error(result.message || 'Update fehlgeschlagen');
            }
        } catch (error) {
            console.error('Error updating coefficient:', error);
            setAlert({
                open: true,
                message: 'Fehler beim Aktualisieren des Koeffizienten',
                severity: 'error'
            });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex' }}>
                <TextField
                    label="Ziffer"
                    value={gop}
                    onChange={handleGopChange}
                    error={errors.gop}
                    margin="normal"
                    size="small"
                    disabled
                />
                <Box sx={{ width: '10px' }} />
                <TextField
                    label="EBM Koeffizient"
                    value={median}
                    onChange={handleMedianChange}
                    margin="normal"
                    size="small"
                />
            </Box>
            <FormControlLabel
                control={
                    <Switch
                        checked={includeChart}
                        onChange={(e) => setIncludeChart(e.target.checked)}
                    />
                }
                label="Freie Leistung"
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={hide}
                        onChange={(e) => setHide(e.target.checked)}
                    />
                }
                label="Ziffer verbergen"
            />
            <Box sx={{ mt: '8px' }} />
            <Button type="submit" variant="contained" color="primary">
                Aktualisieren
            </Button>
            <Box sx={{ mt: 2 }}>
                {alert.open && (
                    <Alert
                        onClose={handleAlertClose}
                        severity={alert.severity}
                        sx={{ width: '100%' }}
                    >
                        {alert.message}
                    </Alert>
                )}
            </Box>
        </form>
    );
};

export default UpdateEBMCoefficientForm;
import { apiCall } from '../../../apiHelpers/apiCall';

/**
 * Fetches EBM descriptions from the server
 * @returns {Promise<Object>} Object containing success status, message, and data array of descriptions
 * @throws {Error} If the request fails
 */
export const getEBMDescription = async (page, rowsPerPage, search, gop='') => {
    try {
        const response = await apiCall(`/api/ebm_get_description?page=${page}&perPage=${rowsPerPage}&s=${search}&gop=${gop}`);
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error in getEBMDescription:', error.message);
        throw error;
    }
};
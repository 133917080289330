import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import hexToRgb from '../../../../utils/hexToRgb';
import GynsuccessLogo from '../../../../components/assets/images/gynsuccess-logo.jpg';

/**
 * Captures a DOM element as an image using html2canvas
 * @param {string} elementId - ID of the DOM element to capture
 * @param {Object} options - Additional options for html2canvas
 * @returns {Promise<string>} - Promise resolving to image data URL
 */
export const captureElementAsImage = (elementId, options = {}) => {
    const chartElement = document.getElementById(elementId);
    if (!chartElement) {
        console.error(`Element with ID "${elementId}" not found`);
        return Promise.resolve(null);
    }
    
    return html2canvas(chartElement, {
        backgroundColor: null,
        scale: 2, // Higher scale for better quality
        useCORS: true,
        logging: false, // Disable logging for production
        ...options
    }).then(canvas => {
        return canvas.toDataURL('image/png');
    }).catch(error => {
        console.error('Error capturing element:', error);
        return null;
    });
};

/**
 * Generates a PDF report for EBM Abrechnungskompass
 * @param {Object} data - The data needed to generate the PDF
 * @param {Object} data.tableData - The table data structure
 * @param {string} data.chartImgData - Image data URL for the chart
 * @param {string} data.displayInfoOutput - Display information text
 * @param {Object} data.colorName - Object containing color definitions
 * @param {Function} onProgress - Optional callback for progress updates
 * @param {Function} onComplete - Optional callback when PDF generation is complete
 * @returns {Promise<Blob>} - Promise resolving to PDF blob
 */
export const generateEBMPdf = async ({
    tableData,
    chartImgData,
    displayInfoOutput,
    colorName,
    currentNote,
    setLoadingCapture
}, onProgress, onComplete) => {
    if (!tableData?.tables?.[0]) {
        throw new Error('No table data available');
    }
    
    try {
        // Initialize PDF with correct format
        const pdf = new jsPDF('p', 'mm', 'a4');
        
        const position = 20;
        const today = new Date();
        const date = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
        
        // Title and Logo - EBM Abrechnungskompass
        const logoWidth = 56; // Width of the logo in mm
        const logoHeight = 10; // Height of the logo in mm

        // Add white background rectangle for the logo area
        pdf.setFillColor(255, 255, 255);
        pdf.rect(14, position - 5, logoWidth, logoHeight, 'F');

        // Add the logo image
        pdf.addImage(GynsuccessLogo, 'PNG', 14, position - 5, logoWidth, logoHeight);

        // Set title after the logo
        pdf.setFontSize(16);
        const pageWidth = pdf.internal.pageSize.getWidth();
        pdf.text('EBM Abrechnungskompass', pageWidth - 14, position + 2.5, { align: 'right' });

        // Display info - third line with more spacing
        pdf.setFontSize(10);
        // Add text splitting to handle long lines properly
        const infoLines = pdf.splitTextToSize(displayInfoOutput, 180); // 180mm width (A4 width with margins)
        pdf.text(infoLines, 14, position + 16);

        // Calculate starting position for tables based on number of lines in info text
        const infoLinesCount = infoLines.length;
        let yPosition = position + 20 + (infoLinesCount * 4); // Add 4mm per line of text
        
        // Add chart image if available
        if (chartImgData) {
            // Calculate dimensions to fit chart on page
            const imgWidth = pdf.internal.pageSize.getWidth() - 28; // 14mm margin on each side
            const imgHeight = 100; // Fixed height or calculate based on aspect ratio
            
            // Add chart to PDF
            pdf.addImage(chartImgData, 'PNG', 14, yPosition, imgWidth, imgHeight);
            
            // Update position for tables
            yPosition += imgHeight + 15; // 15mm spacing after chart
        }

        // Process each table in the tableData array
        tableData.tables.forEach((table, tableIndex) => {
            // Add table title if available
            if (table.title) {
                pdf.setFontSize(10);
                pdf.text(table.title, 14, yPosition);
                yPosition += 8;
            }
            
            // Format table data
            let processedData = [];
            
            // Process sections for this table
            table.sections.forEach(section => {
                // Add section header with styling
                processedData.push([{
                    content: section.header.replace(/<br\s*\/?>/g, '\n'),
                    colSpan: tableIndex === 0 ? 8 : 7,
                    styles: {
                        fontStyle: 'bold',
                        fillColor: hexToRgb(colorName.lightgreen2),
                        cellPadding: 3,
                        cellWidth: 'wrap',   // Ensure text wrapping
                        overflow: 'linebreak', // Enable line breaks
                        valign: 'middle',    // Vertical alignment
                        halign: 'left'       // Horizontal alignment
                    }
                }]);
                
                // Add section rows
                section.rows.forEach(row => {
                    if (tableIndex === 0) {
                        // First table: EBM-Ziffern der freien Leistungen (8 columns)
                        processedData.push([
                            row.gop || '',
                            row.title || '',
                            row.anzahl?.toString() || '',
                            row.ideal_number_of_treatments?.toString() || '',
                            row.deviation?.toString() || '',
                            row.deviation_in_percent?.toString() || '',
                            row.gesamtpotenzial?.toString() || '',
                            row.ihr_potenzial?.toString() || '',
                            row.backgroundColorRow || ''
                        ]);
                    } else {
                        // Second table: Gesamttabelle (7 columns)
                        processedData.push([
                            row.gop || '',
                            row.title || '',
                            row.anzahl?.toString() || '',
                            row.ideal_number_of_treatments?.toString() || '',
                            row.deviation?.toString() || '',
                            row.deviation_in_percent?.toString() || '',
                            row.outputDeviationInPercentStatus || '',
                            row.backgroundColorRow || '',
                            row.colorTextRow || ''
                        ]);
                    }
                });
            });
            
            // Generate this table using autoTable
            autoTable(pdf, {
                startY: yPosition,
                head: table.columns ? [table.columns] : undefined,
                body: processedData,
                theme: 'grid',
                showHead: 'firstPage', // Only show header on the first page of each table
                styles: {
                    fontSize: 8,
                    cellPadding: 2,
                    lineWidth: 0.1,
                    lineColor: [80, 80, 80],
                    overflow: 'linebreak'
                },
                headStyles: {
                    fillColor: hexToRgb(colorName.green),
                    textColor: [255, 255, 255],
                    fontStyle: 'bold',
                    fontSize: 7
                },
                columnStyles: tableIndex === 0 ? {
                    0: { cellWidth: 18 },
                    1: { cellWidth: 35 },
                    2: { cellWidth: 15, halign: 'right' },
                    3: { cellWidth: 14, halign: 'right' },
                    4: { cellWidth: 22, halign: 'right' },
                    5: { cellWidth: 24, halign: 'right' },
                    6: { cellWidth: 29, halign: 'right' },
                    7: { cellWidth: 24, halign: 'right' }
                }:{
                    0: { cellWidth: 18 },
                    1: { cellWidth: 35 },
                    2: { cellWidth: 15, halign: 'right' },
                    3: { cellWidth: 14, halign: 'right' },
                    4: { cellWidth: 22, halign: 'right' },
                    5: { cellWidth: 24, halign: 'right' },
                    6: { cellWidth: 53, halign: 'right' }
                },
                didParseCell: function(data) {
                    // Cell formatting logic from original code
                    // Format cells, apply colors, etc.
                    // ...same as in original code
                    
                    // Format cells
                    if (data.column.index === 5) {
                        if (data.cell.text && data.cell.text.length > 0) {
                            data.cell.text = data.cell.text.map(text => text ? text : '');
                        }
                    }
                    if (data.column.index === 6 || data.column.index === 7) {
                        if (data.cell.text && data.cell.text.length > 0) {
                            data.cell.text = data.cell.text.map(text => text ? text : '');
                        }
                    }

                    // Apply background color for all tables
                    if (data.section === 'body') {
                        const rowData = processedData[data.row.index];
                        const bgColorIndex = tableIndex === 0 ? 8 : 7;
                        const textColorIndex = tableIndex === 0 ? null : 8;
                        
                        if (rowData && rowData[bgColorIndex]) {
                            try {
                                if (rowData[bgColorIndex].startsWith('#')) {
                                    data.cell.styles.fillColor = hexToRgb(rowData[bgColorIndex]);
                                    
                                    if (tableIndex === 0) {
                                        data.cell.styles.textColor = [0, 0, 0];
                                    } 
                                    else if (textColorIndex !== null && rowData[textColorIndex]) {
                                        if (rowData[textColorIndex].startsWith('#')) {
                                            data.cell.styles.textColor = hexToRgb(rowData[textColorIndex]);
                                        } else if (rowData[textColorIndex] === 'inherit') {
                                            data.cell.styles.textColor = [0, 0, 0];
                                        } else {
                                            data.cell.styles.textColor = [0, 0, 0];
                                        }
                                    } else {
                                        data.cell.styles.textColor = [0, 0, 0];
                                    }
                                }
                            } catch (error) {
                                console.error('Error applying background color:', error);
                                data.cell.styles.textColor = [0, 0, 0];
                            }
                        }
                    }

                    // Hide the backgroundColor and textColor columns
                    if ((tableIndex === 0 && data.column.index === 8) || 
                        (tableIndex === 1 && data.column.index === 7) ||
                        (tableIndex === 1 && data.column.index === 8)) {
                        data.cell.text = '';
                    }
                }
            });

            // Get the final Y position after this table was created
            yPosition = pdf.lastAutoTable.finalY + 15;

            // Add page break between tables if needed
            if (tableIndex < tableData.tables.length - 1) {
                if (yPosition > pdf.internal.pageSize.height - 40) {
                    pdf.addPage();
                    yPosition = 20;
                }
            }
        });
        
        if (currentNote && currentNote.length > 0) {
            pdf.setFontSize(10);
            pdf.setTextColor(0, 0, 0); // Set text color to default (black)
            
            const note = pdf.splitTextToSize(currentNote, 180); // Split comment text to fit within margins
            pdf.text('Hinweise:', 14, yPosition); // Add "Notiz:" label
            yPosition += 6; // Adjust position for the comment text
            pdf.text(note, 14, yPosition); // Add the comment text
            yPosition += note.length * 5; // Adjust Y position based on the number of lines in the comment
        }

        const addPageNumbers = () => {
            const totalPages = pdf.internal.getNumberOfPages();
            
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(8);
                pdf.text(
                    `Seite ${i} von ${totalPages}`,
                    14, // Position at left margin (14mm from left edge)
                    pdf.internal.pageSize.height - 10, // 10mm from bottom
                    { align: 'left' }
                );
            }
        };

        addPageNumbers();

        // Return the PDF as blob for flexible handling
        const pdfBlob = pdf.output('blob');
        const outputFilename = `EBM_Abrechnungskompass_${date}.pdf`;

        // Create the result object with methods
        const result = {
            blob: pdfBlob,
            filename: outputFilename,
            preview: () => {
                previewPdf(pdfBlob);
                // Set loading to false after preview is opened
                if (setLoadingCapture) setLoadingCapture(false);
            },
            download: () => {
                
                // Set loading to false after download is triggered
                if (setLoadingCapture) setLoadingCapture(false);
                
                setTimeout(() => {
                    savePdf(pdfBlob, outputFilename);
                }, 1000);
            }
        };

        if (onComplete) {
            onComplete(result.blob, result.filename);
        }
        
        return result;
        
    } catch (error) {
        console.error('Error generating PDF:', error);
        throw error;
    }
};

/**
 * Helper function to preview the generated PDF in a new browser tab
 * @param {Blob} pdfBlob - The PDF blob
 */
const previewPdf = (pdfBlob) => {
    const blobUrl = URL.createObjectURL(pdfBlob);
    window.open(blobUrl, '_blank');
    // Don't immediately revoke the URL as it's needed for the preview
    // The browser will clean it up when the tab is closed
};

/**
 * Helper function to save the generated PDF
 * @param {Blob} pdfBlob - The PDF blob
 * @param {string} filename - The filename to save as
 */
const savePdf = (pdfBlob, filename) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
};
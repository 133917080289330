/**
 * Gets the factor value for a given ziffer, checking multiple sources
 * @param {string} ziffer - The billing code to look up
 * @param {Array} rows - Array of billing data rows
 * @param {string} defaultFaktor - Default factor to use if no mapping found
 * @param {Array} factorMapping - Optional array of factor mappings
 * @returns {string} The factor value in German number format (e.g. "3,5")
 */
export const getFactorFromMapping = (ziffer, rows, defaultFaktor, factorMapping) => {
    // Guard clause for missing ziffer
    if (!ziffer) {
        return defaultFaktor;
    }

    // First check factorMapping if provided (give it priority)
    if (Array.isArray(factorMapping) && factorMapping.length > 0) {
        // First try to find in structured mapping (with list)
        const mappedItems = factorMapping
            .filter(m => m && Array.isArray(m.list))
            .flatMap(m => m.list);
            
        const mappedItem = mappedItems.find(item => item?.ziffer === ziffer);
        
        if (mappedItem?.pre_assigned_factor) {
            return mappedItem.pre_assigned_factor;
        }
    
        // If not found in structured mapping, check direct pre_assigned_factor
        const directMapping = factorMapping.find(item => item?.ziffer === ziffer);
        if (directMapping?.pre_assigned_factor) {
            return directMapping.pre_assigned_factor;
        }
    }

    // Then check rows for pre_assigned_factor if rows is available
    if (Array.isArray(rows)) {
        const rowMapping = rows.find(item => item?.ziffer === ziffer);
        if (rowMapping?.pre_assigned_factor) {
            return rowMapping.pre_assigned_factor;
        }
    }

    // Fall back to default factor
    return defaultFaktor;
};
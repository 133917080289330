import React, { useState, useEffect, useRef } from 'react';
import * as DatabaseService from '../../../../services/DatabaseService';
import { TextField, Box, Paper, Typography, Alert, Button } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

const EBM_Result_Notes = (props) => {

    const { abrechnungskompassCSV_ID, readOnlyNotes } = props;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState('');
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    
    const handleAlertClose = () => {
        setAlert(prev => ({ ...prev, open: false }));
    };

    const textFieldRef = useRef(null);

    const getAbrechnungskompassInputOutputCSVData = async (abrechnungskompassCSV_ID) => {

        try {
            const JSONData = await DatabaseService.getAbrechnungskompassInputOutputCSVData((page + 1), rowsPerPage, search, abrechnungskompassCSV_ID);
            if (JSONData && JSONData.rows) {
                setNotes(JSONData.rows[0]?.note || '');
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {

        setLoading(true); // Set loading to true when fetching data

        getAbrechnungskompassInputOutputCSVData(abrechnungskompassCSV_ID)
            .then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });

    }, [abrechnungskompassCSV_ID]);

    useEffect(() => {
        let timeoutId;
        if (alert.open) {
            timeoutId = setTimeout(() => {
                handleAlertClose();
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [alert.open]);

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };

    // Use a fixed height instead of dynamic resizing to prevent ResizeObserver loops
    useEffect(() => {
        // Add event listener cleanup to prevent memory leaks
        return () => {
            // Cleanup any potential observers
            if (textFieldRef.current && textFieldRef.current._resizeObserver) {
                textFieldRef.current._resizeObserver.disconnect();
            }
        };
    }, []);
    
        const handleSubmit = async (event) => {
            event.preventDefault();
    
            const updates = [{
                id: abrechnungskompassCSV_ID,
                note: notes
            }];
    
            try {
                const result = await DatabaseService.updateAbrechnungskompassCSVNotes(updates);
                if (result.success) {
                    setAlert({
                        open: true,
                        message: 'Notizen erfolgreich aktualisiert',
                        severity: 'success'
                    });
                    
                } else {
                    throw new Error(result.message || 'Update fehlgeschlagen');
                }
            } catch (error) {
                console.error('Error updating notes:', error);
                setAlert({
                    open: true,
                    message: 'Fehler beim Aktualisieren des notes',
                    severity: 'error'
                });
            }
        };

    const textFieldSx = {
        width: { 
            xs: '100%', // mobile
            sm: '100%',  // tablets
            md: '1200px'   // desktop
        },
        flex: 1, // Use flex to expand to available width
        height: '100%',
        '& .MuiInputBase-root': {
            height: '100%',
            // Make disabled text color black instead of the default gray
            color: 'rgba(0, 0, 0, 0.87)', // Regular text color regardless of disabled state
            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.87)', // Needed for webkit browsers
        },
        '& .MuiOutlinedInput-root': {
            height: '100%',
            '& fieldset': {
                borderColor: '#e0e0e0',
            },
            '&:hover fieldset': {
                borderColor: '#bbbbbb',
            },
            '&.Mui-disabled': {
                '& fieldset': {
                    borderColor: '#e0e0e0', // Keep border color consistent when disabled
                },
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box 
                sx={{ 
                    display: 'flex',
                    width: 'auto' // Allow auto width
                }}
            >
                {loading && (
                        <Box sx={{
                            position: 'absolute',
                            width: '100%',
                            height: 'calc(100% - 73px)',
                            left: 0,
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1
                        }}>
                            <Box>
                                <CircularProgress size={40} />
                            </Box>
                        </Box>
                    )}

                {
                    (readOnlyNotes) ? <TextField
                        multiline
                        rows={8} // Fixed number of rows instead of dynamic min/max
                        value={notes}
                        placeholder="Hier Notizen eingeben..."
                        variant="outlined"
                        disabled={true}
                        sx={{
                            ...textFieldSx,
                            '& .MuiInputBase-root.Mui-disabled': {
                                color: 'rgba(0, 0, 0, 0.87)',
                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.87)'
                            },
                            '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-input': {
                                color: 'rgba(0, 0, 0, 0.87)',
                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.87)'
                            }
                        }}
                        InputProps={{
                            readOnly: true,
                            style: { color: 'rgba(0, 0, 0, 0.87)' }
                        }}
                     /> : <TextField
                        ref={textFieldRef}
                        multiline
                        rows={8} // Fixed number of rows instead of dynamic min/max
                        value={notes}
                        onChange={handleNotesChange}
                        placeholder="Hier Notizen eingeben..."
                        variant="outlined"
                        disabled={readOnlyNotes}
                        sx={textFieldSx}
                    />
                }
            </Box>
            {!readOnlyNotes && (
                <>
                    <Box sx={{ mt: '8px' }} />
                    <Button type="submit" variant="contained" color="primary">
                        Aktualisieren
                    </Button>
                </>
            )}
            <Box sx={{ mt: 2 }}>
                {alert.open && (
                    <Alert
                        onClose={handleAlertClose}
                        severity={alert.severity}
                        sx={{ width: '100%' }}
                    >
                        {alert.message}
                    </Alert>
                )}
            </Box>
        </form>
    );
}

export default EBM_Result_Notes;
import React, { useEffect, useState } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import { Box, Typography, Grid, Paper, Button, Modal, IconButton } from '@mui/material';

import { Card, CardContent, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
//import ReactPlayer from 'react-player';
//import ReactAudioPlayer from 'react-audio-player';

import * as DatabaseService from '../../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';
import { dangerouslySetInnerHTML } from 'react-dom';

import MuiDrawer from '../../../components/MuiDrawer';

import { useAuth } from '../../../contexts/AuthContext';


import GynSuccess_Home_Header from '../../../components/assets/images/template/GynSuccess_Home_Header.webp';
import GynSuccess_Leistungen_Bild2 from '../../../components/assets/images/template/GynSuccess_Leistungen_Bild2.webp';
import GynSuccess_Team_Header from '../../../components/assets/images/template/GynSuccess_Team_Header.webp';
import GynSucces_Backend_2 from '../../../components/assets/images/template/GynSucces_Backend_2.webp';
import GynSuccess_Webinar from '../../../components/assets/images/template/aufzeichnung_1_webinar_startbild.webp';
import GynSuccess_Webinar2 from '../../../components/assets/images/template/aufzeichnung_webinar_2_2024_10_30.webp';
import Gynsuccess_Placeholder from '../../../components/assets/images/template/placeholder.webp';
import Gynsuccess_Podcast from '../../../components/assets/images/template/Gynsuccess_podcast_img.webp';
import Gynsuccess_Blog from '../../../components/assets/images/template/bild0_14_launch_gynsuccess_cust.webp';
import Gynsuccess_Blog1 from '../../../components/assets/images/template/blog_drobermeier1.webp';
import Gynsuccess_Logo from '../../../components/assets/images/template/gynsuccess_logo.jpg';
import Gynsuccess_Podcast_green from '../../../components/assets/images/template/ueberschrift_hinzufuegen.webp';
import Gynsuccess_Webinar3 from '../../../components/assets/images/template/aufzeichnung_adventswebinar_041224.webp';
import Gynsuccess_Webinar4 from '../../../components/assets/images/template/aufzeichnung_webiar_digitalisierungssupport.webp';
import Gynsuccess_Webinar5 from '../../../components/assets/images/template/optimised_webinar-erfolgreiche-praxisfuehrung_2025_02_19.webp';


//import Gynsuccess_webinar_video from '../../../components/assets/videos/Aufzeichnung_1_Webinar.mp4';
//import Gynsuccess_podcast_audio from '../../../components/assets/videos/podcast_75767_gynsuccess_der_pdcst_episode_1581600_die_grunder_stellen_sich_vor.mp3';

import VideoPlayer from '../../../components/VideoPlayer';
import MusicPlayer from '../../../components/MusicPlayer';


const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
  fontSize: '20px',
  padding: '0.3em 1em',
}));

const StyledButtonsmall = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
  fontSize: '14px',
  padding: '0.3em 1em',
  transform: 'translate(20px, 10px)',
}));


const LightTypographyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'BR Firma Light',
  lineHeight: '1.8em',
  marginBottom: 0,
}));

const GynSuccessComponent1 = () => {
  return (
    <Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ color: '#014017' }}
          >
            GynSuccess Mediathek
          </Typography>
          {/*<LightTypographyText variant="p" component="div" sx={{fontWeight: '300'}}>
                            Hier finden Sie eine Übersicht über aktuelle Podcast Episoden, vergangene Webinare sowie einführende Videos in die Plattformnutzung.  Wir versorgen Sie stetig mit neuen Inhalten und Impulsen.
                        </LightTypographyText>*/}
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{ borderRadius: '8px', overflow: 'hidden', display: 'flex' }}
          >
            <img src={GynSucces_Backend_2} alt="" style={{ width: '100%' }} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const GynSuccessComponent3 = (props) => {
  const { title, description, buttonName, buttonLink, bgColor } = props;

  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: bgColor, // Dark green background
    color: 'white',
    borderRadius: '16px',
    padding: theme.spacing(3),
  }));

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ mb: 2, marginBottom: 0 }}>
          <LightTypographyText
            variant="p"
            paragraph
            sx={{
              fontWeight: '300',
              '& div p:last-child': { marginBottom: 0 },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </LightTypographyText>
        </Box>
        {buttonName !== '' ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton variant="contained">{buttonName}</StyledButton>
            </Box>
          </>
        ) : (
          ''
        )}
      </CardContent>
    </StyledCard>
  );
}; /*
        <GynSuccessComponent3
          title="EBM-Abrechnungskompass​"
          description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ​"
          buttonName="Zustieg zum  EBM-Abrechnungstool"
          buttonLink=""
          bgColor="#006400"
        />
    */

const GynSuccessComponent5 = () => {
  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: 'rgba(0,0,0,0.36)',
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    color: 'white',
  }));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} fullWidth>
      <StyledCard>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            Sie haben Fragen zur GynSuccess Plattform? Dann wenden Sie sich
            gerne direkt an uns!
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton variant="contained">Kontakt</StyledButton>
        </Box>
      </StyledCard>
    </Box>
  );
};



const Webinare = [
  {
    id: 5,
    url: Gynsuccess_Webinar5, // Replace with actual image URLs
    //link: Gynsuccess_webinar_video, // URL to navigate to when clicked
    link: '/api/videos/Webinar_GynSuccess_Erfolgreiche_und_gewinnoptimierte_Praxisführung-20250219_190048-Besprechungsaufzeichnung.mp4', // URL to navigate to when clicked
    description: 'Zum Webinar',
    type: 'video', // Add this line

  },
  {
    id: 4,
    url: Gynsuccess_Webinar4, // Replace with actual image URLs
    //link: Gynsuccess_webinar_video, // URL to navigate to when clicked
    link: '/api/videos/1_Webinar_Digitalisierungssupport-20250122_192446-Meeting_Recording.mp4', // URL to navigate to when clicked
    description: 'Zum Webinar',
    type: 'video', // Add this line

  },
  {
  id: 3,
      url: Gynsuccess_Webinar3, // Replace with actual image URLs
      //link: Gynsuccess_webinar_video, // URL to navigate to when clicked
      link: '/api/videos/1_Advents-Webinar_Ihrer_GynSuccess-20241204_193036-Meeting_Recording.mp4', // URL to navigate to when clicked
      description: 'Zum Webinar',
      type: 'video', // Add this line
  },
  {
    id: 2,
      url: GynSuccess_Webinar2, // Replace with actual image URLs
    //link: Gynsuccess_webinar_video, // URL to navigate to when clicked
    link: '/api/videos/Webinar_Ihrer_GynSuccess-20241030_191031-Meeting_Recording.mp4', // URL to navigate to when clicked
    description: 'Zum Webinar',
    type: 'video', // Add this line

  },
  {
    id: 1,
      url: GynSuccess_Webinar, // Replace with actual image URLs
    //link: Gynsuccess_webinar_video, // URL to navigate to when clicked
    link: '/api/videos/Aufzeichnung_1_Webinar.mp4', // URL to navigate to when clicked
    description: 'Zum Webinar',
    type: 'video', // Add this line

   },
  {
    id: 0,
    url: Gynsuccess_Placeholder,
    link: '#',
    description: 'Kommt in Kürze',
  },

];

const Podcast = [
  {
    id: 1,
    url: Gynsuccess_Podcast,
    //link: Gynsuccess_podcast_audio,

    description: 'Zum Podcast',
    type: 'audio', // Add this line
    episodes:[
      {
      id: 1,
      title:'Die Gründer stellen sich vor Podcast zum Launch von GynSuccess',
      subtitle:' ',
      link: '/api/audios/podcast_75767_gynsuccess_der_pdcst_episode_1581600_die_grunder_stellen_sich_vor.mp3',
      },
      {
        id: 2,
        title:'Erfolg beginnt im Kopf: Wie das richtige Mindset Ihre Praxis auf Erfolgskurs bringt!',
        subtitle:'Innere Haltung - ein Interview mit Frau Prof. Evelyn Albrecht, MBCI',
        link:'/api/audios/podcast_profalbrecht_2025_02_11_final_1.mp3',
      }
    ]

  },
  {
    id: 2,
    url: Gynsuccess_Podcast_green,
    description: 'Zum Podcast',
    heading:'Dr. Burger - Dr. Zuber "ich hab da mal ne Frage"',
    type: 'audio', // Add this line
    episodes:[
      {
      id: 1,
      title: 'Episode 1: Premierenpodcast',
      subtitle:'Dr. Burger - Dr. Zuber "ich hab da mal ne Frage"',
      link: '/api/audios/B&Z-1.mp3',
      },
      {
        id: 2,
        title:'Episode 2: Neu-Anlage Notfalldatensatz EBM 01640',
        subtitle:'Dr. Burger - Dr. Zuber "ich hab da mal ne Frage"',
        link:'/api/audios/B&Z_2_04_12_24_14_50.mp3',
      },
      {
        id: 3,
        title:'Episode 3: Tipps zur Praxisorganisation',
        subtitle:'Wartezeiten - Dokumentation - IGeLeistungen - Patientinnensicherheit information: Dr. Burger und Dr. Zuber greifen das Thema Wartezeit auf - was ist wichtig, um diese relativ kurz zu halten - 20 Minuten, und wie kann diese bestmöglich im Sinne der Praxisoragnisation genutzt werden. Elektronischer Anamnesesbogen, IGeL Flyer und sonst. Informationen für Patientinnen zielführend einbinden. Ferner gibt es Tipps zur Patientinnensicherheit, Dokumentation und warum gerade ein Laufzettel sehr nützlich sein kann. Viel Spaß!',
        link:'/api/audios/B&Z-3-05_02_25_10_44.mp3',
      }
    ]
  },
  {
    id: 3,
    url: Gynsuccess_Placeholder,
    link: '#',
    description: 'Kommt in Kürze',
  },
];
  const Blog = [
    {
      id: 1,
      url: Gynsuccess_Blog1,
      link: '/blog/gehaltsoptimierung-bei-mitarbeiterinnen-und-mitarbeitern-in-ihrer-gynaekologischen-praxis/',
      description: 'Artikel hier lesen',
      type: 'blog',
      },
  {
    id: 2,
    url: Gynsuccess_Blog,
    link: '/blog/launch-von-gynsuccess/',
    description: 'Artikel hier lesen',
    type: 'blog',
  },

  {
    id: 3,
    url: Gynsuccess_Placeholder,
    link: '#',
    description: 'Kommt in Kürze',
  },
];
// Named ImageGrid Component
export const ImageGrid = ({ images }) => {
  const [open, setOpen] = useState(false);
  //const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(Podcast[0]); // Or appropriate initial podcast
  const [error, setError] = useState(null); // State to track errors
  const [currentAudio, setCurrentAudio] = useState(null);

  const handlePlayAudio = () => {
    if (!selectedMedia.link) {
      console.log('No audio available');
      return;
    }

    // Proceed to play the audio or call setCurrentAudio
    setCurrentAudio(selectedMedia.link);
  };


  const handleOpen = (media) => {
    try {
      if (media.type === 'blog' && media.link !== '#') {
        window.location.href = media.link;
      } else if (media.link !== '#') {
        if (media.type === 'audio' && media.episodes && media.episodes.length > 0) {
          setSelectedMedia({
            ...media,
            link: media.episodes[0].link, // Default to the first episode's link
            heading: media.episodes[0].title || media.heading, // Default to first episode's title
            subtitle: media.episodes[0].subtitle || '', // Default to first episode's subtitle
          });
        } else {
          setSelectedMedia(media); // Set media without episodes or other types
        }
        setOpen(true); // Open the modal
      }
    } catch (err) {
      setError('An error occurred while opening the media.');
      console.error(err);
    }
  };



  const handleClose = () => {
    setOpen(false);
    setSelectedMedia(null);
  };

  const handleEpisodeChange = (episode) => {
    try {
      setSelectedMedia((prevState) => ({
        ...prevState,
        link: episode.link,
        heading: episode.title,
        subtitle: episode.subtitle || '',
      }));

      // Ensure playback restarts with the new audio
      setTimeout(() => {
        const musicPlayerElement = document.querySelector('.music-player');
        if (musicPlayerElement) {
          musicPlayerElement.pause(); // Stop current playback
          musicPlayerElement.load(); // Reload the new audio
          musicPlayerElement.play(); // Start playback
        }
      }, 100); // Slight delay to allow DOM update
    } catch (err) {
      setError('An error occurred while changing the episode.');
      console.error(err); // Log error for debugging purposes
    }
  };





  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'scroll',
          '&::-webkit-scrollbar': { height: 8 },
          '&::-webkit-scrollbar-track': { backgroundColor: '#f1f1f1' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: '#888', borderRadius: 4 },
        }}
      >
        {images.map((image) => (
          <Card
            key={image.id}
            sx={{ minWidth: 439, height: 'auto', m: 1, flexShrink: 0, position: 'relative', cursor: 'pointer', width: '40%' }}
            onClick={() => handleOpen(image)}
          >
            <Box
              component="img"
              src={image.url}
              alt={image.description}
              sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <CardContent
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                backgroundColor: 'rgba(255, 255, 255, 0%)',
                transform: 'translate(-19px, 1px)',
              }}
            >
              <StyledButtonsmall variant="contained">
                {image.description}
              </StyledButtonsmall>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Modal open={open} onClose={handleClose}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      zIndex: 9999,
    }}
  >
    <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleClose}>
      <CloseIcon />
    </IconButton>
    {/* Video Player */}
    {selectedMedia && selectedMedia.type === 'video' && (
      <>
        <h2>Webinar Video</h2> {/* Heading for the video */}
        {/*<ReactPlayer
          url={selectedMedia.link}
          width="100%"
          height="400px"
          controls
        />*/}

        <VideoPlayer src={selectedMedia.link} />
      </>
    )}
 {/* Audio Player */}
{selectedMedia && selectedMedia.type === 'audio' && (
  <>

  <Box
    sx={{
      marginBottom: '20px',
      display: 'flex',
      gap:'10px',
      alignItems: 'center',
      '@media (max-width: 600px)': {
        display: 'block', // Change to block on smaller screens
      },
    }}
  >


      <img src={Gynsuccess_Logo} alt="" style={{ width: '50px' }} />
      <div>
        <h2 style={{marginBottom:'0' }}>{selectedMedia.heading}</h2> {/* Display the heading */}
        {selectedMedia.subtitle && <h3 style={{ fontWeight: 'normal', fontSize: '16px', color: '#666', marginTop:'0'}}>{selectedMedia.subtitle}</h3>} {/* Display the subtitle */}
      </div>
      </Box>
{/* Episode List */}
{selectedMedia.episodes && selectedMedia.episodes.length > 0 && (
  <Box
    sx={{
      marginBottom: '20px',
      display: 'flex',
      gap: '20px',
      flexWrap: 'wrap', // Ensures responsiveness
      '@media (max-width: 600px)': {
        display: 'block', // Change to block on smaller screens
      },
    }}
  >
    {selectedMedia.episodes.map((episode) => (
      <Box key={episode.id} sx={{ marginBottom: '10px' }}>
        <StyledButtonsmall variant="contained" onClick={() => handleEpisodeChange(episode)}>
          {episode.title}
        </StyledButtonsmall>
      </Box>
    ))}
  </Box>
)}


    {/* Music Player */}
  <MusicPlayer
    className="music-player"
    src={selectedMedia.link}
    controls
  />
  </>
)}

  </Box>
</Modal>
    </Box>
  );
};

// Named Export of Mediathek Component
// export const Mediathek = () => (
//   <>
//     <ImageGrid images={Webinare} isModalEnabled={true} />
//     <ImageGrid images={Podcast} isModalEnabled={true} />
//     <ImageGrid images={Blog} isModalEnabled={false} />
//   </>
// );

// const ImageGrid = ({ images }) => {
//   return (
//     <Box sx={{ width: '100%', p: 2 }}>
//       <Box
//         sx={{
//           display: 'flex',
//           overflowX: 'auto',
//           '&::-webkit-scrollbar': {
//             height: 8,
//           },
//           '&::-webkit-scrollbar-track': {
//             backgroundColor: '#f1f1f1',
//           },
//           '&::-webkit-scrollbar-thumb': {
//             backgroundColor: '#888',
//             borderRadius: 4,
//           },
//         }}
//       >
//         {images.map((image) => (
//           <Card
//             key={image.id}
//             sx={{
//               minWidth: 439,
//               height: 332,
//               m: 1,
//               flexShrink: 0,
//               position: 'relative',
//             }}
//           >
//             <Box
//               component="img"
//               src={image.url}
//               alt={`Lorem ipsum ${image.id}`}
//               sx={{
//                 width: '100%',
//                 height: '100%',
//                 objectFit: 'cover',
//               }}
//             />
//             <CardContent
//               sx={{
//                 position: 'absolute',
//                 top: 0,
//                 left: 0,
//                 width: '100%',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'flex-end',
//                 alignItems: 'flex-end',
//                 backgroundColor: 'rgba(255, 255, 255, 0%)',
//                 transform: 'translate(-19px, 1px)',
//               }}
//             >
//               <StyledButtonsmall
//             variant="contained"
//             component="a"
//             href={image.link} target="_blank"
//           >
//              {image.description}
//           </StyledButtonsmall>
//             </CardContent>
//           </Card>
//         ))}
//       </Box>
//     </Box>
//   );
// };

const FullWidthSection1 = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F1F2F4',
          paddingTop: '8px',
          paddingBottom: '8px',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '80%',
            margin: 'auto',
            zIndex: '1',
            position: 'relative',
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default function Workshops() {
  const { login, currentUser, currentRole, getSession } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MuiDrawer>
      <Box sx={{ marginLeft: '-24px', marginRight: '-24px' }}>
        <Box sx={{ width: '80%', margin: 'auto' }}>
          <GynSuccessComponent1 />

          <Box sx={{ height: '24px' }}></Box>
        </Box>

        {/* <Box sx={{ width: '80%', margin: 'auto' }}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ color: '#014017' }}
          >
            Informationen zur GynSuccess Nutzung
          </Typography>
        </Box>
        <FullWidthSection1>
          <ImageGrid />
        </FullWidthSection1> */}

        <Box sx={{ height: '24px' }}></Box>

        <Box sx={{ width: '80%', margin: 'auto' }}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ color: '#014017' }}
          >
            Aufgezeichnete GynSuccess Webinare
          </Typography>
        </Box>
        <FullWidthSection1>
        <ImageGrid images={Webinare} />
        </FullWidthSection1>

        <Box sx={{ height: '24px' }}></Box>

        <Box sx={{ width: '80%', margin: 'auto' }}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ color: '#014017' }}
          >
            Aktuelle Podcast-Episoden​
          </Typography>
        </Box>
        <FullWidthSection1>
        <ImageGrid images={Podcast} />
        </FullWidthSection1>

        <Box sx={{ height: '24px' }}></Box>

        <Box sx={{ width: '80%', margin: 'auto' }}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ color: '#014017' }}
          >
            Neuste Blogbeiträge​
          </Typography>
        </Box>
        <FullWidthSection1>
        <ImageGrid images={Blog} />
        </FullWidthSection1>
      </Box>
    </MuiDrawer>
  );
}
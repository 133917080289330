import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as DatabaseService from '../../../../../../services/DatabaseService'
import Table_Section from './output/Table_Section';
import DialogOutput from '../../../../../../components/DialogOutput';
import EBM_Description_Editor from '../EBM_Description_Editor/EBM_Description_Editor';
import TabsContainer from '../../components/Tabs/TabsContainer';

export default function EBM_Description_Viewer() {
    const [listJSONData, setJSONData] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalRowsCount, setTotalRowsCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const [dialogData, setDialogInformation] = useState({ title: "", information: "", status: "" });
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [dense, setDense] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [zifferValue, setZifferValue] = useState('');
    const [valueTab, setValueTab] = React.useState('1');
  
    const getEBMDescription = async (page, rowsPerPage, search) => {
        try {
            const JSONData = await DatabaseService.getEBMDescription((page + 1), rowsPerPage, search);
            if (JSONData && JSONData.rows) {
                setJSONData(JSONData.rows);
            }
            
            if (JSONData && 'totalCount' in JSONData) {
                const totalCount = parseInt(JSONData.totalCount);
                if (!isNaN(totalCount)) {
                    setTotalRowsCount(totalCount);
                } else {
                    console.warn('Invalid total count value:', JSONData.totalCount);
                    setTotalRowsCount(0);
                }
            } else {
                console.warn('Total count property missing in response');
                setTotalRowsCount(0);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        setDialogInformation({ information: "Loading...", status: "loading" });
        setLoading(true);
        getEBMDescription(page, rowsPerPage, search)
            .then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [page, rowsPerPage, search]);

    useEffect(() => {
        const updatedRows = listJSONData.map(item => ({
            id: item.id, 
            gop: item.gop, 
            description: item.description, 
            title: item.title, 
            header_no: item.header_no
        }));
        setRows(updatedRows);
    }, [listJSONData]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const isEdit = searchParams.has('edit-ziffer-description');
        const isAdd = searchParams.has('add-ziffer-description');
    
        if ((isEdit && zifferValue !== '') || isAdd) {
            setValueTab('2');
        } else {
            setValueTab('1');
            navigate(`${location.pathname}`);
        }
    }, [location.search, zifferValue]);

    const handleTabChange = (event, newValueTab) => {
        setValueTab(newValueTab);
        if (newValueTab === '2') {
            const action = zifferValue ? 'edit' : 'add';
            navigate(`${location.pathname}?${action}-ziffer-description`);
        } else {
            navigate(location.pathname);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onDelete = async () => {
        if (!window.confirm('Sind Sie sicher, dass Sie diesen Koeffizienten löschen möchten?')) {
            return;
        }
        
        setDialogInformation({ information: "Löschend...", status: "loading" });
        setOpen(true);
    
        try {
            const results = await Promise.all(
                selected.map(id => DatabaseService.deleteEBMDescription(id))
            );
    
            const failures = results.filter(result => !result.success);
            
            if (failures.length > 0) {
                setDialogInformation({ 
                    information: `Fehler beim Löschen: ${failures.map(f => f.message).join(', ')}`, 
                    status: "error" 
                });
            } else {
                setDialogInformation({ 
                    information: "Erfolgreich gelöscht", 
                    status: "success" 
                });
                await getEBMDescription(page, rowsPerPage, search);
                setSelected([]);
            }
        } catch (error) {
            console.error('Error deleting descriptions:', error);
            setDialogInformation({ 
                information: "Fehler beim Löschen der Beschreibungen", 
                status: "error" 
            });
        } finally {
            setTimeout(() => setOpen(false), 2000);
        }
    };

    const onUpdateZifferDescriptions = async (ziffer) => {
        setZifferValue(ziffer);
        setValueTab('2');
        navigate(`${location.pathname}?edit-ziffer-description`);
    };

    const onAddZifferDescription = () => {
        setZifferValue('');
        setValueTab('2');
        navigate(`${location.pathname}?add-ziffer-description`);
    };

    const renderDialogContent = () => {
        if (dialogData.status !== "view") return null;
    
        switch (dialogData.action) {
            case "update_description":
                return (
                    <></>
                );
            default:
                return null;
        }
    };

    const tabs = [
        {
            value: '1',
            label: 'Description Viewer',
            content: (
                <Table_Section 
                    tableTitle="EBM Ziffer Description Viewer"
                    loading={loading}
                    rows={rows}
                    order={order}
                    orderBy={orderBy}
                    selected={selected}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    totalRowsCount={totalRowsCount}
                    dense={dense}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    setSelected={setSelected}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    setDense={setDense}
                    onDelete={onDelete}
                    setSearch={setSearch}
                    search={search}
                    onUpdateZifferDescriptions={onUpdateZifferDescriptions}
                    onAddZifferDescription={onAddZifferDescription}
                />
            )
        },
        {
            value: '2',
            label: 'Description Editor',
            content: (
                <EBM_Description_Editor 
                    zifferValue={zifferValue} 
                    onSave={() => {
                        getEBMDescription(page, rowsPerPage, search);
                    }}
                />
            )
        }
    ];

    return (
        <>
            <br/>

            <TabsContainer
                activeTab={valueTab}
                onTabChange={handleTabChange}
                tabs={tabs}
                hideTabList={true}
            />

            {
                dialogData.status === "loading" || dialogData.status === "view" ? (
                <DialogOutput
                    fullScreen={false}
                    open={open}
                    dialogData={dialogData}
                    handleClose={handleClose}
                    output={renderDialogContent()}
                />
                ) : null
            }
        </>
    );
}
//--GOÄ_Abrechnungskompass
import { getGOÄ_Abrechnungskompass } from './goaeServices/getGOÄ_Abrechnungskompass';
import { insertGoaeFachgruppenvergleichCSVData } from './goaeServices/csv/insertGoaeFachgruppenvergleichCSVData';
import { getGoaeFachgruppenvergleichCSVList } from './goaeServices/csv/getGoaeFachgruppenvergleichCSVList';
import { downloadGoaeFachgruppenvergleichCSV } from './goaeServices/csv/downloadGoaeFachgruppenvergleichCSV';
import { delGoaeFachgruppenvergleichCSVList } from './goaeServices/csv/delGoaeFachgruppenvergleichCSVList';
//goae missing
import { insertGoaeMissing } from './goaeServices/goae_missing/insertGoaeMissing';
import { getGoaeMissingData } from './goaeServices/goae_missing/getGoaeMissingData';
import { delGoaeMissingData } from './goaeServices/goae_missing/delGoaeMissingData';
//-- END OF GOÄ_Abrechnungskompass

//--EBM Abrechnungskompass
import { delAbrechnungskompassCSVData } from './ebmServices/csv/delAbrechnungskompassCSVData';
import { updateAbrechnungskompassCSVNotes } from './ebmServices/csv/updateAbrechnungskompassCSVNotes';
import { updateEBMCoefficient } from './ebmServices/ebm_editor/updateEBMCoefficient';
import { insertEBMCoefficient } from './ebmServices/ebm_editor/insertEBMCoefficient';
import { deleteEBMCoefficient } from './ebmServices/ebm_editor/deleteEBMCoefficient';
import { updateEBMDescription } from './ebmServices/ebm_editor/description/updateEBMDescription';
import { getEBMDescription } from './ebmServices/ebm_editor/description/getEBMDescription';
import { deleteEBMDescription } from './ebmServices/ebm_editor/description/deleteEBMDescription';
//-con_data
import { getCONData } from './ebmServices/con_data/getCONData';
import { insertCONData } from './ebmServices/con_data/insertCONData';
import { delCONData } from './ebmServices/con_data/delCONData';
//-- END OF EBM Abrechnungskompass

//CONSTANTS
import { getPLZ_OrtData } from './constants/getPLZ_OrtData';
import { getAbrechnungBillingOptionData } from './constants/getAbrechnungBillingOptionData';
//END OF CONSTANTS

import { apiCall } from './apiHelpers/apiCall';

//Retrieve all List
export const getData = async (data) => {
  try {
    const response = await apiCall(`/auipdata/${data}`);

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getCategoryData = async (category, id) => {
  try {
    const response = await apiCall(`/auipdata/${category}/${id}`);

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

// "/test/:category/:qtype/:idno/:dt1/:dt2"

export const getTestCategoryData = async (category, qtype, idno, dt1, dt2) => {
  try {
    const response = await apiCall(
      `/auipdata/${category}/${qtype}/${idno}/${dt1}/${dt2}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getEnvVariables = async () => {
  try {
    const response = await apiCall(`/api/env`);

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

//USER MANAGEMENT

export const getAllUsersData = async (page, rowsPerPage, search, showReminded = false, showNewUsers = false, showFullMembers = false, roleFilter = "") => {
  try {
    const response = await apiCall(
      `/api/users?page=${page}&perPage=${rowsPerPage}&s=${search}&show_reminded=${showReminded}&show_new_users=${showNewUsers}&show_full_members=${showFullMembers}&role_filter=${roleFilter}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getSingleUserData = async () => {
  try {
    const response = await apiCall(`/api/userlist?single_user=1`);

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getMe = async () => {
  try {
    const response = await apiCall(`/api/user/me`);

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getAllUsersRolesData = async () => {
  try {
    const response = await apiCall(`/api/userroles`);

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const insertUserData = async (data) => {
  try {
    const response = await apiCall('/api/insertuser', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('insertuser');
    console.error(error.message);
  }
};

export const updateUserData = async (id, data) => {
  try {
    const response = await apiCall(`/api/users/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('userupdate');
    console.error(error.message);
  }
};

export const userUpdateProfile = async (data) => {
  try {
    const response = await apiCall('/api/user', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('userupdateprofile');
    console.error(error.message);
  }
};

export const userUpdatePasswordProfile = async (data) => {
  try {
    const response = await apiCall('/api/user/password', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('userupdatepasswordprofile');
    console.error(error.message);
  }
};

export const delUserData = async (data) => {
  try {
    const response = await apiCall('/api/del/users', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('userremove');
    console.error(error.message);
  }
};

// END OF USER MANAGEMENT

export const checkSession = async () => {
  try {
    const response = await apiCall(`/api/thesession`);

    return response.json();
  } catch (error) {
    console.log(error.message);
  }
};

// 2FA
export const fetchUserConfiguredMethods = async () => {
  try {
    const response = await apiCall('/api/user/2fa', {
      method: 'GET',
    });
    const methods = await response.json();
    return methods;
  } catch (error) {
    console.error(error.message);
  }
};
// END OF 2FA

export const authenticate = async (data) => {
  try {
    const response = await apiCall('/api/authentication', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.error(error.message);
  }
};

export const generateLoginOTP = async (method) => {
  try {
    const response = await apiCall('/api/user/2fa/login/generate-otp', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ method }),
      credentials: 'include',
    });

    return response;
  } catch (error) {
    console.error(error.message);
  }
};

export const verifyLoginOTP = async (otp, method, isBackupCode = false) => {
  try {
    const response = await apiCall('/api/user/2fa/login/verify', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ otp, method, isBackupCode }),
      credentials: 'include',
    });

    return response;
  } catch (error) {
    console.error(error.message);
  }
};

export const logout = async () => {
  try {
    await apiCall('/api/user/logout', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });
  } catch (error) {
    console.error(error.message);
  }
};

export const registerUser = async (data) => {
  try {
    const response = await apiCall('/api/user/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('registerUser');
    console.error(error.message);
  }
};

export const checkRegistrationCodeValid = (code) => {
  return apiCall(`/api/registration-codes/${code}`)
    .then((res) => {
      if (res.ok) {
        return res.json().then((code) => !code.isUsed);
      }
      return false;
    })
    .catch(() => false);
};

export const checkEmail = async (data) => {
  try {
    const response = await apiCall('/api/usermail', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: data }),
    });

    return response;
  } catch (error) {
    console.log('checkEmail');
    console.error(error.message);
  }
};

export const checkUsername = async (data) => {
  try {
    const response = await apiCall('/api/username', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: data }),
    });

    return response;
  } catch (error) {
    console.log('checkUsername');
    console.error(error.message);
  }
};

export const getPDFFilesData = async (
  page,
  rowsPerPage,
  search,
  page_type,
  archive
) => {
  try {
    const response = await apiCall(
      `/api/fileslist?page=${page}&perPage=${rowsPerPage}&s=${search}&page_type=${page_type}&archive=${archive}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getCONFilesData = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/fileslist?page=${page}&perPage=${rowsPerPage}&s=${search}&filetype=con`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const delPDFFile = async (data) => {
  try {
    const response = await apiCall('/api/fileslistremove', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('fileslistremove');
    console.error(error.message);
  }
};

export const archiveFile = async (data, page_type, archive) => {
  try {
    const response = await apiCall('/api/fileslistarchive', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: data,
        page_type: page_type,
        archive: archive,
      }),
    });

    return response;
  } catch (error) {
    console.log('fileslistarchive');
    console.error(error.message);
  }
};

//LIST UPLOAD CLIENT PDF FILES

export const getClientFilesData = async (
  page,
  rowsPerPage,
  search,
  user_id,
  is_client_file_show_all_activity=0
) => {
  try {
    const response = await apiCall(
      `/api/fileslist?page=${page}&perPage=${rowsPerPage}&s=${search}&filetype=pdf&is_client_file=1&is_client_file_show_all_activity=${is_client_file_show_all_activity}&user_id=${user_id}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

//END LIST UPLOAD CLIENT PDF FILES

export const getGemeinschaftspraxis = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/gemeinschaftspraxis?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const delGemeinschaftspraxis = async (data) => {
  try {
    const response = await apiCall('/api/del/gemeinschaftspraxis', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('fileslistremove');
    console.error(error.message);
  }
};

export const getLanr = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/lanr?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const delLanr = async (data) => {
  try {
    const response = await apiCall('/api/del/lanr', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('fileslistremove');
    console.error(error.message);
  }
};

export const getHonorarunterlagen = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/honorarunterlagen?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const delHonorarunterlagen = async (data) => {
  try {
    const response = await apiCall('/api/del/honorarunterlagen', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('fileslistremove');
    console.error(error.message);
  }
};

export const extractPDF = async (data) => {
  try {
    const response = await apiCall('/api/extractpdf/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });
    return response;
  } catch (error) {
    console.log('extractPDF');
    console.error(error.message);
  }
};

export const CONtoCSV = async (data) => {
  try {
    const response = await apiCall('/api/contocsv/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('CONtoCSV');
    console.error(error.message);
  }
};

export const PDFtoCSV = async (data) => {
  try {
    const response = await apiCall('/api/pdftocsv/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });
    return response;
  } catch (error) {
    console.log('pdftocsv');
    console.error(error.message);
  }
};

export const PDFtoDOCX = async (data) => {
  try {
    const response = await apiCall('/api/pdftodocx/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });
    return response;
  } catch (error) {
    console.log('pdftodocx');
    console.error(error.message);
  }
};

export const DownloadDOCX = async (data) => {
  try {
    const response = await apiCall('/api/download_docx/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });
    return response;
  } catch (error) {
    console.log('download docx');
    console.error(error.message);
  }
};

export const DownloadPDF = async (data) => {
  try {
    const response = await apiCall('/api/download_pdf/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });
    return response;
  } catch (error) {
    console.log('download pdf');
    console.error(error.message);
  }
};

//BLOG
//Insert blog
export const insertPostData = async (data) => {
  try {
    const response = await apiCall('/api/insertpost', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('insertpost');
    console.error(error.message);
  }
};

export const delPosts = async (data) => {
  try {
    const response = await apiCall('/api/del/posts', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('fileslistremove');
    console.error(error.message);
  }
};

export const updatePostData = async (data) => {
  try {
    const response = await apiCall('/api/updatepost', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('updatepost');
    console.error(error.message);
  }
};

export const getPostList = async (
  page,
  rowsPerPage,
  search,
  post_type,
  status
) => {
  try {
    const response = await apiCall(
      `/api/postslist?page=${page}&perPage=${rowsPerPage}&s=${search}&post_type=${post_type}&status=${status}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getCSV = async (id) => {
  try {
    const response = await apiCall(`/api/csv_get?id=${id}`);

    // Check if the request was successful
    /*if(response.ok) {
            const blob = await response.blob();
            FileSaver.saveAs(blob, 'data.txt');
        } else {
            console.error('Server responded with status: ' + response.status);
        }*/

    return response;
  } catch (error) {
    console.error(error.message);
  }

  /*try {
        const response = await apiCall(`/csv_get?id=${id}`);
        const blob = await response.blob();
        return blob;
    } catch (error) {
        console.error(error.message);
    }*/

  /*try {

        const response = await apiCall(`/csv_get?id=${id}`);

        const blob = await response.blob();

        return blob;
        /*const jsonData = await response.json();

        return jsonData;*/

  // Check if the request was successful
  //if(response.ok) {
  //const blob = await response.blob();
  //FileSaver.saveAs(blob, 'data.txt');
  //} else {
  //    console.error('Server responded with status: ' + response.status);
  //}

  /*} catch (error) {
        console.error(error.message);
    }*/
};

export const getPostGetForUpdate = async (id) => {
  try {
    const response = await apiCall(`/api/postgetforupdate?id=${id}`);

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getPostCategory = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/post_category_list?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getImageList = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/imagelist?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const delImage = async (data) => {
  try {
    const response = await apiCall('/api/imageremove/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('image remove');
    console.error(error.message);
  }
};

//AUDIO
export const getAudioList = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/audiolist?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const delAudio = async (data) => {
  try {
    const response = await apiCall('/api/audioremove/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('audio remove');
    console.error(error.message);
  }
};
//END OF AUDIO

//VIDEO
export const getVideoList = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/videolist?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const delVideo = async (data) => {
  try {
    const response = await apiCall('/api/videoremove/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('video remove');
    console.error(error.message);
  }
};
//END OF VIDEO

export const insertCategory = async (data) => {
  try {
    const response = await apiCall('/api/insertcategory', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('image remove');
    console.error(error.message);
  }
};

export const delCategory = async (data) => {
  try {
    const response = await apiCall('/api/del/category', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('fileslistremove');
    console.error(error.message);
  }
};

// SEARCH ENGINE

export const getSearchEngine = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/searchengine?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

// END OF SEARCH ENGINE

//// SPREADSHEET DATA EXPERIMENT
export const insertBerechnungsdatenBayern = async (data) => {
  try {
    const response = await apiCall('/api/insert_berechnungsdaten_bayern/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('insert_berechnungsdaten_bayern');
    console.error(error.message);
  }
};

export const getBerechnungsdatenBayernData = async (
  page,
  rowsPerPage,
  search
) => {
  try {
    const response = await apiCall(
      `/api/berechnungsdaten_bayern_list?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getAllBerechnungsdatenBayernDataSpreadsheet = async (
  rowsPerPage
) => {
  try {
    const response = await apiCall(
      `/api/berechnungsdaten_bayern_list?perPage=${rowsPerPage}&spreadsheet=1`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

//--------KUNDE--------

export const insertKunde = async (data) => {
  try {
    const response = await apiCall('/api/insert_kunde/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('insert_kunde');
    console.error(error.message);
  }
};

export const getKundeData = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/kunde_list?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getKundeDataSpreadsheet = async (rowsPerPage) => {
  try {
    const response = await apiCall(
      `/api/kunde_list?perPage=${rowsPerPage}&spreadsheet=1`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

//--------KUNDE COMPARISON--------

export const getCSVFilesData = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/fileslist?page=${page}&perPage=${rowsPerPage}&s=${search}&filetype=csv`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

/*export const getKundeListComparison  = async (data, page, rowsPerPage) => {
    try{
        const response = await apiCall(`/kunde_list_comparison/?page=${page}&perPage=${rowsPerPage}`, {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(data)
        });
        
        return response;
        
    }catch(error){
        console.log("list comparison");
        console.error(error.message);
    }
}*/

export const getKundeListComparison = async (data) => {
  try {
    const response = await apiCall('/api/kunde_list_comparison/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('list comparison');
    console.error(error.message);
  }
};

//// END OF SPREADSHEET DATA EXPERIMENT

//// Haeufigkeitsstatistik

export const getCSVFilesHaeufigkeitsstatistikData = async (
  page,
  rowsPerPage,
  search
) => {
  try {
    const response = await apiCall(
      `/api/fileslist?page=${page}&perPage=${rowsPerPage}&s=${search}&filetype=csv&is_haeufigkeitsstatistik=1`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getFilesHaeufigkeitsstatistikEachFileData = async (
  page,
  rowsPerPage,
  search,
  fid
) => {
  try {
    const response = await apiCall(
      `/api/haeufigkeitsstatistik_eachfile_list?page=${page}&perPage=${rowsPerPage}&s=${search}&fid=${fid}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const delHaeufigkeitsstatistikEachFileData = async (data) => {
  try {
    const response = await apiCall('/api/del/haeufigkeitsstatistik', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('fileslistremove');
    console.error(error.message);
  }
};

//-- computation output

export const getHaeufigkeitsEBMNumberCount = async (
  page,
  rowsPerPage,
  search
) => {
  try {
    const response = await apiCall(
      `/api/haeufigkeitsstatistik_ebm_number_count_result?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

//-- end of computation output

//-- second computation output

export const getHaeufigkeitsEBMNumberAverageCount = async (
  page,
  rowsPerPage,
  search
) => {
  try {
    const response = await apiCall(
      `/api/haeufigkeitsstatistik_ebm_number_average_count?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

//-- end of second computation output

//-- third computation output

export const getHaeufigkeitsEBM_Median = async (page, rowsPerPage, search) => {
  try {
    const response = await apiCall(
      `/api/haeufigkeitsstatistik_ebm_median?page=${page}&perPage=${rowsPerPage}&s=${search}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

//-- end of third computation output

//-- fourth computation output

export const getEBMKoeffizientEachFileComputation = async (
  page,
  rowsPerPage,
  search,
  fid
) => {
  try {
    const response = await apiCall(
      `/api/ebm_koeffizient_eachfile_computation?page=${page}&perPage=${rowsPerPage}&s=${search}&fid=${fid}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

//-- end of fourth computation output

//-- ebm koeffizient compare

export const getEBMKoeffizientCompare = async (
  page,
  rowsPerPage,
  search,
  category_state_id,
  subcategories_quarter_id,
  subcategories_quarter_year,
  fid
) => {
  try {
    const response = await apiCall(
      `/api/ebm_koeffizient_compare?page=${page}&perPage=${rowsPerPage}&s=${search}&category_state_id=${category_state_id}&subcategories_quarter_id=${subcategories_quarter_id}&subcategories_quarter_year=${subcategories_quarter_year}&fid=${fid}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

//-- end of ebm koeffizient compare

//-- file structure

export const getEBMKoeffizientenFilestructure = async (
  category_state_id,
  subcategories_quarter_id,
  subcategories_quarter_year
) => {
  try {
    //const response = await apiCall(`/api/ebm_koeffizienten_filestructure?page=${page}&perPage=${rowsPerPage}&s=${search}`);

    const response = await apiCall(
      `/api/ebm_koeffizienten_filestructure?category_state_id=${category_state_id}&subcategories_quarter_id=${subcategories_quarter_id}&subcategories_quarter_year=${subcategories_quarter_year}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getEBMKoeffizientenFilestructure_FilesList = async (
  page,
  rowsPerPage,
  search,
  subcategory_id,
  mode
) => {
  try {
    const response = await apiCall(
      `/api/ebm_koeffizienten_filestructure_fileslist?page=${page}&perPage=${rowsPerPage}&s=${search}&subcategory_id=${subcategory_id}&mode=${mode}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const insertFileStructureData = async (data) => {
  try {
    const response = await apiCall('/api/insertfilestructure', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.log('insertfilestructure');
    console.error(error.message);
  }
};
//-- end of file structure

//-- EBM Abrechnungskompass

export const getEBMAbrechnungskompass = async (
  page,
  rowsPerPage,
  search,
  scheine,
  fallzahl
) => {
  try {
    const response = await apiCall(
      `/api/ebm_abrechnungskompass?page=${page}&perPage=${rowsPerPage}&s=${search}&scheine=${scheine}&fallzahl=${fallzahl}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getEBMAbrechnungskompass2 = async (
  page,
  rowsPerPage,
  search,
  scheine,
  fallzahl
) => {
  try {
    const response = await apiCall(
      `/api/ebm_abrechnungskompass2?page=${page}&perPage=${rowsPerPage}&s=${search}&scheine=${scheine}&fallzahl=${fallzahl}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getEBMAbrechnungskompass3 = async (
  page,
  rowsPerPage,
  search,
  scheine,
  fallzahl,
  state_id
) => {
  try {
    const response = await apiCall(
      `/api/ebm_abrechnungskompass3?page=${page}&perPage=${rowsPerPage}&s=${search}&scheine=${scheine}&fallzahl=${fallzahl}&state_id=${state_id}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getEBMAbrechnungskompassFix = async (scheine, state_id) => {
  try {
    const response = await apiCall(
      `/api/ebm_abrechnungskompass_fix?scheine=${scheine}&state_id=${state_id}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getEBMAbrechnungskompassSearchByStates = async (
  scheine,
  state_id,
  edit_mode = false,
  gop = ''
) => {
  try {
    const response = await apiCall(
      `/api/ebm_abrechnungskompass_searchbystates?scheine=${scheine}&state_id=${state_id}&edit_mode=${edit_mode}&gop=${gop}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const insertAbrechnungskompassCSVData = async (
  data,
  input,
  scheine,
  state_id,
  abrechnung_billing_no
) => {
  try {
    const response = await apiCall('/api/abrechnungskompass_to_csv', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        csv: data,
        input: input,
        scheine: scheine,
        state_id: state_id,
        abrechnung_billing_no: abrechnung_billing_no,
      }),
    });

    return response;
  } catch (error) {
    console.log('abrechnungskompass_to_csv');
    console.error(error.message);
  }
};

export const getAbrechnungskompassInputOutputCSVData = async (
  page,
  rowsPerPage,
  search,
  id=''
) => {
  try {
    const response = await apiCall(
      `/api/abrechnungskompass_to_csv_list?page=${page}&perPage=${rowsPerPage}&s=${search}&id=${id}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const getAbrechnungskompassUserInputAndOutputCSVFile = async (id) => {
  try {
    const response = await apiCall(`/api/abrechnungskompass_csv_get?id=${id}`);

    return response;
  } catch (error) {
    console.error(error.message);
  }
};

//GOP Missing
export const insertGOPMissing = async (gop_missing_data_json, state_no) => {
  try {
    if (gop_missing_data_json === '') {
      throw new Error('gop_missing_data_json cannot be empty');
    }

    const response = await apiCall('/api/insert_gop_missing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        gop_missing_data_json: gop_missing_data_json,
        state_no: state_no,
      }),
    });

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      throw new Error('Failed to insert data');
    }
  } catch (error) {
    console.error('Error in insert gop missing', error.message);
    throw error;
  }
};

export const getGOPMissingData = async (
  page,
  rowsPerPage,
  search,
  state_no,
  per_user_data
) => {
  try {
    const response = await apiCall(
      `/api/get_gop_missing?page=${page}&perPage=${rowsPerPage}&s=${search}&state_no=${state_no}&per_user_data=${per_user_data}`
    );

    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error.message);
  }
};

export const delGOPMissingData = async (data) => {
  try {
    const response = await apiCall('/api/del/gop_missing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: data }),
    });

    return response;
  } catch (error) {
    console.log('gop_missing_remove');
    console.error(error.message);
  }
};
//-- END EBM Abrechnungskompass

//// END OF Haeufigkeitsstatistik

export {
    //--EBM Abrechnungskompass
    delAbrechnungskompassCSVData,
    updateAbrechnungskompassCSVNotes,
    updateEBMCoefficient,
    insertEBMCoefficient,
    deleteEBMCoefficient,
    updateEBMDescription,
    getEBMDescription,
    deleteEBMDescription,
    //-- END OF EBM Abrechnungskompass
    //-- CON Data - EBM Abrechnungskompass
    getCONData,
    insertCONData,
    delCONData,
    //-- CON Date - EBM Abrechnungskompass
    //--GOÄ_Abrechnungskompass
    getGOÄ_Abrechnungskompass,
    insertGoaeFachgruppenvergleichCSVData,
    getGoaeFachgruppenvergleichCSVList,
    downloadGoaeFachgruppenvergleichCSV,
    delGoaeFachgruppenvergleichCSVList,
    //goae missing
    insertGoaeMissing,
    getGoaeMissingData,
    delGoaeMissingData,
    //-- END OF GOÄ_Abrechnungskompass
    //CONSTANTS
    getPLZ_OrtData,
    getAbrechnungBillingOptionData,
    //END OF CONSTANTS
}
import React, { useEffect, useState, createRef, useRef } from 'react';
import * as DatabaseService from '../../../../services/DatabaseService';
import TablePaginationActions from '../../../../components/TablePaginationActions'; // Adjust the path accordingly
import SearchInput from '../../../../components/SearchInput';
import MuiDrawer from '../../../../components/MuiDrawer';
import readableDateFormat from '../../../../utils/readableDateFormat';

// Table Libraries
import PropTypes from 'prop-types';
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
/* Dialog Box */
import DialogOutput from '../../../../components/DialogOutput';
import CircularProgress from '@mui/material/CircularProgress';
/* End of Dialog Box */

import EBM_Abrechnungskompass from '../../../EBM_Abrechnungskompass/EBM_Abrechnungskompass';
import Button from '@mui/material/Button';
// Table Libraries

import FileSaver from 'file-saver';
import Checkbox from '@mui/material/Checkbox';
import { useSingleUserData } from '../../../../services/userService';

export default function Abrechnungskompass_CSV_Page() {

  const { userRoleID } = useSingleUserData();

  const [listPDFFiles, setPDFFiles] = useState([]);

  const [csv_data, setCSVData] = useState("");

  const [pageUserInfo, setPageUserInfo] = useState({});

  const [abrechnungskompass_csv_id, setabrechnungskompassCSV_ID] = useState(null);

  const [userInputScheine, setUserInputScheine] = useState();

  const [userInputState, setUserInputState] = useState();

  const [userInputData, setUserInputData] = useState({});

  const [created_at, setCreatedAt] = useState();

  const [rows, setRows] = useState([]);

  const [totalRowsCount, setTotalRowsCount] = useState(0);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [loading, setLoading] = useState(false); // State to manage loading indication

  const [search, setSearch] = React.useState("");

  /*Dialog Box*/

  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogInformation] = React.useState({title:"", information:"", status:""});
  
  const theme = useTheme();

  /*Dialog Box*/

  const getCSV = async (id, filename) => {
    try{

      const CSVData = await DatabaseService.getAbrechnungskompassUserInputAndOutputCSVFile(id);

      if(CSVData.ok) {
        const blob = await CSVData.blob();
        FileSaver.saveAs(blob, filename + '.csv');
      } else {
          console.error('Server responded with status: ' + CSVData.status);
      }

    }catch(e){}
  }

  const getAbrechnungskompassInputOutputCSVData = async (page, rowsPerPage, search) => {

      try {
          const JSONData = await DatabaseService.getAbrechnungskompassInputOutputCSVData((page + 1), rowsPerPage, search);
          if (JSONData && JSONData.rows) {
              setPDFFiles(JSONData.rows);
          }

          if (JSONData && 'totalCount' in JSONData) {
            const totalCount = parseInt(JSONData.totalCount);
            if (!isNaN(totalCount)) {
                setTotalRowsCount(totalCount);
            } else {
                console.warn('Invalid total count value:', JSONData.totalCount);
                setTotalRowsCount(0);
            }
          } else {
              console.warn('Total count property missing in response');
              setTotalRowsCount(0);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  }

  useEffect(() => {

      setLoading(true); // Set loading to true when fetching data

      getAbrechnungskompassInputOutputCSVData(page, rowsPerPage, search)
          .then(() => setLoading(false))
          .catch(error => {
              console.error('Error fetching data:', error);
              setLoading(false);
          });

  }, [page, rowsPerPage, search]);

  useEffect(() => {
      // Map listAllUsers to create rows and update the state
      const updatedRows = listPDFFiles.map(item => ({
        id: item.id,
        kv_name: item.kv_name,
        salutation: item.salutation,
        user_title: item.user_title,
        fname: item.fname,
        lname: item.lname,
        user_input: item.user_input,
        user_input_scheine: item.user_input_scheine,
        user_input_state: item.user_input_state,
        billing_option: item.billing_option,
        created_at: item.created_at
      }));

      // Update the rows state
      setRows(updatedRows);

  }, [listPDFFiles]); // Watch for changes in listAllUsers
  
  useEffect(() => {
    if (csv_data !== "") { /*csvLink.current.link.click();*/ }
  }, [csv_data]);

  const handleCSVData = async (id, filename) => {
    getCSV(id, filename);
  }

  const handleViewResult = async (abrechnungskompass_csv_id,
                                  input, 
                                  scheine, 
                                  state_id, 
                                  created_at,
                                  salutation,
                                  user_title,
                                  fname,
                                  lname,
                                  kv_name
                                ) => {
    
    setabrechnungskompassCSV_ID(abrechnungskompass_csv_id);
    setUserInputData(JSON.parse(input));
    setUserInputScheine(scheine);
    setUserInputState(state_id);
    setCreatedAt(created_at);

    setPageUserInfo({
      salutation: salutation || '',
      user_title: user_title || '',
      fname: fname || '',
      lname: lname || '',
      kv_name: kv_name || ''
    });

    setDialogInformation({ title: "", information: "", status: "view" });
    setOpen(true);

  }

  function descendingComparator(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
          return -1;
      }
      if (b[orderBy] > a[orderBy]) {
          return 1;
      }
      return 0;
  }

  function getComparator(order, orderBy) {
      return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) {
              return order;
          }
          return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
      {
          id: 'fname',
          numeric: false,
          disablePadding: true,
          label: 'Vorname',
      },
      {
          id: 'lname',
          numeric: false,
          disablePadding: true,
          label: 'Nachname',
      },
      {
        id: 'scheine',
        numeric: false,
        disablePadding: true,
        label: 'Scheine',
      },
      {
        id: 'state',
        numeric: false,
        disablePadding: true,
        label: 'KV',
      },
      {
        id: 'billing_option',
        numeric: false,
        disablePadding: true,
        label: 'Abrechnung',
      },
      {
          id: 'created_at',
          numeric: false,
          disablePadding: true,
          label: 'Erstellungsdatum',
      },
      {
          id: 'csv_download',
          numeric: false,
          disablePadding: true,
          label: '',
      },
      {
          id: 'view_result',
          numeric: false,
          disablePadding: true,
          label: '',
      }
  ];

  function EnhancedTableHead(props) {
      const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
      
      return (
          <TableHead>
              <TableRow>
                    <TableCell padding="checkbox" sx={{ width: 0, p: (parseInt(userRoleID) !== 1 && parseInt(userRoleID) !== 4) ? '7px 7px 37px 7px' : '0'}}>
                      {(parseInt(userRoleID) === 1 || parseInt(userRoleID) === 4) && (
                        <>
                          <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                              'aria-label': 'select all desserts',
                            }}
                          />
                        </>
                      )}
                    </TableCell>
                  
                  {headCells.map((headCell) => (
                      <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? 'right' : 'left'}
                          padding={headCell.disablePadding ? 'none' : 'normal'} 
                          sortDirection={orderBy === headCell.id ? order : false}
                      >
                          {headCell.label}
                      </TableCell>
                  ))}
              </TableRow>
          </TableHead>
      );
  }

  EnhancedTableHead.propTypes = {
      numSelected: PropTypes.number.isRequired,
      onRequestSort: PropTypes.func.isRequired,
      onSelectAllClick: PropTypes.func.isRequired,
      order: PropTypes.oneOf(['asc', 'desc']).isRequired,
      orderBy: PropTypes.string.isRequired,
      rowCount: PropTypes.number.isRequired,
  };



function EnhancedTableToolbar(props) {
const { numSelected } = props;

return (
  <Toolbar
    sx={{
      pl: { sm: 2 },
      pr: { xs: 1, sm: 1 },
      ...(numSelected > 0 && {
        bgcolor: (theme) =>
          alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
      }),
      paddingRight: '16px!important', 
      display: { xs: 'block', sm: 'flex' },
      paddingTop: { xs: '8px', sm: '0' },
      paddingBottom: { xs: '14px', sm: '0' },
    }}
  >
    {numSelected > 0 ? (
      <Typography
        sx={{ flex: '1 1 100%' }}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {numSelected} selected
      </Typography>
    ) : (
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Abrechnungskompass Benutzereingabe-Protokolle
      </Typography>
    )}


    {
      
    numSelected > 0 ? (<>
      <Tooltip title="Delete">
        <Button color="error" variant="contained" onClick={onDelete}>
          <DeleteIcon />
        </Button>
      </Tooltip>
      </>
    ) : (
          <>
              <SearchInput setSearch={setSearch} setPage={setPage} search={search} />
          </>
    )
    
    }
  </Toolbar>
);
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const [order, setOrder] = React.useState('asc');
const [orderBy, setOrderBy] = React.useState('calories');
const [selected, setSelected] = React.useState([]);

const [dense, setDense] = React.useState(false);

const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

const handleSelectAllClick = (event) => {
  if (event.target.checked) {
    const newSelected = rows.map((n) =>  n.id);
    setSelected(newSelected);
    return;
  }
  setSelected([]);
};

const handleClick = (event, id) => {
  const selectedIndex = selected.indexOf(id);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }

  setSelected(newSelected);
};

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

const isSelected = (id) => selected.indexOf(id) !== -1;

const visibleRows = stableSort(rows, getComparator(order, orderBy));

//POST Database Service
function delRow_func(data){
  return DatabaseService.delAbrechnungskompassCSVData(data)
}
//END of POST Database Service

const onDelete = (event) => {
    
  setDialogInformation({ information: "Löschend...", status: "loading" });
  setOpen(true);

    delRow_func(selected)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to delete row');
            }
        })
        .then(result => {
            getAbrechnungskompassInputOutputCSVData(page, rowsPerPage, search)   
              .then(() => {
                setOpen(false);
                setSelected([]);
              });
        })
        .catch(error => {
            console.error('Error deleting file:', error);
            setOpen(false);
            // Handle error here, show message or take appropriate action
        });
};

const handleClose = () => {
    setOpen(false);
};
/* Dialog Box */

//Table

//=========== Upload Files

//==== Upload Files

  const LoadingTable = styled('div')(({ theme }) =>
      theme.unstable_sx({
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.50)',
          zIndex: 1,
          paddingTop: '2.7em'
      }),
  );

return (
          <MuiDrawer>

                <br></br>

                <Box sx={{ width: '100%' }}>
                  <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer sx={{ position: 'relative' }}>

                    {
                        loading ? (

                            <LoadingTable>
                                <div>
                                    <CircularProgress />
                                </div>
                            </LoadingTable>
                        ) : ""
                    }

                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        />
                        <TableBody>
                            {
                                (visibleRows.length > 0) ?
                                    visibleRows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                          <>
                                              <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer', '& .MuiTableCell-root': { padding: (parseInt(userRoleID) !== 1 && parseInt(userRoleID) !== 4) ? '4px 0 4px 0' : '0' }}}
                                                        className='checkboxId'
                                                        data-id={row.id}
                                              >
                                                {(parseInt(userRoleID) === 1 || parseInt(userRoleID) === 4) && (
                                                  <TableCell padding="checkbox">
                                                    <Checkbox
                                                      color="primary"
                                                      checked={isItemSelected}
                                                      inputProps={{
                                                        'aria-labelledby': labelId,
                                                      }}
                                                      onClick={(event) => handleClick(event, row.id)}
                                                    />
                                                  </TableCell>
                                                )}
                                                {(parseInt(userRoleID) !== 1 && parseInt(userRoleID) !== 4) && (
                                                  <TableCell sx={{p:'7px'}}></TableCell>
                                                )}
                                                <TableCell
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      sx={{paddingRight: '1em'}}
                                                >
                                                      { row.fname }
                                                </TableCell>
                                                <TableCell
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      sx={{paddingRight: '1em'}}
                                                >
                                                      { row.lname }
                                                </TableCell>
                                                <TableCell
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      sx={{paddingRight: '1em'}}
                                                >
                                                      { row.user_input_scheine }
                                                </TableCell>
                                                <TableCell
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      sx={{paddingRight: '1em'}}
                                                >
                                                      { row.kv_name }
                                                </TableCell>
                                                <TableCell
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      sx={{paddingRight: '1em'}}
                                                >
                                                      { row.billing_option }
                                                </TableCell>
                                                <TableCell
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      sx={{paddingRight: '1em'}}
                                                >
                                                      { readableDateFormat(row.created_at) }
                                                </TableCell>
                                                <TableCell
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      sx={{paddingRight: '1em'}}
                                                >
                                                      {
                                                              <Button
                                                                variant="contained"
                                                                color="error"
                                                                sx={{ whiteSpace: 'nowrap' }}
                                                                onClick={() => {
                                                                  handleCSVData(row.id, row.fname + " " + row.lname + " " + readableDateFormat(row.created_at).toString().replace(/ /g, "_"));
                                                                }}
                                                              >
                                                                CSV Herunterladen
                                                              </Button>
                                                      }
                                                </TableCell>
                                                <TableCell
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      sx={{paddingRight: '1em'}}
                                                >
                                                      {
                                                          (row.user_input !== null && row.user_input_scheine !== null) ? 
                                                              <>
                                                                <Button
                                                                  variant="contained"
                                                                  color="primary"
                                                                  sx={{ whiteSpace: 'nowrap' }}
                                                                  onClick={() => {
                                                                    handleViewResult(row.id,
                                                                                     row.user_input, 
                                                                                     row.user_input_scheine, 
                                                                                     row.user_input_state, 
                                                                                     row.created_at,
                                                                                     row.salutation,
                                                                                     row.user_title,
                                                                                     row.fname,
                                                                                     row.lname,
                                                                                     row.kv_name
                                                                                    );
                                                                  }}
                                                                >
                                                                  Daten Ansehen
                                                                </Button>
                                                              </> : <></>
                                                      }
                                                </TableCell>
                                              </TableRow>
                                              </>
                                        );
                                    })
                                  : loading ? (
                                        <TableRow
                                            style={{
                                                height: '120px',
                                            }}
                                        ><TableCell colSpan={6} />
                                        </TableRow>
                                    ) : null
                            }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={parseInt(totalRowsCount)}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage="Zeilen pro Seite"
                    />
                  </Paper>
                  {/*<FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                  />*/}
                </Box>
                
                <DialogOutput
                    fullScreen={true} // Set the value according to your requirement
                    open={open}
                    dialogData={dialogData}
                    handleClose={handleClose}
                    backgroundColor='#F1F2F4'
                    output={<EBM_Abrechnungskompass 
                              user_input={userInputData} 
                              user_input_scheine={userInputScheine} 
                              user_input_state={userInputState} 
                              view_only_mode={true} 
                              created_at={created_at} 
                              pageUserInfo={pageUserInfo}
                              abrechnungskompassCSV_ID={abrechnungskompass_csv_id} 
                            />}
                />

          </MuiDrawer>
        );
}